import { PartialRecord } from '@predium/client-lookup';
import { energy_consumer_technology_type_enum, energy_system_type_enum } from '@predium/enums';
import clamp from 'lodash/clamp';
import sortBy from 'lodash/sortBy';

interface IEfficiencyLookupParams {
  energySystemType: energy_system_type_enum;
  energyConsumerTechnologyType: energy_consumer_technology_type_enum;
  currentEfficiency: number;
}

export const minimumEfficiencyRouteLookup: PartialRecord<
  energy_system_type_enum,
  PartialRecord<energy_consumer_technology_type_enum, number>
> = {
  [energy_system_type_enum.HEATING]: {
    WOOD_BOILER: 1.31,
    WOOD_FURNACE: 1.4,
    COAL_FURNACE: 1.4,
    GAS_CONDENSING_BOILER: 0.97,
    GAS_NON_CONDENSING_BOILER: 1.09,
    GAS_ROOM_HEATER: 1.29,
    GAS_FLOOR_HEATING: 1.07,
    GAS_FLOW_HEATER: 1.14,
    CONDENSING_BOILER: 0.97,
    LOW_TEMPERATURE_BOILER: 1.09,
    OIL_FURNACE: 1.4,
    STANDARD_BOILER: 1.16,
    DISTRICT_HEATING_WITHOUT_KWK: 1.02,
    DISTRICT_HEATING_WITH_KWK: 1.02,
    ELECTRIC_HEAT_PUMP_AIR: 0.31,
    ELECTRIC_HEAT_PUMP_GEO: 0.21,
    CENTRAL_ELECTRIC_STORAGE: 1.02,
    DIRECT_ELECTRICITY_HEATING: 1,
    SMALL_ELECTRIC_STORAGE: 1.05,
  },
  [energy_system_type_enum.HOT_WATER]: {
    WOOD_BOILER: 1.12,
    GAS_CONDENSING_BOILER: 1.07,
    GAS_NON_CONDENSING_BOILER: 1.12,
    GAS_FLOOR_HEATING: 1.32,
    GAS_FLOW_HEATER: 1.16,
    CONDENSING_BOILER: 1.07,
    LOW_TEMPERATURE_BOILER: 1.12,
    STANDARD_BOILER: 1.14,
    DISTRICT_HEATING_WITHOUT_KWK: 1.1,
    DISTRICT_HEATING_WITH_KWK: 1.14,
    ELECTRIC_HEAT_PUMP_AIR: 0.38,
    ELECTRIC_HEAT_PUMP_GEO: 0.26,
    CENTRAL_ELECTRIC_STORAGE: 1,
    ELECTRIC_FLOW_HEATER: 1,
    SMALL_ELECTRIC_STORAGE: 1,
  },
};

export const maximumEfficiencyRouteLookup: PartialRecord<
  energy_system_type_enum,
  PartialRecord<energy_consumer_technology_type_enum, number>
> = {
  [energy_system_type_enum.LIGHTING]: {
    INCANDESCENT_LAMPS: 17,
    FLUORESCENT_LAMPS: 100,
    LED: 160,
  },
  [energy_system_type_enum.HEATING]: {
    WOOD_BOILER: 2.12,
    WOOD_FURNACE: 2.96,
    COAL_FURNACE: 2.96,
    GAS_CONDENSING_BOILER: 1.31,
    GAS_NON_CONDENSING_BOILER: 1.92,
    GAS_ROOM_HEATER: 1.5,
    GAS_FLOOR_HEATING: 1.24,
    GAS_FLOW_HEATER: 1.24,
    CONDENSING_BOILER: 1.31,
    LOW_TEMPERATURE_BOILER: 1.24,
    OIL_FURNACE: 1.4,
    STANDARD_BOILER: 1.47,
    DISTRICT_HEATING_WITHOUT_KWK: 1.34,
    DISTRICT_HEATING_WITH_KWK: 1.67,
    ELECTRIC_HEAT_PUMP_AIR: 0.45,
    ELECTRIC_HEAT_PUMP_GEO: 0.52,
    CENTRAL_ELECTRIC_STORAGE: 1.02,
    DIRECT_ELECTRICITY_HEATING: 1.25,
    SMALL_ELECTRIC_STORAGE: 1.12,
  },
};

/**
 * Retrieves the min and max efficiency for a route
 * depending on the energy system type and the energy consumer technology type
 */
export function lookupRouteEfficiency(params: IEfficiencyLookupParams) {
  const min = minimumEfficiencyRouteLookup[params.energySystemType]?.[params.energyConsumerTechnologyType];
  const max = maximumEfficiencyRouteLookup[params.energySystemType]?.[params.energyConsumerTechnologyType];

  return {
    min,
    max,
  };
}

export function checkEfficiencyInBounds(efficiency: number, params: IEfficiencyLookupParams) {
  const lookup = lookupRouteEfficiency(params);
  return efficiency >= (lookup.min ?? 0) && efficiency <= (lookup.max ?? Infinity);
}

export function clipEfficiencyToBounds(efficiency: number, params: IEfficiencyLookupParams) {
  const lookup = lookupRouteEfficiency(params);
  // ensure that the current efficiency is within the bounds
  if (lookup.min !== undefined && params.currentEfficiency < lookup.min) {
    lookup.min = params.currentEfficiency;
  }
  if (lookup.max !== undefined && params.currentEfficiency > lookup.max) {
    lookup.max = params.currentEfficiency;
  }
  return clamp(efficiency, lookup.min ?? 0, lookup.max ?? Infinity);
}

export function lookupRouteConsumerTechnologyTypeByMaxEfficiency(
  efficiency: number,
  energySystemType: energy_system_type_enum,
): energy_consumer_technology_type_enum | null {
  const lookup = maximumEfficiencyRouteLookup[energySystemType];
  if (!lookup) {
    throw new Error(`No lookup found for energy system type ${energySystemType}`);
  }
  const entries = sortBy(Object.entries(lookup) as [energy_consumer_technology_type_enum, number][], (a) => a[1]);
  for (const [technologyType, maxEfficiency] of entries) {
    if (efficiency <= maxEfficiency) {
      return technologyType;
    }
  }
  return null;
}
