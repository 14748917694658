/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Divider, InputAdornment, InputProps, MenuItem, Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getOrientationAngle } from '@predium/client-lookup';
import {
  envelope_type_enum,
  insulation_material_category_enum,
  insulation_method_enum,
  orientation_enum,
} from '@predium/enums';
import {
  translateInsulationMaterialCategoryEnum,
  translateInsulationMethodEnum,
  translateOrientationEnum,
} from '@predium/i18n/client';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldIcon, IconState } from '../../../../../components/custom-data-source-input/FieldIcon';
import DetailedTooltip, { DetailedTooltipProps } from '../../../../../components/DetailedTooltip/DetailedTooltip';
import { RHFNumberField, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import { getInsulationMethodOptions } from '../../../../Scenarios/ActionPlan/ActionPlan.utils';
import { CUSTOM } from '../../BuildingEnvelope';
import useBuilding from '../../Context/useBuilding';

type FieldType =
  | 'text'
  | 'number'
  | 'select'
  | 'insulation_method'
  | 'insulation_material_category'
  | 'material_name'
  | 'orientation';

const getCustomInputProps = (
  unit: string,
  isFieldEditable: boolean,
  state?: IconState | undefined,
): Pick<InputProps, 'sx' | 'readOnly' | 'endAdornment' | 'startAdornment'> => {
  return {
    sx: {
      fontWeight: 700,
    },
    readOnly: !isFieldEditable,
    endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : null,
    startAdornment: state ? <FieldIcon state={state} /> : null,
  };
};

type Props = {
  title: string;
  unit?: string;
  name: string; //input name
  id: string; // this RHF field id
  fieldType: FieldType; //input field type
  isFieldEditable?: boolean; // some fields are not editable e.g u-value
  envelope_type?: envelope_type_enum;
  materialOptions?: string[];
  placeholder?: string;
  onMaterialNameChange?: (value: string) => void;
  onInsulationMethodChange?: (value: insulation_method_enum) => void;
  tooltipProps?: DetailedTooltipProps;
};

//TODO refactor this reduce number of props
export function EnvelopeUnitField({
  title,
  unit = '',
  name,
  id,
  fieldType = 'text',
  isFieldEditable = true,
  envelope_type,
  materialOptions,
  placeholder,
  onMaterialNameChange,
  onInsulationMethodChange,
  tooltipProps,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getValues, getFieldState } = useFormContext();
  const { hasEditAccess } = useBuilding();

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const handleValueClick = () => {
    setShowPlaceholder(false);
  };

  const currentValue = getValues(name);

  const isDirty = getFieldState(name).isDirty;
  const isError = getFieldState(name).error ? true : false;

  const isManualEdit = isDirty && currentValue !== '';

  let state: IconState | undefined;

  if (isError) {
    state = IconState.Error;
  } else if (!isFieldEditable) {
    state = IconState.AutoCalculated;
  } else if (isManualEdit) {
    state = IconState.ManualEdit;
  }

  const inputProps = getCustomInputProps(unit, isFieldEditable, state);

  let placeholderText = '';
  if (showPlaceholder && !isError) {
    const noValue = currentValue === '' || currentValue === undefined || currentValue === null ? '-' : '';
    placeholderText = placeholder ? placeholder : noValue;
  }

  const renderInputField = () => {
    switch (fieldType) {
      case 'text':
        return (
          <RHFTextField
            size="small"
            name={name}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            key={id}
            InputProps={inputProps}
            placeholder={placeholderText}
            disabled={!hasEditAccess}
          />
        );
      case 'number':
        return (
          <RHFNumberField
            size="small"
            name={name}
            variant="standard"
            key={id}
            InputProps={inputProps}
            placeholder={placeholderText}
            disabled={!hasEditAccess}
            sx={{
              '& .MuiInputBase-root.MuiInput-root:before': {
                border: !isFieldEditable ? 'none' : 'auto',
              },
              pointerEvents: !isFieldEditable ? 'none' : 'auto',
            }}
          />
        );
      case 'orientation':
        return (
          <RHFSelect
            select
            name={name}
            size="small"
            variant="standard"
            key={id}
            InputProps={inputProps}
            placeholder={placeholderText}
            disabled={!hasEditAccess}
          >
            {Object.values(orientation_enum)
              .filter((option) => option !== 'HORIZONTAL')
              .map((option, index) => {
                return (
                  <MenuItem key={index} value={getOrientationAngle(option)}>
                    {translateOrientationEnum(option)}
                  </MenuItem>
                );
              })}
          </RHFSelect>
        );
      case 'material_name':
        if (materialOptions && materialOptions.length > 0) {
          return (
            <>
              <RHFSelect
                native={false}
                name={name}
                size="small"
                variant="standard"
                key={id}
                InputProps={inputProps}
                placeholder={placeholderText}
                //@ts-ignore
                onChange={(e) => onMaterialNameChange(e.target.value)}
                disabled={!hasEditAccess}
              >
                {materialOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
                <Divider />

                <MenuItem key={CUSTOM} value={CUSTOM}>
                  <Typography fontWeight={600}>{t('DataCollection_EnvelopeUnit_AddOwnInsulationMaterial')}</Typography>
                </MenuItem>
              </RHFSelect>
            </>
          );
        } else {
          return (
            <RHFTextField
              size="small"
              name={name}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              key={id}
              InputProps={inputProps}
              placeholder={placeholderText}
              disabled={!hasEditAccess}
            />
          );
        }

      case 'insulation_material_category':
        return (
          <RHFSelect
            native={false}
            name={name}
            size="small"
            variant="standard"
            key={id}
            InputProps={inputProps}
            placeholder={placeholderText}
            disabled={!hasEditAccess}
          >
            {Object.values(insulation_material_category_enum).map((option, index) => (
              <MenuItem key={index} value={option}>
                {translateInsulationMaterialCategoryEnum(option as unknown as insulation_material_category_enum)}
              </MenuItem>
            ))}
          </RHFSelect>
        );
      case 'insulation_method':
        // This type seems wrong
        const insulationMethodOptions = getInsulationMethodOptions(envelope_type as any);
        if (currentValue !== '' && !insulationMethodOptions.includes(currentValue)) {
          insulationMethodOptions.push(currentValue);
        }
        return (
          <RHFSelect
            native={false}
            name={name}
            size="small"
            variant="standard"
            key={id}
            InputProps={inputProps}
            placeholder={placeholderText}
            disabled={!hasEditAccess}
            //@ts-ignore
            onChange={(e) => onInsulationMethodChange(e.target.value as insulation_method_enum)}
          >
            {insulationMethodOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {translateInsulationMethodEnum(option as insulation_method_enum)}
              </MenuItem>
            ))}
          </RHFSelect>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ pt: 2, pr: 3, mb: 2 }}>
      <Stack direction="row" spacing={0.5} alignItems="center" mb={0.5}>
        <Typography variant="body2" color={theme.palette.text.secondary} fontWeight={600}>
          {title}
        </Typography>
        {tooltipProps && <DetailedTooltip {...tooltipProps} />}
      </Stack>

      <Box onClick={handleValueClick}>{renderInputField()}</Box>
    </Box>
  );
}
