import { Box, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import {
  co2EmissionsFromYear,
  DEFAULT_DISCOUNT_RATE,
  getDiscountedCost,
  getDiscountedValue,
} from '@predium/client-lookup';
import { fShortenNumber } from '@predium/utils';
import isNil from 'lodash/isNil';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberField from '../../../../components/form/NumberField';
import InfoTooltip from '../../../../components/InfoTooltip';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import ExcessEmissionCostGraph from '../../Visualization/EsgPathGraphs/ExcessEmissionCostGraph';

type Props = {
  graphStartYear: number;
  excessEmissionCostPath: number[] | undefined;
};

export function ExcessEmissionCost({ graphStartYear, excessEmissionCostPath }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const defaultDiscountRate = Math.round(DEFAULT_DISCOUNT_RATE * 100 * 10) / 10; // Round to 1 decimal point

  const [discountRate, setDiscountRate] = useState<number | null>(() => {
    return defaultDiscountRate;
  });

  const newExcessEmissionCostPath = excessEmissionCostPath
    ? co2EmissionsFromYear(excessEmissionCostPath, graphStartYear)
    : [];

  const totalDiscountedCost =
    newExcessEmissionCostPath.length > 0 && discountRate !== null
      ? getDiscountedCost(newExcessEmissionCostPath, discountRate / 100)
      : null;

  const totalDiscountBelowTarget =
    newExcessEmissionCostPath.length > 0 && discountRate !== null
      ? getDiscountedValue(newExcessEmissionCostPath, discountRate / 100)
      : null;

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12} md={2} lg={2}>
          <Box>
            <Stack mb={4}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  {t('EsgAnalysis_ExcessEmissionCost-TotalDiscountedExcessEmissions')}
                  <InfoTooltip
                    text={t('EsgAnalysis_ExcessEmissionCost-TotalDiscountedExcessEmissionsTooltip')}
                    sx={{ ml: 1, p: 0, svg: { height: 20, width: 20 } }}
                  />
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'baseline'}>
                <Typography variant="h3" sx={{ mr: 1 }}>
                  {!isNil(totalDiscountedCost) ? fShortenNumber(totalDiscountedCost) : '-'} €
                </Typography>
              </Stack>
            </Stack>

            <Stack mb={4}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="subtitle1" sx={{ mr: 1, whiteSpace: 'break-spaces' }}>
                  {t('EsgAnalysis_ExcessEmissionCost-TotalDiscountBelowTarget')} {''}
                  <InfoTooltip
                    text={t('EsgAnalysis_ExcessEmissionCost-TotalDiscountBelowTargetTooltip')}
                    sx={{ p: 0, svg: { height: 20, width: 20 } }}
                  />
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'baseline'}>
                <Typography variant="h3" sx={{ mr: 1 }}>
                  {!isNil(totalDiscountBelowTarget) ? fShortenNumber(totalDiscountBelowTarget) : '-'} €
                </Typography>
              </Stack>
            </Stack>

            <Stack mb={4}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  {t('EsgAnalysis_ExcessEmissionCost-DiscountRate')}
                  <InfoTooltip
                    text={t('EsgAnalysis_ExcessEmissionCost-DiscountRateTooltip')}
                    sx={{ ml: 1, p: 0, svg: { height: 20, width: 20 } }}
                  />
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'baseline'}>
                <NumberField
                  value={discountRate !== null ? discountRate : null}
                  onValueChange={(values) => {
                    const { floatValue, formattedValue } = values;
                    if (formattedValue === '') {
                      setDiscountRate(null); // Set to null if the field is cleared
                    } else if (floatValue !== undefined) {
                      setDiscountRate(floatValue);
                      if (floatValue !== discountRate) {
                        trackEvent('ESG_CRREM_EXCESS_EMISSION_COST_DISCOUNT_RATE_CHANGED', {
                          discount_rate_changed: true,
                          discount_rate: floatValue,
                        });
                      }
                    }
                  }}
                  size="small"
                  variant="outlined"
                  max={100}
                  sx={{
                    width: 100,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  maxDecimalPlaces={2}
                />
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Box pl={3}>
            <Stack pl={2}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="body1" sx={{ fontWeight: 700, mr: 1 }}>
                  {t('EsgAnalysis_ExcessEmissionCost-Title')}
                </Typography>
                <Typography variant="caption">{t('EsgAnalysis_ExcessEmissionCost-TitleCaption')}</Typography>
              </Stack>
              <Typography variant="caption">{t('EsgAnalysis_ExcessEmissionCost-AvoidedCost')} €/a </Typography>
            </Stack>

            <ExcessEmissionCostGraph data={newExcessEmissionCostPath} graphStartYear={graphStartYear} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
