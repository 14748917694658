import {
  Checkbox,
  Chip,
  ChipProps,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';

export type FilterItem = {
  name: string;
  value: string | number;
};

type BaseProps = {
  /**
   * Array of items to be displayed in the filter
   */
  items: FilterItem[];
  /**
   * Callback function that is called when an item is selected
   * @param item The value of the selected item
   * @returns void
   */
  onChanged: (item: string | number) => void;
  /**
   * The label of the filter when more than one item is selected, needs to be in plural form
   */
  selectedLabel: string;
  /**
   * Whether the list of filter options is searchable
   * @default false
   */
  searchable?: boolean;
  /**
   * Whether multiple items can be selected
   * @default false
   */
  multiple?: boolean;
};

type MultipleProps = BaseProps & {
  multiple: true;
  selectedItems: FilterItem[];
};

type SingleProps = BaseProps & {
  multiple?: false;
  selectedItem: FilterItem;
};

type Props = (MultipleProps | SingleProps) & ChipProps;

/**
 * A controlled, generic chip component, meant to be used as a filter. Supports single/multi select & a search field.
 */
export default function FilterWithSearch({
  items,
  onChanged,
  selectedLabel,
  label,
  searchable,
  multiple = false,
  sx,
  ...props
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null>(null);
  const [searchInput, setSearchInput] = useState('');

  const chipRef = useRef(null);

  const handleClick = (item: FilterItem) => {
    onChanged(item.value);
    if (!multiple) {
      setAnchorEl(null); // Close menu on selection in single select mode
    }
  };

  const openMenu = () => {
    setAnchorEl(chipRef.current);
  };

  const filteredItems = items.filter((item) => item.name.toLowerCase().includes(searchInput.toLowerCase()));
  const noResults = filteredItems.length === 0;

  const filterLabelContent = !!(props as MultipleProps).selectedItems
    ? (props as MultipleProps).selectedItems.length === 0
      ? label
      : (props as MultipleProps).selectedItems.length === 1
      ? (props as MultipleProps).selectedItems[0].name
      : `${(props as MultipleProps).selectedItems.length} ${selectedLabel}`
    : (props as SingleProps).selectedItem.name;

  const filterLabel = (
    <Stack flexDirection={'row'} alignItems={'center'} gap={0.5}>
      {multiple && !!(props as MultipleProps).selectedItems && (props as MultipleProps).selectedItems.length > 0 && (
        <Iconify icon={'material-symbols:check'} color="text.secondary" width={16} height={16} />
      )}
      {filterLabelContent}
    </Stack>
  );

  return (
    <>
      <Chip
        {...props}
        ref={chipRef}
        label={filterLabel}
        onClick={openMenu}
        onDelete={openMenu}
        deleteIcon={<Iconify icon={'material-symbols:keyboard-arrow-down'} color="text.secondary" />}
        sx={{
          ...sx,
          backgroundColor: multiple
            ? !!(props as MultipleProps).selectedItems && (props as MultipleProps).selectedItems.length === 0
              ? theme.palette.background.paper
              : theme.palette.background.neutral
            : theme.palette.background.paper,
          borderRadius: 1,
          border: '1px solid',
          borderColor: theme.palette.divider,
          height: '100%',
          '& .MuiChip-label': {
            color: 'text.primary',
            fontWeight: 600,
            fontSize: 16,
            py: 1,
          },
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{
          mt: 1,
          '& .MuiPaper-root': {
            minWidth: 200,
            maxWidth: 'unset',
            width: 'auto',
          },
          '& .MuiMenuItem-root': {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            width: 'auto',
          },
        }}
      >
        {searchable && (
          <TextField
            placeholder="Search"
            value={searchInput}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: '200px',
              height: 40,
              '.MuiOutlinedInput-input': {
                py: 1,
              },
              mx: 2,
              mb: 2,
              position: 'sticky',
              top: 0,
              backgroundColor: 'background.paper',
              zIndex: 1,
            }}
          />
        )}

        <Scrollbar sx={{ maxHeight: 300 }}>
          {filteredItems.map((item) => (
            <MenuItem
              key={item.value}
              onClick={() => handleClick(item)}
              sx={{
                pl: multiple ? 1 : 2,
                px: multiple ? 1 : 3,
                height: (theme) => theme.spacing(6),
                backgroundColor:
                  (props as SingleProps).selectedItem?.value === item.value
                    ? theme.palette.background.neutral
                    : 'transparent',
              }}
            >
              {multiple && !!(props as MultipleProps).selectedItems && (
                <Checkbox
                  checked={(props as MultipleProps).selectedItems.some(
                    (selectedItem) => selectedItem.value === item.value,
                  )}
                />
              )}
              {item.name}
            </MenuItem>
          ))}
          {noResults && (
            <Typography variant="body2" color="text.secondary" sx={{ width: '100%', textAlign: 'center', p: 1 }}>
              {t('General_NoSearchResultsFound')}
            </Typography>
          )}
        </Scrollbar>
      </Menu>
    </>
  );
}
