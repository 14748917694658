import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  summary: ReactNode;
  content: ReactNode;
  expanded: boolean;
  disabled: boolean;
  setExpanded: (expanded: boolean) => void;
};

export default function AffectedPartsTable({ summary, content, expanded, disabled, setExpanded }: Props) {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        background: theme.palette.grey[200],
        borderRadius: 1,
        mb: 3,
        cursor: disabled ? 'default' : 'pointer',
        '&.MuiAccordion-root:before': {
          backgroundColor: 'white',
        },
        '&.MuiAccordion-root ': {
          boxShadow: 'none',
          mb: 3,
        },

        //target .MuiAccordionSummary-root but only when expanded
        '&.MuiAccordion-root': {
          '& .MuiAccordionSummary-root': {
            '&.Mui-expanded': {
              minHeight: 0,
              height: 48,
            },
            '&.Mui-disabled': {
              color: 'text.primary',
            },
          },
        },
      }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disabled={disabled}
    >
      <AccordionSummary
        sx={{
          background: theme.palette.grey[200],
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomRightRadius: !expanded ? 8 : 0,
          borderBottomLeftRadius: !expanded ? 8 : 0,
          boxShadow: 'none',
          px: 2,
        }}
      >
        {summary}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          background: theme.palette.grey[200],
          boxShadow: 'none',
          border: 'none',
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          cursor: 'default',
          pt: 0,
        }}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
}
