import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ActionFragment,
  ActionImpactDataOutputFragment,
  ActionPlanningActionPlanActionPlanFragment,
} from '@predium/client-graphql';
import { country_enum, efficiency_class_enum, renovation_type_enum } from '@predium/enums';
import { translateRenovationTypeEnum } from '@predium/i18n/client';
import { ensureDefined, fCurrencyTrimmed, fPercent, getBuildingIsMixed } from '@predium/utils';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import EfficiencyClassLabel from '../../../../components/EfficiencyClassLabel';
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import Scrollbar from '../../../../components/Scrollbar';
import { DELETE_ACTION } from '../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_ACTION_PLAN_METRICS,
  GET_SUBSIDIES,
} from '../../../../graphql/ActionPlanning.queries';
import { ActionPlanMetricsData } from '../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../utils/formatTime';
import { getUnit } from '../../../EsgAnalysis/EsgAnalysisUtil';
import { HeadLabel } from '../../../EsgAnalysis/Portfolio/BuildingListTab/EsgAnalysisBuildingListHead';
import {
  affectedConsumerPartsByAction,
  affectedEnvelopePartsByAction,
  displayCost,
  getActionName,
  getIconURLByAction,
} from '../ActionPlan.utils';
import EnvelopeAffectedPartsSubtext from '../Actions/PartialRenovations/EnvelopeRenovation/EnvelopeAffectedPartsSubtext';
import AffectedConsumersSubtext from '../Actions/PartialRenovations/SystemRenovation/AffectedConsumersSubtext';
import ActionPlanSectionTitle from '../Components/ActionPlanSectionTitle';
import { ComparisonTableCell } from '../Components/ComparisonTableCell';

export type ActionWithMetricData = ActionFragment & {
  metric: ActionImpactDataOutputFragment;
};

//PRE PRE-4289 create a fragment for building
const getEfficiencyClassColumnTitle = (building: ActionPlanningActionPlanActionPlanFragment['building']) => {
  const isMixed = getBuildingIsMixed(building.areas);

  if (!isMixed) {
    return t('General_EfficiencyClass');
  }

  if (building.address.country_id === country_enum.DE) {
    return [t('General_EfficiencyClass'), t('General_Residence')].join(' ');
  }

  return t('General_AverageEfficiencyClass');
};

const getTableHead = (building: ActionPlanningActionPlanActionPlanFragment['building']): HeadLabel[] => {
  const action = { id: 'name', label: t('General_Action', { count: 1 }), minWidth: 250 };

  const primaryEnergy = {
    id: 'energy_primary_impact',
    label: t('General_PrimaryEnergy'),
    subtitle: getUnit('primary_energy', false),
    minWidth: 200,
  };

  const finalEnergy = {
    id: 'energy_final_impact',
    label: t('General_FinalEnergy'),
    subtitle: getUnit('final_energy', false),
    minWidth: 200,
  };

  let subtitle;
  if (building.address.country_id === country_enum.AT) {
    if (building.active_building_model?.heating_demand) {
      subtitle = t('General_AccordingToHeatingDemand');
    } else {
      subtitle = t('General_AccordingToPrimaryEnergy');
    }
  } else {
    subtitle = t('General_AccordingToFinalEnergy');
  }

  const efficiencyClass = {
    id: 'efficiency_class_impact',
    label: getEfficiencyClassColumnTitle(building),
    minWidth: 100,
    subtitle,
  };

  const co2Emissions = {
    id: 'co2_emissions_impact',
    label: t('General_CO2Intensity'),
    subtitle: getUnit('co2_emissions', false),
    minWidth: 200,
  };

  const strandingDate = {
    id: 'stranding_date_impact',
    label: t('General_StrandingDate'),
    subtitle: t('General_AccordingToDegreePath', {
      degree: '1.5',
    }),
    minWidth: 120,
  };

  const totalInvestment = {
    id: 'total_investment',
    label: t('General_TotalInvestment'),
    subtitle: `€ ${t('General_Gross')}`,
    minWidth: 100,
    alignment: 'right',
  };

  const subsidies = {
    id: 'subsidies',
    label: t('General_SubsidyAmount'),
    subtitle: `€ ${t('General_Gross')}`,
    minWidth: 140,
    alignment: 'right',
  };

  const year = { id: 'year', label: t('General_PlannedFor'), minWidth: 140 };

  const deleteAction = { id: 'delete', label: '' };

  switch (building.address.country_id) {
    case country_enum.AT:
      return [
        action,
        primaryEnergy,
        efficiencyClass,
        co2Emissions,
        strandingDate,
        totalInvestment,
        subsidies,
        year,
        deleteAction,
      ];
    default:
      return [
        action,
        finalEnergy,
        efficiencyClass,
        co2Emissions,
        strandingDate,
        totalInvestment,
        subsidies,
        year,
        deleteAction,
      ];
  }
};

type Props = {
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  handleActionSelection: (selectedActionId: number, previousActionId: number | undefined) => void;
  hoverRowId: number | null;
  setMetricsData: (data: ActionPlanMetricsData) => void;
};

const ActionListTable = ({ actionPlan, handleActionSelection, hoverRowId, setMetricsData }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { language } = useLanguage();
  const [deleteActionModalOpen, setDeleteActionModalOpen] = useState(false);

  const [selectedAction, setSelectedAction] = useState<ActionFragment | null>(null);
  const [actionsWithImpact, setActionsMetricsData] = useState<ActionWithMetricData[] | []>([]);

  //TODO both queries will be combined in one query later. separate ticket
  const { data: actionsData, loading: actionsLoading } = useQuery(GET_ACTIONS_FOR_ACTION_PLAN, {
    variables: { actionPlanId: actionPlan.id },
  });

  const { data: modificationImpacts, loading } = useQuery(GET_ACTION_PLAN_METRICS, {
    variables: { actionPlanId: actionPlan.id },
    skip: !actionsData,
  });

  const [deleteActionMutation, { loading: loadingDeleteAction }] = useMutation(DELETE_ACTION, {
    refetchQueries: [GET_ACTIONS_FOR_ACTION_PLAN, GET_ACTION_PLAN_METRICS, GET_SUBSIDIES],
    onError: () =>
      enqueueSnackbar(t('ActionPlanningActionPlan_DeleteAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () =>
      enqueueSnackbar(t('ActionPlanningActionPlan_DeleteAction-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      }),
    update: (cache, { data }) => {
      if (data?.delete_action_by_pk) {
        const normalizedId = cache.identify({
          id: data?.delete_action_by_pk.id,
        });

        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  useEffect(() => {
    if (modificationImpacts && actionsData) {
      const metrics = modificationImpacts?.getActionPlanMetrics ?? [];

      const metricsData = actionsData?.action.map((action) => {
        const metric = metrics?.actions.find((m) => m.action_id === action.id);
        return {
          ...(action as ActionFragment),
          metric: metric as ActionImpactDataOutputFragment,
        };
      });

      const data: ActionPlanMetricsData = {
        actions: metricsData,
        action_plan: metrics.action_plan,
      };
      setMetricsData(data);
      setActionsMetricsData(metricsData);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modificationImpacts, actionsData]);

  const isDataLoading = actionsLoading || loading;

  const actions = actionsData?.action ?? [];

  const { building } = actionPlan;

  const deleteLastAction = async () => {
    if (!selectedAction) return;
    deleteActionMutation({ variables: { actionId: selectedAction.id } });
    setDeleteActionModalOpen(false);
  };

  const tableHead = getTableHead(building);

  const onActionRowClick = (selectedActionId: number, index: number) => {
    const previousAction = index > 0 ? actionsWithImpact[index - 1] : undefined;
    handleActionSelection(selectedActionId, previousAction?.id);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <ActionPlanSectionTitle
              title={t('General_Action_other')}
              subtitle={t('ActionDetails_EnergeticKPIs_Subtitle')}
              icon={ICONS.CHART_TIMELINE}
            />
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((headCell) => (
                        <TableCell key={headCell.id} sx={{ minWidth: headCell.minWidth, verticalAlign: 'top' }}>
                          <TableSortLabel
                            style={{ cursor: 'default' }}
                            hideSortIcon
                            sx={{
                              display: 'flex',
                            }}
                          >
                            <Stack flexDirection={'column'} width={'100%'}>
                              <span style={{ whiteSpace: 'nowrap', textAlign: headCell.alignment ?? 'left' }}>
                                {headCell.label}
                              </span>
                              {headCell.subtitle && (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  component={'div'}
                                  textAlign={headCell.alignment ?? 'left'}
                                >
                                  {headCell.subtitle}
                                </Typography>
                              )}
                            </Stack>
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {!isDataLoading ? (
                    <TableBody>
                      {actionsWithImpact.map((row, index) => {
                        const { implementation_to } = row;

                        if (!row?.metric?.modification_impact) {
                          return null;
                        }
                        const { co2_emissions, efficiency_class, final_energy, stranding_date, primary_energy } =
                          row.metric.modification_impact;

                        const { total_renovation_cost, total_subsidy_cost } = row.metric;
                        const finalEnergyBefore = Number(final_energy?.before ?? 0).toFixed(1);
                        const finalEnergyAfter = Number(final_energy?.after ?? 0).toFixed(1);

                        const co2EmissionBefore = Number(co2_emissions?.before ?? 0).toFixed(1);
                        const co2EmissionAfter = Number(co2_emissions?.after ?? 0).toFixed(1);

                        const use_custom_cost =
                          row.renovations && row.renovations.some((renovation) => renovation.use_custom_cost);

                        const strandingDateAfter = dayjs(stranding_date?.after).format('YYYY');
                        const strandingDateChange = stranding_date?.impact ?? 0;

                        const primaryEnergyBefore = Number(primary_energy?.before ?? 0).toFixed(1);
                        const primaryEnergyAfter = Number(primary_energy?.after ?? 0).toFixed(1);
                        const efficiencyClassBefore = efficiency_class?.before ?? efficiency_class_enum.UNKNOWN;
                        const efficiencyClassAfter = efficiency_class?.after ?? efficiency_class_enum.UNKNOWN;

                        const renovationType = row?.renovations[0]?.renovation_type_id;

                        const affectedEnvelopeParts = affectedEnvelopePartsByAction(
                          row,
                          actions,
                          ensureDefined(building.active_building_model),
                        );

                        const affectedConsumerParts = affectedConsumerPartsByAction(
                          row,
                          actions,
                          ensureDefined(building.active_building_model),
                        );

                        const strandingDateText =
                          strandingDateChange !== 0
                            ? `${strandingDateChange > 0 ? '-' : '+'}${Math.abs(strandingDateChange)} ${t(
                                'General_Year',
                                { count: Math.abs(strandingDateChange) },
                              )}`
                            : `0 ${t('General_Year', { count: 0 })}`;

                        return (
                          <TableRow
                            key={index}
                            hover
                            sx={{
                              cursor: 'pointer',
                              backgroundColor: hoverRowId === row.id ? theme.palette.action.hover : '',
                            }}
                            onClick={() => {
                              onActionRowClick(row.id, index);
                            }}
                          >
                            <TableCell
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 60,
                              }}
                            >
                              <Tooltip placement="top" arrow title={translateRenovationTypeEnum(renovationType)}>
                                <Box component={'img'} src={getIconURLByAction(row)} width={20} mr={2} />
                              </Tooltip>
                              <Stack>
                                <Typography variant="body2">{getActionName(row)}</Typography>
                                {!!building.active_building_model &&
                                  (row.renovations_envelope.length > 0 ? (
                                    <EnvelopeAffectedPartsSubtext
                                      buildingModel={row.building_models[0]}
                                      affectedParts={affectedEnvelopeParts}
                                      renovationType={renovationType}
                                    />
                                  ) : (
                                    <AffectedConsumersSubtext
                                      buildingModel={row.building_models[0]}
                                      affectedParts={affectedConsumerParts}
                                      renovationType={renovationType}
                                    />
                                  ))}
                              </Stack>
                            </TableCell>
                            {building.address.country_id === country_enum.AT ? (
                              <ComparisonTableCell
                                before={primaryEnergyBefore}
                                after={primaryEnergyAfter}
                                comparisonPercent={primary_energy?.impact ?? 0}
                              />
                            ) : (
                              <ComparisonTableCell
                                before={finalEnergyBefore}
                                after={finalEnergyAfter}
                                comparisonPercent={final_energy?.impact ?? 0}
                              />
                            )}
                            <ComparisonTableCell
                              before={<EfficiencyClassLabel efficiencyClass={efficiencyClassBefore} />}
                              after={<EfficiencyClassLabel efficiencyClass={efficiencyClassAfter} />}
                              hideComparisonLabel
                            />
                            <ComparisonTableCell
                              before={co2EmissionBefore}
                              after={co2EmissionAfter}
                              comparisonPercent={co2_emissions?.impact ?? 0}
                            />
                            <TableCell>
                              <Stack display="flex" flexDirection={'row'} gap={2}>
                                <Box display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                                  <Typography variant="body2">{strandingDateAfter}</Typography>
                                </Box>
                                <Label
                                  color={
                                    strandingDateChange < 0
                                      ? 'success'
                                      : strandingDateChange === 0
                                      ? 'default'
                                      : 'error'
                                  }
                                  sx={{ minWidth: '40px' }}
                                >
                                  {strandingDateText}
                                </Label>
                              </Stack>
                            </TableCell>

                            <TableCell>
                              <Typography variant="body2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={use_custom_cost ? undefined : t('General_ApproximatedValue')}
                                >
                                  <Stack
                                    direction={'row'}
                                    justifyContent={'end'}
                                    alignItems={'center'}
                                    whiteSpace={'nowrap'}
                                  >
                                    {!use_custom_cost && (
                                      <Iconify
                                        width={20}
                                        height={20}
                                        color="info.main"
                                        icon="mdi:chart-timeline-variant-shimmer"
                                        mr={1}
                                      />
                                    )}
                                    <span>{displayCost(total_renovation_cost)}</span>
                                  </Stack>
                                </Tooltip>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack display="flex" flexDirection={'row'} gap={2} justifyContent={'end'}>
                                <Box display="flex" flexDirection="row" alignItems={'center'} gap={1}>
                                  <Typography variant="body2">
                                    {total_renovation_cost !== null && total_subsidy_cost > 0
                                      ? fCurrencyTrimmed(total_subsidy_cost, { unit: '€' })
                                      : '–'}
                                  </Typography>
                                  {!!total_subsidy_cost && total_subsidy_cost > 0 && (
                                    <Label color="success">
                                      {fPercent(
                                        (total_subsidy_cost / (total_renovation_cost ?? Number.EPSILON)) * 100,
                                        0,
                                      )}
                                    </Label>
                                  )}
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell>{`${formatDateToLocale(
                              implementation_to,
                              COMMON_DATE_FORMATS.DAY_MONTH_YEAR,
                              language,
                            )}`}</TableCell>
                            <TableCell>
                              <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
                                {renovationType === renovation_type_enum.ENVELOPE && row.editable && (
                                  <IconButton>
                                    <Iconify icon={ICONS.EDIT_SQUARE} width={16} height={16} />
                                  </IconButton>
                                )}

                                {index === actionsWithImpact.length - 1 && (
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      setSelectedAction(row);
                                      setDeleteActionModalOpen(true);
                                      e.stopPropagation();
                                    }}
                                    disabled={loadingDeleteAction}
                                  >
                                    <Iconify icon={ICONS.TRASH} color="error.main" />
                                  </IconButton>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {actions.length === 0 && (
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              {t('ActionPlanningActionPlan_NoActionsAvailable')}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <Box height={300}>
                            <DelayedLoading />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Grid>
      </Grid>

      {selectedAction && (
        <DeleteConfirmationModal
          open={deleteActionModalOpen}
          onClose={() => {
            setDeleteActionModalOpen(false);
          }}
          onDelete={deleteLastAction}
          title={t('General_DeleteModalTitle-Action', { count: 1 })}
          description={
            <Trans
              i18nKey={'General_DeleteModalDescription-Action'}
              values={{ name: getActionName(selectedAction), count: 1 }}
              components={{ bold: <strong /> }}
            />
          }
        />
      )}
    </>
  );
};

export default ActionListTable;
