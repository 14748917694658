import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import FilterWithSearch from '../../../../components/search/FilterWithSearch';
import { getAvailableDataTypes, TDataType } from './SubBuildingDocuments';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

// ----------------------------------------------------------------------

type Props = {
  selectedDataType: TDataType;
  handleDataTypeChange: (value: TDataType) => void;
  filterName: string;
  handleFilterNameChange: (value: string) => void;
};

export default function SubBuildingDocumentsToolbar({
  filterName,
  handleFilterNameChange,
  handleDataTypeChange,
  selectedDataType,
}: Props) {
  const { t } = useTranslation();
  const AVAILABLE_DATA_TYPES = getAvailableDataTypes(t);

  const filterItems = AVAILABLE_DATA_TYPES.map((type) => ({
    name: type.label,
    value: type.key,
  }));

  const selectedItem = {
    name: AVAILABLE_DATA_TYPES.find((type) => type.key === selectedDataType)?.label || '',
    value: selectedDataType,
  };

  return (
    <RootStyle>
      <Stack direction="row" mb={3}>
        <TextField
          value={filterName}
          onChange={(event) => handleFilterNameChange(event.target.value)}
          placeholder={t('General_SearchPlaceholder_Document')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: 250,
            mr: 3,
            '.MuiOutlinedInput-input': {
              py: 1,
            },
          }}
        />

        <FilterWithSearch
          items={filterItems}
          selectedItem={selectedItem}
          onChanged={(value) => handleDataTypeChange(value as TDataType)}
          selectedLabel={t('General_FileType')}
          label={t('General_FileType')}
        />
      </Stack>
    </RootStyle>
  );
}
