/* eslint-disable @typescript-eslint/ban-ts-comment */
import get from 'lodash/get';
import { useCallback, useState } from 'react';

// ----------------------------------------------------------------------

type UseTableProps = {
  dense: boolean;
  page: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  //
  selected: string[];
  onSelectRow: (id: string) => void;
  onSelectAllRows: (checked: boolean, newSelecteds: string[]) => void;
  //
  onSort: (id: string) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setDense: React.Dispatch<React.SetStateAction<boolean>>;
  setOrder: React.Dispatch<React.SetStateAction<'desc' | 'asc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;

  // Custom additions
  filterName: string;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  handleRequestSort: (property: string) => void;
  handleFilterByName: (name: string) => void;
};

export type OnFilterCallbackParams = {
  order: 'asc' | 'desc';
  orderBy: string;
};

type Props = {
  defaultDense?: boolean;
  defaultOrder?: 'asc' | 'desc';
  defaultOrderBy?: string;
  defaultSelected?: string[];
  defaultRowsPerPage?: number;
  defaultCurrentPage?: number;
  onSortCallback?: ({ order, orderBy }: OnFilterCallbackParams) => void;
};

/**
 * Minimal hook
 * TODO: Add proper API documentation
 */
export default function useTable(props?: Props): UseTableProps {
  const [dense, setDense] = useState(!!props?.defaultDense);

  const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');

  const [order, setOrder] = useState<'asc' | 'desc'>(props?.defaultOrder || 'asc');

  const [page, setPage] = useState(props?.defaultCurrentPage || 0);

  const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || 5);

  const [selected, setSelected] = useState<string[]>(props?.defaultSelected || []);

  const [filterName, setFilterName] = useState('');

  const onSort = useCallback(
    (id: string) => {
      const isAsc = orderBy === id && order === 'asc';
      if (id !== '') {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
      }
    },
    [order, orderBy],
  );

  const onSelectRow = useCallback(
    (id: string) => {
      const selectedIndex = selected.indexOf(id);

      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const onSelectAllRows = useCallback((checked: boolean, newSelecteds: string[]) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }, []);

  const onChangePage = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  }, []);

  const onChangeDense = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  }, []);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const nextOrder = isAsc ? 'desc' : 'asc';
    setOrder(nextOrder);
    setOrderBy(property);
    props?.onSortCallback?.({ order: nextOrder, orderBy: property });
  };

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  return {
    dense,
    order,
    page,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,
    //
    setPage,
    setDense,
    setOrder,
    setOrderBy,
    setSelected,
    setRowsPerPage,

    filterName,
    setFilterName,
    handleRequestSort,
    handleFilterByName,
  };
}

// ----------------------------------------------------------------------

function descendingComparatorNumber(
  a: Record<string, unknown>,
  b: Record<string, unknown>,
  orderBy: ((item: any) => number) | string,
) {
  const getOrderPropertyValue = (item: Record<string, unknown>): number => {
    return typeof orderBy === 'function' ? orderBy(item) : (item[orderBy] as number);
  };

  if (getOrderPropertyValue(b) < getOrderPropertyValue(a)) {
    return -1;
  }
  if (getOrderPropertyValue(b) > getOrderPropertyValue(a)) {
    return 1;
  }
  return 0;
}

function descendingComparatorString(
  a: Record<string, unknown>,
  b: Record<string, unknown>,
  orderBy: ((item: any) => string) | string,
) {
  const getOrderPropertyValue = (item: Record<string, unknown>): string => {
    return typeof orderBy === 'function' ? orderBy(item) : (get(item, orderBy) as string);
  };
  // Handle mixed types like string | null
  if (!getOrderPropertyValue(a)) return -1;
  if (!getOrderPropertyValue(b)) return 1;

  return getOrderPropertyValue(b).localeCompare(getOrderPropertyValue(a));
}

function getComparator(
  order: 'asc' | 'desc',
  orderBy: ((item: any) => string) | ((item: any) => number) | string,
  /**
   * Is the property to be sorted a string or a number?
   */
  sortAlphabetically: boolean,
): (a: Record<string, unknown>, b: Record<string, unknown>) => number {
  if (sortAlphabetically) {
    return order === 'desc'
      ? (a, b) => descendingComparatorString(a, b, orderBy as ((item: any) => string) | string)
      : (a, b) => -descendingComparatorString(a, b, orderBy as ((item: any) => string) | string);
  } else {
    return order === 'desc'
      ? (a, b) => descendingComparatorNumber(a, b, orderBy as ((item: any) => number) | string)
      : (a, b) => -descendingComparatorNumber(a, b, orderBy as ((item: any) => number) | string);
  }
}

function isStringProperty<Data>(
  data: Data[],
  orderBy: ((item: any) => string) | ((item: any) => number) | keyof Data,
): boolean {
  const firstItem = data[0];
  return typeof orderBy === 'function'
    ? typeof orderBy(firstItem) === 'string'
    : typeof get(firstItem as Record<string, unknown>, orderBy as string) === 'string';
}
/**
 * returns the number of empty table rows to be displayed when a table has pagination so that the table doesnt twitch on page change.
 */
export function emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

type SortFilterProps<Data> = {
  data: Data[];
  orderOptions?: {
    order: 'asc' | 'desc';
    /**
     * Either a property string or a getter function if nested in object.
     * Type "string" just for simplicity and compatibility with the useTable hook.
     */
    orderBy: ((item: Data) => string | number) | keyof Data | string;
  };

  nameFilter?: {
    /**
     * Either a property string or a getter function if nested in object.
     */
    fieldName: string | ((item: Data) => string);
    filterValue: string;
  };
  /**
   * Additional filter criteria that is just applied to .filter()
   */
  dataFilters?: ((value: Data, index: number, array: Data[]) => boolean)[];
};

/**
 * Get nested key data
 * @param item
 * @param key
 */
function getValue(item: { [key: string]: any }, key: string): string {
  const data = key.split('.').reduce((parentKey, nestedKey) => parentKey?.[nestedKey], item);
  return data ? String(data) : '';
}
/**
 * Reuseable sorting function.
 * It filters for a provided name and applies any additional filter criteria it is passed.
 * Afterwards the data is sorted based on the provided ascending or descending order by the property that is passed.
 */
export function applySortFilter<Data>({
  data,

  //@ts-ignore

  //@ts-ignore
  orderOptions: { order, orderBy },
  nameFilter,
  dataFilters = [],
}: SortFilterProps<Data>) {
  // We need to make a copy of the data because if React state is passed it is read-only and can not be compared.
  data = data.slice();
  // First filter by nameField
  if (nameFilter && nameFilter.filterValue !== '') {
    data = data.filter((item: any) => {
      // Either a obj property is provided or a getter function
      const itemName =
        typeof nameFilter.fieldName === 'string' ? getValue(item, nameFilter.fieldName) : nameFilter.fieldName(item);

      return itemName?.toLowerCase().includes(nameFilter.filterValue.toLowerCase());
    });
  }

  // Apply custom filters
  dataFilters.forEach((filter) => {
    data = data.filter(filter);
  });

  if (data.length > 0) {
    // Then sort based on provided field asc or desc, either alphabetically if a string or numerically if a number
    const comparator = getComparator(order, orderBy as any, isStringProperty(data, orderBy as any));
    // @ts-ignore
    data.sort(comparator);
  }

  return data;
}
