/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Card, Container, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import InlineUser from '../../components/InlineUser';
import { createStandardErrorSnackbar } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import Unavailable from '../../components/Unavailable';
import PreDialog from '../../components/presentations/PreDialog/PreDialog';
import TablePaginationStandard from '../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../components/table/TableRowWithHighlight';
import { PermissionType } from '../../contexts/PermissionContext';
import { GET_SCENARIOS } from '../../graphql/ActionPlanning.queries';
import usePermissions from '../../hooks/usePermissions';
import useTable, { applySortFilter } from '../../hooks/useTable';
import { PATHS } from '../../routes';
import CreateScenarioModal from '../../sections/Scenarios/Scenario/CreateScenarioModal';
import ScenarioActionMenu from '../../sections/Scenarios/Scenario/ScenarioActionMenu';
import ScenariosTableHead, { HeadLabel } from '../../sections/Scenarios/Scenario/ScenariosTableHead';
import ScenariosTableToolbar from '../../sections/Scenarios/Scenario/ScenariosTableToolbar';

export default function ActionPlanningScenarios() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // TABLE STATE
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    filterName,
    handleFilterByName,
    handleRequestSort,
  } = useTable({
    defaultOrderBy: 'name',
    defaultRowsPerPage: 25,
  });
  const { checkPortfolioPermission } = usePermissions();

  const [modalOpen, setModalOpen] = useState(false);
  const [filteredScenarios, setFilteredScenarios] = useState<ActionPlanningScenariosScenarioFragment[]>([]);
  const [portfolioFilterOptions, setPortfolioFilterOptions] = useState([{ key: 0, label: t('General_AllPortfolios') }]);
  const [selectedPortfolioFilter, setSelectedPortfolioFilter] = useState(0);

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);
  // QUERY
  const { data: scenarioQueryData } = useQuery(GET_SCENARIOS, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const scenarios = useMemo(() => {
    if (!scenarioQueryData) return [];
    const portfolioOptions = [{ key: 0, label: t('General_AllPortfolios') }];
    /**
     * Filter out scenarios where the portfolio is null which might happen in certain edge cases
     * for details see PRE-4595 here: https://linear.app/predium/issue/PRE-4595/handle-buildings-in-scenarios-correctly-if-the-get-moved-out-of-a
     */
    const scenariosWithoutNull = scenarioQueryData.scenario.filter((s) => s.portfolio);

    scenariosWithoutNull.map(
      (s) =>
        !portfolioOptions.find((option) => option.key === s.portfolio.id) &&
        portfolioOptions.push({ key: s.portfolio.id, label: s.portfolio.name ?? '' }),
    );
    setPortfolioFilterOptions(portfolioOptions);
    setSelectedPortfolioFilter(0);
    setFilteredScenarios(scenariosWithoutNull);
    return scenariosWithoutNull;
  }, [scenarioQueryData, t]);

  //TABLE
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scenarios.length) : 0;

  const filteredRows = applySortFilter({
    data: filteredScenarios,
    nameFilter: {
      fieldName: 'name',
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy,
    },
  });

  const isNotFound = !filteredRows.length && Boolean(filterName);

  const handleRowClick = (id: number) => {
    navigate(PATHS.actionPlanning.scenario({ id }));
  };

  const handlePortfolioChange = (portfolioId: number) => {
    setSelectedPortfolioFilter(portfolioId);
    if (portfolioId === 0) {
      setFilteredScenarios(scenarios);
      return;
    }
    setFilteredScenarios(scenarios.filter((scenario) => scenario.portfolio?.id === portfolioId));
  };

  const tableHead: HeadLabel[] = [
    { id: 'name', label: t('General_Name'), minWidth: 300 },
    // FIXME: Sorting is broken because nested properties on the object want to be compared. Disabled for now.
    { id: 'portfolio', label: t('General_Portfolio'), sortingDisabled: true, minWidth: 200 },
    { id: 'owner', label: t('General_CreatedBy'), sortingDisabled: true, minWidth: 200 },
    { id: 'actions', label: '', sortingDisabled: true, minWidth: 200 },

    // { id: 'last_updated', label: 'Letzte Änderung', format: 'number' },
  ];

  const hasScenarioEditDeletePermission = (scenario: ActionPlanningScenariosScenarioFragment) => {
    return scenario?.portfolio ? checkPortfolioPermission(scenario.portfolio.id, PermissionType.READ) : false;
  };

  const scenariosNames = scenarios.map((s) => s.name);

  return (
    <Page title={t('PageName_Scenarios')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('PageName_Scenarios')}
          links={[]}
          infoText={t('ActionPlanningScenarios_ScenarioInfoText')}
        />

        <Card>
          <ScenariosTableToolbar
            numSelected={0}
            onFilterName={handleFilterByName}
            onCreateScenario={() => {
              setModalOpen(true);
            }}
            handlePortfolioChange={handlePortfolioChange}
            portfolioOptions={portfolioFilterOptions.map((portfolio) => ({
              name: portfolio.label,
              value: portfolio.key,
            }))}
            selectedPortfolio={{
              name: portfolioFilterOptions.find((portfolio) => portfolio.key === selectedPortfolioFilter)?.label ?? '',
              value: selectedPortfolioFilter,
            }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ScenariosTableHead
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name } = row;
                    return (
                      <TableRowWithHighlight
                        hover
                        key={id}
                        role="checkbox"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleRowClick(row.id)}
                        // @ts-ignore
                        background={hasRecentlyChanged(row.created_at ?? row.updated_at) ? 'success' : 'default'}
                      >
                        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>{name}</TableCell>
                        <TableCell>{row.portfolio?.name}</TableCell>
                        <TableCell>
                          {/*@ts-ignore */}
                          <InlineUser firstName={row.owner.first_name} lastName={row.owner.last_name} size={25} />
                        </TableCell>

                        <TableCell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          align="right"
                        >
                          <ScenarioActionMenu
                            viewType={'table'}
                            hasScenarioEditDeletePermission={hasScenarioEditDeletePermission(row)}
                            scenario={row}
                            scenariosNames={
                              scenarios ? scenarios.filter((s) => s.id !== row.id).map((scenario) => scenario.name) : []
                            }
                          />
                        </TableCell>
                      </TableRowWithHighlight>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {scenarios?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Unavailable
                          iconString="gridicons:create"
                          title={t('ActionPlanningScenarios_NoScenarioAvailable')}
                          subTitle={t('ActionPlanningScenarios_NoScenarioAvailableInfo')}
                          onClick={() => {
                            setModalOpen(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isNotFound && scenarios?.length > 0 && (
                  <TableBody>
                    <SearchNotFound searchQuery={filterName} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationStandard
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Card>

        <PreDialog
          type="definedByChildren"
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          maxWidth="sm"
          fullWidth
        >
          <CreateScenarioModal
            onClose={() => {
              setModalOpen(false);
            }}
            scenariosNames={scenariosNames}
          />
        </PreDialog>
      </Container>
    </Page>
  );
}
