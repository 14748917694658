/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, MenuItem, Stack, Tooltip } from '@mui/material';
import { area_type_enum, sub_building_class_enum } from '@predium/enums';
import { translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { ensureDefined, getAreaSum } from '@predium/utils';
import find from 'lodash/find';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommercialIcon from '../../../../../assets/Icons/CommercialIcon';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import PreDialog, { PreDialogBody, PreDialogTitle } from '../../../../../components/presentations/PreDialog/PreDialog';
import SquareMeterRow from '../Components/SquareMeterRow';
import System from '../Components/System';
import TenantAreasForm from '../forms/TenantAreasForm';
import { useArea } from '../hooks';
import { AreaMutation } from '../types';

const CLASS_ICONS = {
  [sub_building_class_enum.COMMERCIAL]: <CommercialIcon sx={{ color: 'grey.600' }} />,
  [sub_building_class_enum.RESIDENTIAL]: <Iconify color="grey.600" icon="mdi:home-outline" height={24} width={24} />,
};

const TenantAreas = () => {
  const { addArea, getPropsForEditableArea, removeArea, updateArea, fields } = useArea();

  const [isAddTenantDialogOpen, setIsAddTenantDialogOpen] = useState(false);
  const [editTenantAreaId, setEditTenantAreaId] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleOpenAddTenantAreaDialog = () => setIsAddTenantDialogOpen(true);

  const handleCloseAddTenantAreaDialog = () => setIsAddTenantDialogOpen(false);

  const handleCloseEditTenantAreaDialog = () => setEditTenantAreaId(null);

  const af = ensureDefined(find(fields, { area_type_id: area_type_enum.AF }));

  const handleAddTenantArea = (values: AreaMutation) => {
    setIsAddTenantDialogOpen(false);
    addArea(values);
  };

  const handleEditTenantArea = (values: AreaMutation) => {
    setEditTenantAreaId(null);

    if (editTenantAreaId === null) {
      throw new Error('editTenantAreaId is null');
    }

    updateArea(editTenantAreaId, values);
  };

  const tenantAreas = fields.filter((area) => area.area_type_id === area_type_enum.MF && !area.delete);
  const tenantAreasSum = getAreaSum(tenantAreas);

  const getEBFSubtitle = (area: AreaMutation) => {
    if (!area.name && !area.description) {
      return translateTypeOfUseEnum_dynamic(area.type_of_use_id, t);
    }

    const id = area.name ? `ID ${area.name || t('General_Unavailable-Short')}` : null;
    const rented = area.is_rented ? t('DataCollectionAreas_Rented') : t('DataCollectionAreas_OwnUse');
    const type = translateTypeOfUseEnum_dynamic(area.type_of_use_id, t);

    const separator = ' • ';

    return [id, rented, type].filter(Boolean).join(separator);
  };

  return (
    <>
      <System
        title={t('DataCollectionAreas_TenantAreaTitle')}
        subtitle={t('DataCollectionAreas_TenantAreaSubtitle')}
        onAddAreaClick={handleOpenAddTenantAreaDialog}
        areaSum={tenantAreasSum}
        showAreaSum={tenantAreas.length > 1}
        listAreaType={area_type_enum.MF}
        areas={
          <Stack spacing={1}>
            {tenantAreas.map((area) => (
              <SquareMeterRow
                key={area.uid}
                {...getPropsForEditableArea(area.uid)}
                options={{
                  hoverEffectSupport: true,
                  displayErrorInline: false,
                  hideEquationError: tenantAreas.length > 1,
                }}
                labels={{
                  title: area.description || t('DataCollectionAreas_UnassignedRentalArea'),
                  subtitle: getEBFSubtitle(area),
                }}
                titleProps={{ variant: 'subtitle2' }}
                icon={CLASS_ICONS[area.class_of_use_id]}
                actions={
                  <>
                    <MenuItem onClick={() => setEditTenantAreaId(area.uid)}>
                      <Iconify icon={'fa-regular:edit'} />
                      {t('General_Edit')}
                    </MenuItem>
                    <Tooltip title={tenantAreas.length === 1 ? t('DataCollectionAreas_LastItemDeleteTooltip') : null}>
                      <Box component="span">
                        <MenuItem
                          sx={{ color: 'error.main' }}
                          disabled={tenantAreas.length === 1}
                          onClick={() => removeArea(area.uid)}
                        >
                          <Iconify icon={ICONS.TRASH} />
                          {t('General_Delete')}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </>
                }
              />
            ))}
          </Stack>
        }
      />
      <Box border="1px solid" borderColor="divider" borderRadius={2} py={2}>
        <SquareMeterRow
          options={{
            hoverEffectSupport: true,
            displayErrorInline: false,
          }}
          labels={{
            title: t('DataCollectionAreas_CommonArea'),
            subtitle: t('DataCollectionAreas_CommonAreaSubtitle'),
          }}
          {...getPropsForEditableArea(af.uid)}
        />
      </Box>
      <PreDialog
        open={isAddTenantDialogOpen}
        onClose={handleCloseAddTenantAreaDialog}
        fullWidth
        type="definedByChildren"
      >
        {isAddTenantDialogOpen && (
          <PreDialogBody
            dialogtitle={<PreDialogTitle title={t('DataCollectionAreas_AddTenantAreaTitle')} />}
            content={<TenantAreasForm existingAreas={tenantAreas} onSubmit={handleAddTenantArea} />}
            actions={
              <>
                <Button variant="outlined" onClick={handleCloseAddTenantAreaDialog}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="tenant-area-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>

      <PreDialog
        open={Boolean(editTenantAreaId)}
        onClose={handleCloseEditTenantAreaDialog}
        fullWidth
        type="definedByChildren"
      >
        {editTenantAreaId !== null && (
          <PreDialogBody
            dialogtitle={<PreDialogTitle title={t('DataCollectionAreas_EditTenantAreaTitle')} />}
            content={
              <TenantAreasForm
                onSubmit={handleEditTenantArea}
                defaultValue={find(tenantAreas, { uid: editTenantAreaId })}
                existingAreas={tenantAreas}
              />
            }
            actions={
              <>
                <Button variant="outlined" onClick={handleCloseEditTenantAreaDialog}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="tenant-area-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>
    </>
  );
};

export default TenantAreas;
