import { InputAdornment, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';

type EsgAnalysisBuildingToolbarProps = {
  filterName: string;
  onFilterName: (value: string) => void;
};

export default function EsgAnalysisBuildingListToolbar({ filterName, onFilterName }: EsgAnalysisBuildingToolbarProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" sx={{ pl: 3, pb: 3 }}>
      <TextField
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={t('General_SearchBuildingPlaceholder')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: '200px',
          height: 40,
          '.MuiOutlinedInput-input': {
            py: 1,
          },
        }}
      />
    </Stack>
  );
}
