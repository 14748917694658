import { Box, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { EnvelopeUnitFragment } from '@predium/client-graphql';
import { getOrientationEnum } from '@predium/client-lookup';
import { envelope_type_enum } from '@predium/enums';
import { translateOrientationEnum_short_dynamic } from '@predium/i18n/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../assets/icons';
import Iconify from '../../../../../../components/Iconify';
import { getEnvelopeLabel } from '../../../ActionPlan.utils';
import AffectedPartsTable from '../../../Components/AffectedPartsTable';
import { EnvelopePartialRenovationOption } from '../../CreateAction';
import PartialRenovationOptionSubtext from '../PartialRenovationOptionSubtext';

type Props = {
  affectedParts: EnvelopePartialRenovationOption[];
  action: envelope_type_enum;
  envelopes: EnvelopeUnitFragment[];
};

export default function EnvelopeAffectedPartsTable({ affectedParts, action }: Props) {
  const MAX_AFFECTED_PARTS_TO_SHOW = 3;
  const disabled = affectedParts.length <= MAX_AFFECTED_PARTS_TO_SHOW;
  const [expanded, setExpanded] = useState(affectedParts.length <= MAX_AFFECTED_PARTS_TO_SHOW);
  const { t } = useTranslation();

  return (
    <AffectedPartsTable
      expanded={expanded}
      disabled={disabled}
      setExpanded={setExpanded}
      summary={
        <Stack sx={{ width: '100%' }}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="subtitle1">{t('General_AffectedParts')}</Typography>
            {!disabled && (
              <Iconify
                icon={ICONS.CHEVRON_DOWN}
                width={16}
                height={16}
                sx={{ color: 'text.secondary', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            )}
          </Stack>
          {!expanded && (
            <Typography variant="body2" mt={2}>
              {affectedParts
                ?.slice(0, MAX_AFFECTED_PARTS_TO_SHOW)
                .map((part) => {
                  if (!part || !part?.id) {
                    return '';
                  }
                  return `${getEnvelopeLabel(part, t)}`;
                })
                .join(', ')}
              {affectedParts.length > MAX_AFFECTED_PARTS_TO_SHOW && (
                <Tooltip
                  placement="top"
                  arrow
                  title={
                    <List dense sx={{ p: 0 }}>
                      {affectedParts.slice(MAX_AFFECTED_PARTS_TO_SHOW).map((part) => {
                        if (!part || !part?.id) {
                          return '';
                        }
                        return <ListItem sx={{ listStyleType: 'none' }}>{getEnvelopeLabel(part, t)}</ListItem>;
                      })}
                    </List>
                  }
                >
                  <Box component={'span'}>{`, + ${affectedParts.length - MAX_AFFECTED_PARTS_TO_SHOW}`}</Box>
                </Tooltip>
              )}
            </Typography>
          )}
        </Stack>
      }
      content={affectedParts.map((part) => {
        const label = getEnvelopeLabel(part, t);

        const showOrientation =
          action === envelope_type_enum.WALL ||
          action === envelope_type_enum.WINDOW ||
          action === envelope_type_enum.DOOR;

        const showInsulationThickness = action !== envelope_type_enum.DOOR;

        const windowThickness = part.insulation_material_name
          ?.match(/\d+/g)
          ?.map((number) => number)
          ?.join(' ');

        return (
          <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} sx={{ py: 0.5 }}>
            <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
              {label}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={1}>
              {showOrientation && (
                <PartialRenovationOptionSubtext
                  title={t('General_Orientation')}
                  icon={ICONS.ORIENTATION}
                  value={translateOrientationEnum_short_dynamic(
                    getOrientationEnum(part.orientation ?? 0, part.inclination ?? -1),
                    t,
                  )}
                />
              )}
              <PartialRenovationOptionSubtext
                title={t('General_Area')}
                icon={ICONS.AREA}
                value={`${part.area.toFixed(1)} m²`}
              />
              {action === envelope_type_enum.WINDOW ? (
                <PartialRenovationOptionSubtext
                  title={t('General_Glazing')}
                  icon={ICONS.INSULATION_THICKNESS}
                  value={`${windowThickness}x`}
                />
              ) : (
                showInsulationThickness && (
                  <PartialRenovationOptionSubtext
                    title={t('General_InsulationThickness')}
                    icon={ICONS.INSULATION_THICKNESS}
                    value={`${((part?.insulation_thickness ?? 0) * 100).toFixed(0)} cm`}
                  />
                )
              )}
              <PartialRenovationOptionSubtext
                title={t('General_UValue')}
                icon={ICONS.U_VALUE}
                value={`${part?.u_value?.toString()} W/(m²K)`}
              />
            </Stack>
          </Stack>
        );
      })}
    />
  );
}
