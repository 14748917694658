/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, MenuItem, Typography, useTheme } from '@mui/material';
import { envelope_type_enum, insulation_material_category_enum, insulation_method_enum } from '@predium/client-graphql';
import {
  DoorTemplate,
  EnvelopeRenovationTemplates,
  InsulationMaterial,
  NotWindowDoorEnvelopeType,
  WindowTemplate,
  getEnvelopeInsulationDefaults,
} from '@predium/client-lookup';
import {
  translateEnvelopeTypeEnum_dynamic,
  translateInsulationMaterialCategoryEnum,
  translateInsulationMethodEnum,
} from '@predium/i18n/client';
import { mToCm } from '@predium/utils';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormProvider,
  RHFDatePicker,
  RHFNumberField,
  RHFSelect,
  RHFTextField,
} from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { createEnvelopeInfoTable } from '../../../../Scenario/scenarios.util';
import { getInsulationCostsPerM2, getInsulationMethodOptions, getIsCostAvailable } from '../../../ActionPlan.utils';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { EnvelopePartialRenovationOption } from '../../CreateAction';
import { NonWindowDoorActionFormSchema, SetValueOptions } from '../../CreateActions/validation-schema';
import { EnvelopeActionEditProps } from '../../EditAction';
import EnvelopeAffectedPartsTable from '../../PartialRenovations/EnvelopeRenovation/EnvelopeAffectedPartsTable';

export type NonWindowDoorActionForm = {
  renovation_ids: number[];
  implementation_to: DateTime | null;
  envelope_renovation_parameter: {
    envelope_type: envelope_type_enum;
    insulation_method: insulation_method_enum;
    material_name: string;
    insulation_material_category: insulation_material_category_enum;
    insulation_lambda: number | undefined;
    insulation_thickness: number | undefined;
    cost_per_m2: number | undefined;
  };
  affected_parts: EnvelopePartialRenovationOption[];
};
export type NonWindowDoorActionSchemaType = ReturnType<typeof NonWindowDoorActionFormSchema>;

//Non window and door envelope actions
const NonWindowDoorAction = forwardRef(
  (
    {
      selectedActionType,
      previousActionBuildingModel,
      resetSimulatedData,
      minimumDate,
      maximumDate,
      implementationTo,
      loading,
      parameters,
      editable = true,
      affectedParts,
    }: EnvelopeActionEditProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { language } = useLanguage();

    const templateParameters = parameters.insulation_method
      ? EnvelopeRenovationTemplates[selectedActionType as keyof typeof EnvelopeRenovationTemplates]?.[
          parameters.insulation_method
        ]
      : [];

    const isMaterialFromTemplate = templateParameters?.some(
      (params) => params.material_name === parameters.material_name,
    );
    const allTemplateMaterialNames = templateParameters?.map((params) => params.material_name);
    // Include the material name in the dropdown if it is a custom material name or not part of the template
    const materialNames = isMaterialFromTemplate
      ? allTemplateMaterialNames
      : [...(allTemplateMaterialNames ?? []), parameters.material_name];

    const [isNewMaterial, setIsNewMaterial] = useState<boolean>(false);
    const [materialNamesList, setMaterialNamesList] = useState<string[]>(materialNames ?? []);
    const [allParameters, setAllParameters] = useState<InsulationMaterial[]>(templateParameters ?? []);

    const envelopes = previousActionBuildingModel.envelope_units;
    const buildingId = previousActionBuildingModel.building!.id;

    const infoTableData: InfoTableData[] | null = createEnvelopeInfoTable(selectedActionType, envelopes, t);

    const defaultValues: NonWindowDoorActionForm = {
      envelope_renovation_parameter: {
        envelope_type: selectedActionType as envelope_type_enum,
        insulation_method: parameters.insulation_method ?? ('' as insulation_method_enum),
        material_name: parameters.material_name,
        insulation_material_category:
          parameters.insulation_material_category ?? ('' as insulation_material_category_enum),
        insulation_lambda: parameters.insulation_lambda ?? undefined,
        insulation_thickness: parameters.insulation_thickness ? mToCm(parameters.insulation_thickness) : undefined,
        cost_per_m2: parameters.cost_per_m2 ? parseFloat(parameters.cost_per_m2.toFixed(2)) : undefined,
      },
      implementation_to: implementationTo,
      renovation_ids: [],
      affected_parts: affectedParts ?? [],
    };

    const formMethods = useForm<NonWindowDoorActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(NonWindowDoorActionFormSchema(minimumDate, language, true, maximumDate)),
    });

    const { setValue, watch } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const materialName = watch('envelope_renovation_parameter.material_name');
    const insulationMethod = watch('envelope_renovation_parameter.insulation_method');

    const insulationMethodOptions = getInsulationMethodOptions(selectedActionType as NotWindowDoorEnvelopeType);

    const setInsulationValues = (parameters: InsulationMaterial | DoorTemplate | WindowTemplate) => {
      setValue('envelope_renovation_parameter.material_name', get(parameters, 'material_name', ''), SetValueOptions);
      setValue(
        'envelope_renovation_parameter.insulation_lambda',
        get(parameters, 'insulation_lambda', 0),
        SetValueOptions,
      );
      setValue(
        'envelope_renovation_parameter.insulation_material_category',
        get(parameters, 'insulation_material_category', ''),
        SetValueOptions,
      );
      const insulationThicknessInCm = mToCm(get(parameters, 'insulation_thickness', 0));
      setValue('envelope_renovation_parameter.insulation_thickness', insulationThicknessInCm, SetValueOptions);

      const costPerM2 = getInsulationCostsPerM2(get(parameters, 'cost_per_cm_m2', 0), insulationThicknessInCm);
      setValue('envelope_renovation_parameter.cost_per_m2', costPerM2, SetValueOptions);
    };

    const handleMethodChange = (newMethod: insulation_method_enum) => {
      resetSimulatedData();
      const values: InsulationMaterial[] =
        EnvelopeRenovationTemplates[selectedActionType as keyof typeof EnvelopeRenovationTemplates]?.[newMethod] ?? [];
      if (values?.length > 0) {
        setAllParameters(values);
        setMaterialNamesList(values.map((value) => value.material_name));
        const parameters = getEnvelopeInsulationDefaults(selectedActionType as NotWindowDoorEnvelopeType, newMethod);
        if (parameters) {
          setInsulationValues(parameters);
        }
      }
      setIsNewMaterial(false);
    };

    const handleMaterialChange = (selectedMaterialName: string) => {
      resetSimulatedData();

      const values = allParameters.find((material) => material?.material_name === selectedMaterialName);
      if (values) {
        setInsulationValues(values);
      } else if (selectedMaterialName === parameters?.material_name) {
        setValue('envelope_renovation_parameter.insulation_lambda', parameters?.insulation_lambda ?? 0, {
          shouldDirty: true,
        });
        setValue(
          'envelope_renovation_parameter.insulation_material_category',
          parameters.insulation_material_category ?? ('' as insulation_material_category_enum),
          {
            shouldDirty: true,
          },
        );
        setValue(
          'envelope_renovation_parameter.insulation_thickness',
          parameters.insulation_thickness ? mToCm(parameters.insulation_thickness) : 0,
          {
            shouldDirty: true,
          },
        );
      }
    };

    const handleAddNewMaterial = () => {
      //TODO: use reset instead of setValue to reset the form
      setValue('envelope_renovation_parameter.material_name', '', {
        shouldDirty: true,
      });

      setValue('envelope_renovation_parameter.cost_per_m2', 0, {
        shouldDirty: true,
      });
      setValue('envelope_renovation_parameter.insulation_lambda', 0, {
        shouldDirty: true,
      });
      setValue('envelope_renovation_parameter.insulation_material_category', '' as insulation_material_category_enum, {
        shouldDirty: true,
      });
      setValue('envelope_renovation_parameter.insulation_thickness', 0, {
        shouldDirty: true,
      });
      setIsNewMaterial(true);
    };

    const shouldDisable = !isNewMaterial || materialNamesList.includes(materialName) || loading || !editable;

    useEffect(() => {
      setValue(
        'renovation_ids',
        affectedParts.flatMap((part: EnvelopePartialRenovationOption) => part.id),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    }, [affectedParts, setValue]);

    const handleInsulationThicknessChange = (value: number | undefined) => {
      if (isNil(value)) {
        return;
      }
      resetSimulatedData();
      // @ts-ignore Don't know what this does or what type it is.
      const parameters = EnvelopeRenovationTemplates[selectedActionType]?.[insulationMethod].find(
        // @ts-ignore Don't know what this does or what type it is.
        (material) => material.material_name === materialName,
      );
      const insulationThicknessInCm = value;
      const insulationCostsPerM2 = getInsulationCostsPerM2(
        get(parameters, 'cost_per_cm_m2', 0),
        insulationThicknessInCm,
      );
      setValue('envelope_renovation_parameter.cost_per_m2', insulationCostsPerM2);
    };

    const isCostAvailableForInsulation = getIsCostAvailable(materialName, allParameters);

    const actionType = parameters.envelope_type;

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={2}>
              <InfoTable buildingId={buildingId} data={infoTableData} title={t('General_StateBeforeRenovation')} />
            </Box>
          )}

          {affectedParts && affectedParts.length > 0 && !!actionType && (
            <EnvelopeAffectedPartsTable affectedParts={affectedParts} action={actionType} envelopes={envelopes} />
          )}

          <RHFSelect
            name="envelope_renovation_parameter.insulation_method"
            label={t('General_Selection')}
            size={'small'}
            sx={{ ...SelectSx, mb: 3 }}
            onValueChange={handleMethodChange}
            disabled={loading || !editable}
          >
            {insulationMethodOptions.map((method: insulation_method_enum) => (
              <MenuItem key={method} value={method}>
                <StyledListItemText action={translateInsulationMethodEnum(method)} />
              </MenuItem>
            ))}
          </RHFSelect>

          {insulationMethod && (
            <Box
              sx={{
                p: 2,
                border: `1px solid ${theme.palette.grey[500_32]}`,
                borderRadius: '8px',
                mb: 3,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {isNewMaterial ? (
                    <RHFTextField
                      name="envelope_renovation_parameter.material_name"
                      label={translateEnvelopeTypeEnum_dynamic(selectedActionType as envelope_type_enum, t)}
                      size={'small'}
                      sx={{ ...NumberInputSx, pb: 0, mb: 0 }}
                      disabled={loading || !editable}
                      onChange={(e) => {
                        resetSimulatedData();
                        setValue('envelope_renovation_parameter.material_name', e.target.value, SetValueOptions);
                      }}
                      autoComplete="off"
                    />
                  ) : (
                    <RHFSelect
                      name="envelope_renovation_parameter.material_name"
                      label={t('General_InsulationMaterial')}
                      size={'small'}
                      sx={{ ...SelectSx, pb: 0, mb: 0 }}
                      disabled={!insulationMethod || loading || !editable}
                      onValueChange={handleMaterialChange}
                    >
                      {materialNamesList.map((material) => (
                        <MenuItem key={material} value={material}>
                          <StyledListItemText
                            action={material}
                            secondary={`${getIsCostAvailable(material, allParameters) ? '€' : ''}`}
                          />
                        </MenuItem>
                      ))}
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleAddNewMaterial();
                        }}
                      >
                        <Button
                          type="button"
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          size="small"
                          variant="text"
                        >
                          {t('CreateNewEnvelopeAction_CreateCustomInsulationMaterial')}
                        </Button>
                      </MenuItem>
                    </RHFSelect>
                  )}
                </Grid>
              </Grid>

              {(materialName || isNewMaterial) && (
                <Box mb={isNewMaterial ? 2 : 0}>
                  <RHFSelect
                    name="envelope_renovation_parameter.insulation_material_category"
                    label={t('CreateNewEnvelopeAction_InsulationMaterialCategory')}
                    size={'small'}
                    sx={{ ...SelectSx, my: 2, pb: 0 }}
                    disabled={shouldDisable}
                    onChange={(e) => {
                      resetSimulatedData();
                      setValue(
                        'envelope_renovation_parameter.insulation_material_category',
                        e.target.value as insulation_material_category_enum,
                        SetValueOptions,
                      );
                    }}
                  >
                    {Object.values(insulation_material_category_enum).map((category) => (
                      <MenuItem key={category} value={category}>
                        <StyledListItemText action={translateInsulationMaterialCategoryEnum(category)} />
                      </MenuItem>
                    ))}
                  </RHFSelect>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <RHFNumberField
                        name="envelope_renovation_parameter.insulation_lambda"
                        label={`${t('General_ThermalConductivity')} [W/(m·K)]`}
                        size={'small'}
                        sx={NumberInputSx}
                        disabled={shouldDisable || loading}
                        onValueChange={() => {
                          resetSimulatedData();
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <RHFNumberField
                        name="envelope_renovation_parameter.insulation_thickness"
                        label={`${t('General_InsulationThickness')} [cm]`}
                        size={'small'}
                        sx={NumberInputSx}
                        disabled={loading}
                        onValueChange={(values) => handleInsulationThicknessChange(values.floatValue)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {isCostAvailableForInsulation && (
                      <Grid item xs={6} mt={2}>
                        <RHFNumberField
                          name="envelope_renovation_parameter.cost_per_m2"
                          label={t('General_PricePerM2Insulation')}
                          size={'small'}
                          sx={NumberInputSx}
                          disabled={shouldDisable}
                          onValueChange={() => {
                            resetSimulatedData();
                          }}
                        />
                      </Grid>
                    )}
                    {!isNewMaterial && !isCostAvailableForInsulation && (
                      <Grid item xs={12} mt={2}>
                        <Typography variant="caption" sx={{ display: 'flex', mb: 0 }} gutterBottom>
                          {t('General_NoCostAvailableForAction')}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </Box>
          )}
          <RHFDatePicker
            label={t('General_PlannedFor')}
            name="implementation_to"
            size={'small'}
            sx={NumberInputSx}
            disabled={loading || !editable}
            minDate={minimumDate}
            maxDate={maximumDate}
            onChange={(date) => {
              resetSimulatedData();
              setValue('implementation_to', date as DateTime);
            }}
          />
        </Box>
      </FormProvider>
    );
  },
);

export default NonWindowDoorAction;
