import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { TARGET_PATH_FIRST_YEAR, TARGET_PATH_LAST_YEAR } from '@predium/client-lookup';
import { fShortenNumber } from '@predium/utils';
import merge from 'lodash/merge';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../../theme/apexcharts';

type Props = {
  data: number[];
  graphStartYear: number;
};

const ExcessEmissionCost = ({ data, graphStartYear }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isDataAvailable = data.length > 0;

  const totalYears = TARGET_PATH_LAST_YEAR - graphStartYear;
  const categories = isDataAvailable
    ? Array.from({ length: totalYears + 1 }, (_, i) => graphStartYear + i)
    : Array.from({ length: 31 }, (_, i) => TARGET_PATH_FIRST_YEAR + i);

  const options: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        offsetY: -40,
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '80%',
        borderRadius: 8,
        borderRadiusApplication: 'end',
        colors: {
          ranges: [
            {
              from: -Infinity,
              to: 0,
              color: theme.palette.excessEmissionCostColors.value,
            },
            {
              from: 0,
              to: Infinity,
              color: theme.palette.excessEmissionCostColors.cost,
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      title: {
        text: t('General_Year_other'),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
        offsetY: -10,
      },
      categories: categories,
      labels: {
        formatter: (value: number, timestamp: number) => (timestamp % 2 === 0 ? value : ''),
      },
    },
    yaxis: {
      labels: {
        formatter: (val: number) => fShortenNumber(val),
      },
    },
    crosshairs: {
      enabled: false,
    },

    tooltip: {
      enabled: true,
      shared: false,

      custom: function ({
        series,
        seriesIndex,
        dataPointIndex,
      }: {
        series: number[][];
        seriesIndex: number;
        dataPointIndex: number;
        w: any;
      }) {
        const xValue = categories[dataPointIndex];
        const yValue = series[seriesIndex][dataPointIndex];
        return `
        <div  class="apexcharts-custom-tooltip" style="font-size:12px;">
         <div>
            <span>${t('General_Year')}: <strong>${xValue}</strong></span>
            </div>
            <div>
              <span>${
                yValue > 0
                  ? t('EsgAnalysis_ExcessEmissionCost-IncurredCost')
                  : t('EsgAnalysis_ExcessEmissionCost-AvoidedCost')
              }: <strong>${fShortenNumber(yValue)} €/a</strong></span>
            </div>
        </div>
        `;
      },
      legend: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    noData: {
      text: t('General_NoDataAvailable'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: -40,
      style: {
        color: theme.palette.text.secondary,
        fontSize: '12px',
        fontFamily: undefined,
      },
    },
  });

  const series = [
    {
      name: '',
      data: data,
    },
  ];

  return (
    <Box>
      <Chart options={options} series={series} type="bar" height={350} />
      <Stack alignItems={'center'} justifyContent={'center'} direction={'row'}>
        <Stack direction={'row'} alignItems={'center'} mr={2}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: alpha(theme.palette.success.light, 0.8),
              mr: 1,
            }}
          />
          <Typography variant="body2">{t('EsgAnalysis_ExcessEmissionCost-AvoidedCost')}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: alpha(theme.palette.error.light, 0.8),
              mr: 1,
            }}
          />
          <Typography variant="body2">{t('EsgAnalysis_ExcessEmissionCost-IncurredCost')}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ExcessEmissionCost;
