import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

// ----------------------------------------------------------------------

type TableSearchActionToolbarProps = {
  numSelected: number;
  actionLabel?: string;
  filterTerm?: string;
  filterPlaceholder?: string;
  onFilterTermChange?: (value: string) => void;
  onAction?: VoidFunction;
  noPadding?: boolean;
};

export default function TableSearchActionToolbar({
  numSelected,
  filterTerm,
  filterPlaceholder,
  onFilterTermChange,
  actionLabel,
  onAction,
}: TableSearchActionToolbarProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const filterActive = filterPlaceholder && onFilterTermChange;
  const actionActive = onAction && actionLabel;

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="flex-start" py={2}>
        {filterActive && (
          <TextField
            value={filterTerm}
            onChange={(event) => onFilterTermChange(event.target.value)}
            placeholder={filterPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: '200px',
              height: 40,
              '.MuiOutlinedInput-input': {
                py: 1,
              },
            }}
          />
        )}
      </Stack>

      {actionActive && (
        <Button
          variant="contained"
          startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
          sx={{ py: 1 }}
          onClick={onAction}
        >
          {actionLabel}
        </Button>
      )}
    </RootStyle>
  );
}
