import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { DataCollectionEconomicUnitFragment } from '@predium/client-graphql';
import {
  building_state_enum,
  country_enum,
  country_state_enum,
  sub_building_class_enum,
  type_of_use_enum,
} from '@predium/enums';
import {
  translateBuildingStateEnum,
  translateCountryEnum,
  translateCountryStateEnum,
  translateSubBuildingClassEnum,
  translateTypeOfUseEnum,
} from '@predium/i18n/client';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { COUNTRIES_ICON_PATH } from '../../../../assets/images';
import { RHFDatePicker, RHFNumberField, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import RHFToggleButton from '../../../../components/hook-form/RHFToggleButton';
import useOrgPreferences from '../../../../hooks/useOrgPreferences';
import useSessionData from '../../../../hooks/useSessionData';

type Props = {
  portfolios: { id: number; name?: string }[];
  users: { id: number; first_name?: string; last_name?: string }[];
  economicUnits: DataCollectionEconomicUnitFragment[];
};

export default function EnergyCertificateDraftBasicDataTab({ portfolios, users, economicUnits }: Props) {
  const { t } = useTranslation();
  const { economicUnitsToggled } = useOrgPreferences();
  const { setValue, getValues, watch, trigger, getFieldState, formState } = useFormContext();

  const {
    serverSideFeatureFlags: { AUSTRIAN_BGF },
  } = useSessionData();

  const typeOfUse = watch('type_of_use_id');
  const subBuildingClass: sub_building_class_enum = watch('sub_building_class_id');

  const country = watch('country_id');
  const [countryPrefix] = country.split('_');

  const usableArea = watch('area_usable');
  const grossArea = watch('area_gross');

  const stateOptions = Object.values(country_state_enum).filter((value) => value.startsWith(countryPrefix));

  const residentialTypeOfUse: type_of_use_enum[] = useMemo(() => {
    return [type_of_use_enum.SFH, type_of_use_enum.MFH];
  }, []);

  const commercialTypeOfUse: type_of_use_enum[] = useMemo(() => {
    return Object.values(type_of_use_enum).filter(
      (value) => value !== type_of_use_enum.SFH && value !== type_of_use_enum.MFH,
    );
  }, []);

  const onCountryChange = (country: country_enum) => {
    setValue('country_id', country);
    setValue('country_state_id', '');

    // Validation is different for postal code in different countries
    // so we trigger validation again when country is changed
    // to show the correct error message
    const postalCodeError = getFieldState('postal_code').error;

    if (getValues('postal_code') !== '' || postalCodeError) {
      trigger('postal_code');
    }

    if (formState.isSubmitted) {
      trigger(['area_gross', 'area_usable']);
    }
  };

  const onPortfolioChange = (portfolioId: number) => {
    setValue('portfolio_id', portfolioId);
    setValue('economic_unit_id', null);
  };

  const getTypeOfUseForSubBuildingClass = () => {
    const isResidential = subBuildingClass === sub_building_class_enum.RESIDENTIAL;
    return isResidential ? residentialTypeOfUse : commercialTypeOfUse;
  };

  useEffect(() => {
    const isResidential = subBuildingClass === sub_building_class_enum.RESIDENTIAL;
    const validTypes = isResidential ? residentialTypeOfUse : commercialTypeOfUse;

    if (!validTypes.includes(typeOfUse) && typeOfUse !== '') {
      setValue('type_of_use_id', '');
    }
  }, [commercialTypeOfUse, residentialTypeOfUse, setValue, subBuildingClass, typeOfUse]);

  useEffect(() => {
    if (!AUSTRIAN_BGF) {
      return;
    }

    if (formState.dirtyFields.area_usable || formState.dirtyFields.area_gross) {
      trigger(['area_gross', 'area_usable']);
    }
  }, [
    trigger,
    usableArea,
    grossArea,
    formState.dirtyFields.area_usable,
    formState.dirtyFields.area_gross,
    AUSTRIAN_BGF,
  ]);

  return (
    <Box sx={{ px: 2, py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6">{t('General_BuildingStatus')}</Typography>
        </Grid>
        <Grid container item xs={12} spacing={4} alignItems="top">
          <Grid item xs={6}>
            <RHFSelect
              label="Portfolio"
              name="portfolio_id"
              includeEmptyOption
              onChange={(e) => onPortfolioChange(Number(e.target.value))}
            >
              {portfolios.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>

          {economicUnitsToggled && (
            <Grid item xs={6}>
              <RHFSelect label={t('General_EconomicUnit')} name="economic_unit_id" includeEmptyOption>
                {economicUnits.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                    {option.customer_economic_unit_id && ` (${option.customer_economic_unit_id})`}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          )}

          <Grid item xs={6}>
            <RHFToggleButton
              name="building_state_id"
              leftValue={{
                key: building_state_enum.ACQUISITION,
                value: translateBuildingStateEnum(building_state_enum.ACQUISITION),
              }}
              rightValue={{
                key: building_state_enum.INVENTORY,
                value: translateBuildingStateEnum(building_state_enum.INVENTORY),
              }}
              size="medium"
            />
          </Grid>
          <Grid item xs={6}>
            <RHFSelect label={t('General_ResponsibleUser')} name="responsible_user_id" includeEmptyOption>
              {users.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.first_name} {option.last_name}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFSelect label={t('General_MonumentProtection')} name="monument_protection">
              <MenuItem value="true">{t('General_Yes')}</MenuItem>
              <MenuItem value="false">{t('General_No')}</MenuItem>
            </RHFSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFSelect label={t('General_Leasehold')} name="leasehold">
              <MenuItem value="true">{t('General_Yes')}</MenuItem>
              <MenuItem value="false">{t('General_No')}</MenuItem>
            </RHFSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFSelect label={t('General_HeritageDistrict')} name="heritage_district">
              <MenuItem value="true">{t('General_Yes')}</MenuItem>
              <MenuItem value="false">{t('General_No')}</MenuItem>
            </RHFSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFSelect label={t('General_MilieuProtection')} name="milieu_protection">
              <MenuItem value="true">{t('General_Yes')}</MenuItem>
              <MenuItem value="false">{t('General_No')}</MenuItem>
            </RHFSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFTextField name="customer_external_identifier" label={t('General_CustomerExternalIdentifier')} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{t('General_GeneralData')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <RHFTextField name="street" label={t('General_StreetCommaNumber')} />
        </Grid>
        <Grid item xs={3}>
          <RHFNumberField
            allowLeadingZeros
            name="postal_code"
            label={t('General_PostalCode')}
            valueAsText
            allowDecimals={false}
          />
        </Grid>
        <Grid item xs={3}>
          <RHFTextField name="city" label={t('General_City')} />
        </Grid>
        <Grid item xs={6}>
          <RHFSelect
            label={t('General_Country')}
            name="country_id"
            sx={{
              '.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onChange={(e) => onCountryChange(e.target.value as country_enum)}
          >
            {Object.values(country_enum).map((option) => (
              <MenuItem key={option} value={option}>
                <Box component={'img'} src={`${COUNTRIES_ICON_PATH}${option}.svg`} mr={1}></Box>
                {translateCountryEnum(option)}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item xs={6}>
          <RHFSelect label={t('General_CountryState')} name="country_state_id" includeEmptyOption>
            {stateOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {translateCountryStateEnum(option)}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>

        <Grid item xs={6}>
          <RHFNumberField name="year_constructed" label={t('General_ConstructionYear')} allowDecimals={false} />
        </Grid>
        <Grid item xs={6}>
          <RHFNumberField name="area_usable" label={t('General_EnergyReferenceArea')} />
        </Grid>
        <Grid container item xs={12} spacing={4} alignItems="top">
          <Grid item xs={6}>
            <RHFToggleButton
              name="sub_building_class_id"
              leftValue={{
                key: sub_building_class_enum.RESIDENTIAL,
                value: translateSubBuildingClassEnum(sub_building_class_enum.RESIDENTIAL),
              }}
              rightValue={{
                key: sub_building_class_enum.COMMERCIAL,
                value: translateSubBuildingClassEnum(sub_building_class_enum.COMMERCIAL),
              }}
              size="medium"
            />
          </Grid>
          <Grid item xs={6}>
            <RHFSelect label={t('General_TypeOfUse')} name="type_of_use_id" includeEmptyOption>
              {getTypeOfUseForSubBuildingClass().map((option) => (
                <MenuItem key={option} value={option}>
                  {translateTypeOfUseEnum(option)}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <RHFNumberField
            name="units"
            label={
              subBuildingClass === sub_building_class_enum.RESIDENTIAL
                ? t('General_LivingUnit')
                : subBuildingClass === sub_building_class_enum.COMMERCIAL
                ? t('General_CommercialUnit')
                : t('General_Units')
            }
            allowDecimals={false}
          />
        </Grid>

        {AUSTRIAN_BGF && country === country_enum.AT && (
          <Grid item xs={6}>
            <RHFNumberField name="area_gross" nullable label={t('DataCollectionAreas_GrossFloorArea')} />
          </Grid>
        )}
        <Grid item xs={6}>
          <RHFDatePicker name="expiry_date" label={t('General_Expiry_Date')} />
        </Grid>
      </Grid>
    </Box>
  );
}
