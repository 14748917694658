import { ApolloQueryResult, useQuery } from '@apollo/client';
import { ActionPlanningActionPlanGetActionPlanQuery } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingScreen from '../../../../components/LoadingScreen';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { GET_ACTIONPLAN } from '../../../../graphql/ActionPlanning.queries';

type ActionPlanContextProps = {
  actionPlan: ActionPlanningActionPlanGetActionPlanQuery['action_plan_by_pk'];
  reloadCurrentActionPlan: () => Promise<ApolloQueryResult<ActionPlanningActionPlanGetActionPlanQuery>>;
};

const ActionPlanContext = createContext<ActionPlanContextProps | null>(null);

type Props = PropsWithChildren<{}>;

function ActionPlanContextProvider({ children }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: actionPlanId } = useParams();

  const { data, refetch: reloadCurrentActionPlan } = useQuery(GET_ACTIONPLAN, {
    //@ts-ignore
    variables: { actionPlanId: parseInt(actionPlanId) },
    onError: () => createStandardErrorSnackbar(t)(enqueueSnackbar),
    fetchPolicy: 'cache-and-network',
  });

  if (!data) return <LoadingScreen />;

  return (
    <ActionPlanContext.Provider
      value={{
        actionPlan: data.action_plan_by_pk!,
        reloadCurrentActionPlan,
      }}
    >
      {children}
    </ActionPlanContext.Provider>
  );
}

export { ActionPlanContext, ActionPlanContextProvider };
