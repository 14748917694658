import { useQuery } from '@apollo/client';
import { Badge, Box, Container, Divider, Stack, Tab, Tabs, alpha, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DataCollectionEmissionCertificateDraftFragment,
  DataCollectionEmissionCertificateFragment,
} from '../../../../../../libs/generated/client-graphql/src/generated/graphql';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { DelayedLoading } from '../../../components/Loading';
import Page from '../../../components/Page';
import { GET_EMISSION_CERTIFICATES, GET_EMISSION_CERTIFICATE_DRAFTS } from '../../../graphql/DataCollection.queries';
import useSessionData from '../../../hooks/useSessionData';
import { PATHS } from '../../../routes';
import EmissionCertificateUploadModal from './EmissionCertificateUploadModal';
import EmissionCertificatesDraftsList from './EmissionCertificatesDraftsList';
import EmissionCertificatesList, { getStatusFromCertificate } from './EmissionCertificatesList';

export type EmissionCertificateStatusType = 'active' | 'expired' | 'in_review';
export type EmissionCertificatesTabName = 'all' | EmissionCertificateStatusType;

export type EmissionCertificateDraftStatusType = 'uploading' | 'in_review' | 'ocr_scan';

export default function EmissionCertificatesTabs() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useSessionData();
  const defaultTab = location.pathname.includes('drafts') ? 'in_review' : 'all';

  const [currentTab, setCurrentTab] = useState<EmissionCertificatesTabName>(defaultTab);
  const [uploadCertificateModalOpen, setUploadCertificateModalOpen] = useState(false);

  // QUERIES

  const { data: dataEmissionCertificates, loading: loadingEmissionCertificates } = useQuery(GET_EMISSION_CERTIFICATES, {
    fetchPolicy: 'cache-and-network',
  });

  const { data: dataEmissionCertificateDrafts, loading: loadingEmissionCertificateDrafts } = useQuery(
    GET_EMISSION_CERTIFICATE_DRAFTS,
    {
      skip: !isAdmin,
      fetchPolicy: 'cache-and-network',
    },
  );

  if (loadingEmissionCertificates || loadingEmissionCertificateDrafts) return <DelayedLoading />;

  const emissionCertificates = dataEmissionCertificates?.emission_certificate ?? [];
  const emissionCertificatesDrafts = dataEmissionCertificateDrafts?.emission_certificate_draft ?? [];

  const emissionCertificateTabs: { label: string; value: EmissionCertificatesTabName }[] = [
    {
      label: t('General_All'),
      value: 'all',
    },
    {
      label: t('General_Valid'),
      value: 'active',
    },
    {
      label: t('General_Expired'),
      value: 'expired',
    },
  ];

  const emissionCertificateTabsWithDrafts = [
    ...emissionCertificateTabs,
    { label: t('General_InReview'), value: 'in_review' },
  ];

  const onChangeHandler = (_: React.ChangeEvent<{}>, newValue: EmissionCertificatesTabName) => {
    const path =
      newValue === 'in_review'
        ? PATHS.dataCollection.emissionCertificateDrafts()
        : PATHS.dataCollection.emissionCertificates();
    setCurrentTab(newValue);
    navigate(path);
  };

  return (
    <Page title={t('General_EmissionCertificate', { count: 2 })}>
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <HeaderBreadcrumbs heading={t('General_EmissionCertificate', { count: 2 })} links={[]} sx={{ mb: 0 }} />
          <Box>
            <EmissionCertificateUploadModal open={uploadCertificateModalOpen} setOpen={setUploadCertificateModalOpen} />
          </Box>
        </Stack>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={onChangeHandler}
        >
          {emissionCertificateTabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={
                <EmissionCertificatesCount certificates={emissionCertificates} value={tab.value} label={tab.label} />
              }
              value={tab.value}
            />
          ))}
          {isAdmin && (
            <Tab
              disableRipple
              key={'in_review'}
              label={
                <EmissionCertificatesCount
                  certificates={emissionCertificatesDrafts}
                  value={'in_review'}
                  count={emissionCertificatesDrafts.length}
                  label={t('General_InReview')}
                />
              }
              value={'in_review'}
            />
          )}
        </Tabs>
        {/* Hacky way to give the Tabs component a bottom border while ensuring that the active-tab-indicator is aligned with it*/}
        <Divider sx={{ border: `1px solid ${alpha(theme.palette.grey[500], 0.08)}`, mt: '-2px' }} />

        <Box sx={{ mb: 5 }} />

        {emissionCertificateTabsWithDrafts.map((tab) => {
          if (tab) {
            const isMatched = tab.value === currentTab;
            return isMatched ? (
              <Box key={tab.value}>
                {tab.value === 'in_review' ? (
                  <EmissionCertificatesDraftsList
                    emissionCertificates={emissionCertificatesDrafts}
                    openCreationModal={() => setUploadCertificateModalOpen(true)}
                  />
                ) : (
                  <EmissionCertificatesList
                    selectedTab={currentTab}
                    emissionCertificates={emissionCertificates}
                    openCreationModal={() => setUploadCertificateModalOpen(true)}
                  />
                )}
              </Box>
            ) : null;
          }
          return null;
        })}
      </Container>
    </Page>
  );
}

type EmissionCertificatesCountProps = {
  certificates: DataCollectionEmissionCertificateFragment[] | DataCollectionEmissionCertificateDraftFragment[];
  count?: number;
  value: EmissionCertificatesTabName;
  label: string;
};

function EmissionCertificatesCount({ certificates, value, label, count }: EmissionCertificatesCountProps) {
  const theme = useTheme();

  const emissionCertificateCount =
    count ??
    certificates.filter(
      (certificate: DataCollectionEmissionCertificateFragment | DataCollectionEmissionCertificateDraftFragment) =>
        getStatusFromCertificate(certificate) === value,
    ).length;

  let textColor: string, backgroundColor: string;

  switch (value) {
    case 'expired':
      textColor = theme.palette.error.dark;
      backgroundColor = theme.palette.error.lighter;
      break;
    case 'active':
      textColor = theme.palette.text.secondary;
      backgroundColor = theme.palette.grey[500_24];
      break;
    default:
      textColor = theme.palette.grey[200];
      backgroundColor = theme.palette.grey[900];
  }

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ pr: 2, opacity: value === 'in_review' ? 0.5 : 1 }}>
        <div>{label}</div>
        {emissionCertificateCount > 0 && (
          <Badge
            badgeContent={emissionCertificateCount}
            sx={{
              pl: 2,
              '& .MuiBadge-badge': { color: textColor, backgroundColor, borderRadius: '6px', py: 1.5 },
            }}
          />
        )}
      </Stack>
    </>
  );
}
