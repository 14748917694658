import { Box, Button, Grid, MenuItem, Typography, useTheme } from '@mui/material';
import {
  energy_consumer_technology_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
} from '@predium/client-graphql';
import { TechnologyRenovationTemplates, TechnologyTemplate } from '@predium/client-lookup';
import { translateEnergyConsumerTechnologyTypeEnum, translateEnergySourceTypeEnum } from '@predium/i18n/client';
import get from 'lodash/get';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFDatePicker, RHFNumberField, RHFSelect, RHFTextField } from '../../../../../../../components/hook-form';
import { getIsCostAvailableForTechnology, getMaxDate } from '../../../../ActionPlan.utils';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../../Components/ActionCustomStyles';

function getAvailableConsumerTechnologyTypes(firstLevelKey: keyof typeof TechnologyRenovationTemplates) {
  const firstLevelObject = TechnologyRenovationTemplates[firstLevelKey];
  if (firstLevelObject) {
    const secondLevelKeys = Object.keys(firstLevelObject);
    return secondLevelKeys;
  }
  return [];
}

export default function TechnicalCommonFields({
  selectedActionType,
  minimumDate,
  loading,
  resetSimulatedData,
  noCostAvailableMessage,
}: {
  selectedActionType: energy_system_type_enum.HEATING | energy_system_type_enum.HOT_WATER;
  minimumDate: Date;
  loading: boolean;
  resetSimulatedData: () => void;
  noCostAvailableMessage?: JSX.Element;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isNewTechnology, setIsNewTechnology] = useState<boolean>(false);

  const { watch, setValue } = useFormContext();

  const technologyName = watch('system_renovation_parameter.technology_name');
  const efficiency = watch('system_renovation_parameter.efficiency');
  const consumerTechnologyType = watch('system_renovation_parameter.consumer_technology_type');

  const availableConsumerTechnologyTypes = getAvailableConsumerTechnologyTypes(
    selectedActionType as keyof typeof TechnologyRenovationTemplates,
  );

  const allParameters: TechnologyTemplate[] = consumerTechnologyType
    ? // @ts-ignore
      TechnologyRenovationTemplates[selectedActionType]?.[consumerTechnologyType]
    : [];
  const technologyNames = allParameters.map((value) => value.technology_name);
  const availableEnergySourceTypes: energy_source_type_enum[] = get(allParameters[0], 'energySourceTypes', []);

  const isCostAvailableForTechnology = getIsCostAvailableForTechnology(technologyName, allParameters);

  const showCostWarning = () => {
    const parameter = allParameters.find((param) => param.technology_name === technologyName);
    if (!isCostAvailableForTechnology || !parameter) {
      return false;
    }
    // Only show this warning when someone changed the efficiency (Aufwandszahl) of a new energy consumer
    const hasEfficiencyChanged = !!efficiency && efficiency !== (parameter as TechnologyTemplate).efficiency;

    return hasEfficiencyChanged;
  };

  const handleTechnologyNameChange = (selectedName: string) => {
    const parameters = allParameters.find((material) => material.technology_name === selectedName);
    setValue('system_renovation_parameter.efficiency', get(parameters, 'efficiency', 0));
    setValue('system_renovation_parameter.cost_per_m2', get(parameters, 'cost_per_m2', 0));
    setValue('system_renovation_parameter.energy_source_type', '');
  };

  const resetTechnologyParameters = () => {
    setValue('system_renovation_parameter.technology_name', '', {
      shouldDirty: true,
    });
    setValue('system_renovation_parameter.efficiency', 0, {
      shouldDirty: true,
    });
    setValue('system_renovation_parameter.cost_per_m2', 0, {
      shouldDirty: true,
    });
    setValue('system_renovation_parameter.energy_source_type', '', {
      shouldDirty: true,
    });
  };

  const handleAddNewTechnology = () => {
    resetTechnologyParameters();

    setIsNewTechnology(true);
  };

  const handleTechnologyTypeChange = () => {
    resetTechnologyParameters();
    resetSimulatedData();

    setIsNewTechnology(false);
  };

  const showBorder = isNewTechnology || technologyName;

  return (
    <>
      <RHFSelect
        name="system_renovation_parameter.consumer_technology_type"
        label={t('General_Selection')}
        size={'small'}
        sx={{ ...SelectSx, mb: 3 }}
        disabled={loading}
        onValueChange={handleTechnologyTypeChange}
      >
        {availableConsumerTechnologyTypes.map((consumerTechType) => (
          <MenuItem key={consumerTechType} value={consumerTechType}>
            <StyledListItemText
              action={translateEnergyConsumerTechnologyTypeEnum(
                consumerTechType as energy_consumer_technology_type_enum,
                2,
              )}
            />
          </MenuItem>
        ))}
      </RHFSelect>

      {consumerTechnologyType && (
        <Box
          sx={{
            p: showBorder ? 2 : 0,
            border: showBorder ? `1px solid ${theme.palette.grey[500_32]}` : 0,
            borderRadius: '8px',
            mb: 3,
          }}
        >
          {isNewTechnology ? (
            <RHFTextField
              name="system_renovation_parameter.technology_name"
              label={t('General_Name')}
              size={'small'}
              sx={{ ...NumberInputSx, pb: 0, mb: 0 }}
              disabled={loading}
              onChange={(e) => {
                resetSimulatedData();
                setValue('system_renovation_parameter.technology_name', e.target.value);
              }}
            />
          ) : (
            <RHFSelect
              name="system_renovation_parameter.technology_name"
              label={t('General_Technology')}
              size={'small'}
              sx={{ ...SelectSx, pb: 0, mb: 0 }}
              disabled={!consumerTechnologyType || loading}
              onValueChange={handleTechnologyNameChange}
            >
              {technologyNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <StyledListItemText
                    action={name}
                    secondary={`${getIsCostAvailableForTechnology(name, allParameters) ? '€' : ''}`}
                  />
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => {
                  handleAddNewTechnology();
                }}
                sx={{ borderTop: '1px solid', borderColor: theme.palette.divider }}
              >
                <Button
                  type="button"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  size="small"
                  variant="text"
                >
                  {t('CreateNewTechnicalAction_CustomTechnology')}
                </Button>
              </MenuItem>
            </RHFSelect>
          )}

          {(technologyName || isNewTechnology) && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={4} mb={2}>
                  <RHFNumberField
                    name="system_renovation_parameter.efficiency"
                    label={t('General_ExpenditureFactor')}
                    size={'small'}
                    sx={{ ...NumberInputSx, mt: 2 }}
                    disabled={!isNewTechnology || loading}
                    onValueChange={() => {
                      resetSimulatedData();
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  {isCostAvailableForTechnology && (
                    <RHFNumberField
                      name="system_renovation_parameter.cost_per_m2"
                      label={t('General_PricePerM2EnergyReferenceArea')}
                      size={'small'}
                      sx={{ ...NumberInputSx, mt: 2 }}
                      disabled
                      onValueChange={() => {
                        resetSimulatedData();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <RHFSelect
                name="system_renovation_parameter.energy_source_type"
                label={t('General_EnergySource')}
                size={'small'}
                sx={{ ...SelectSx, mb: 0 }}
                disabled={!technologyName || loading}
                onChange={(e) => {
                  resetSimulatedData();
                  setValue('system_renovation_parameter.energy_source_type', e.target.value);
                }}
              >
                {availableEnergySourceTypes.map((energy_type) => (
                  <MenuItem key={energy_type} value={energy_type}>
                    <StyledListItemText action={translateEnergySourceTypeEnum(energy_type)} />
                  </MenuItem>
                ))}
              </RHFSelect>
              {technologyName &&
                !isCostAvailableForTechnology &&
                !isNewTechnology &&
                (noCostAvailableMessage ?? (
                  <Typography variant="caption" sx={{ display: 'flex', mt: 2 }} gutterBottom>
                    {t('General_NoCostAvailableForAction')}
                  </Typography>
                ))}
              {showCostWarning() && (
                <Typography variant="caption" sx={{ display: 'flex', mt: 2 }} gutterBottom>
                  {t('CreateNewTechnicalAction_CostWarning')}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
      <RHFDatePicker
        label={t('General_PlannedFor')}
        name="implementation_to"
        size={'small'}
        sx={NumberInputSx}
        disabled={loading}
        minDate={minimumDate}
        maxDate={getMaxDate()}
        onChange={(date) => {
          resetSimulatedData();
          setValue('implementation_to', date as DateTime);
        }}
      />
    </>
  );
}
