import { List, ListItem, Tooltip, Typography } from '@mui/material';
import { BuildingModelFragment } from '@predium/client-graphql';
import { renovation_type_enum } from '@predium/enums';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { getSystemLabel } from '../../../ActionPlan.utils';
import { ConsumerPartialRenovationOption } from '../../CreateAction';

type Props = {
  affectedParts: ConsumerPartialRenovationOption[];
  renovationType: renovation_type_enum;
  buildingModel: BuildingModelFragment;
};

export default function AffectedConsumersSubtext({ affectedParts, renovationType, buildingModel }: Props) {
  const { t } = useTranslation();
  const MAX_AFFECTED_PARTS_TO_SHOW = 2;

  if (!affectedParts || renovationType === renovation_type_enum.ENVELOPE || affectedParts.length === 0) return null;

  const affectedSystemType = affectedParts[0]?.energy_system_consumer_routes[0]?.energy_system.energy_system_type_id;

  const useIndexForOptions = affectedParts.some((part, index) =>
    affectedParts.some(
      (otherPart, otherIndex) =>
        index !== otherIndex &&
        part.energy_consumer_technology_type_id === otherPart.energy_consumer_technology_type_id,
    ),
  );

  const possibleAffectedParts = uniqBy(
    buildingModel?.energy_systems
      .filter((s) => s.energy_system_type_id === affectedSystemType)
      .flatMap((part) => part.energy_system_consumer_routes.map((route) => route.energy_consumer)) ?? [],
    (r) => r.id,
  );

  if (possibleAffectedParts.length <= affectedParts.length) {
    return (
      <Typography variant="caption" color="text.secondary">
        {t('General_AllParts')}
      </Typography>
    );
  }

  return (
    <Typography variant="caption" color="text.secondary">
      {affectedParts
        ?.slice(0, MAX_AFFECTED_PARTS_TO_SHOW)
        .map((part) => {
          if (!part || !part?.id) {
            return '';
          }
          return `${getSystemLabel(part, useIndexForOptions, t)}`;
        })
        .join(', ')}
      {affectedParts.length > MAX_AFFECTED_PARTS_TO_SHOW && (
        <Tooltip
          placement="top"
          arrow
          title={
            <List dense sx={{ p: 0 }}>
              {affectedParts.slice(MAX_AFFECTED_PARTS_TO_SHOW).map((part, index) => {
                if (!part || !part?.id) {
                  return '';
                }
                return (
                  <ListItem key={index} sx={{ listStyleType: 'none' }}>
                    {getSystemLabel(part, useIndexForOptions, t)}
                  </ListItem>
                );
              })}
            </List>
          }
        >
          <span>{`, +${affectedParts.length - MAX_AFFECTED_PARTS_TO_SHOW}`}</span>
        </Tooltip>
      )}
    </Typography>
  );
}
