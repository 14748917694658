/* eslint-disable @typescript-eslint/ban-ts-comment */
import { EsgAnalysisBuildingBasicDetailsFragment, EsgAnalysisGetPortfolioQuery } from '@predium/client-graphql';
import { area_type_enum, sub_building_class_enum, type_of_use_enum } from '@predium/enums';
import {
  translateCountryEnum,
  translateSubBuildingClassEnum_dynamic,
  translateTypeOfUseEnum_dynamic,
} from '@predium/i18n/client';
import { fShortenToTwoDecimal, getEbfClassesOfUse, getEbfTypesOfUse, getNetArea } from '@predium/utils';
import { t } from 'i18next';
import { createSearchParams } from 'react-router-dom';
import { ICONS } from '../../assets/icons';
import { EsgAnalysisFiltersSearchParams, esgAnalysisFilters } from './EsgAnalysisFilters/EsgAnalysisFilters';

type UnitId = 'co2_emissions' | 'co2_taxes' | 'primary_energy' | 'final_energy';

type UnitMap = {
  [key in UnitId]: string;
};

//TODO move this to a shared libs PRE-3193
/**
 * Returns the unit corresponding to the given id.
 *
 * @param {UnitId} id - The id for the Unit.
 * @returns {string} The unit corresponding to the id.
 */
export const getUnit = (id: UnitId, absolute: boolean = false) => {
  const unitsMap: UnitMap = {
    co2_emissions: absolute ? 'kg CO₂e/a' : 'kg CO₂e/m²a',
    co2_taxes: absolute ? '€/a' : '€/m²a',
    primary_energy: absolute ? 'kWh/a' : 'kWh/m²a',
    final_energy: absolute ? 'kWh/a' : 'kWh/m²a',
  };

  if (!unitsMap[id]) {
    throw new Error(`Unhandled id ${id}`);
  }

  return unitsMap[id];
};

/**
 * This function calculates the absolute value multiplier by summing all the values in the areas array.
 * If the showTotal flag is true, the sum of all values is returned, otherwise 1 is returned (meaning that we show the value per m2).
 *
 * @param {boolean} showTotal - The flag to determine if the sum of all values should be returned.
 * @param {object} building - The building object containing the areas array.
 * @returns {number} The absolute value multiplier.
 */
export const getAbsoluteValueMultiplier = (
  showTotal: boolean,
  areas: { value: number; area_type_id: area_type_enum }[],
) => {
  if (showTotal) {
    return getNetArea(areas);
  }
  return 1;
};

export const filtersToSearchParams = (filters: EsgAnalysisFiltersSearchParams) =>
  createSearchParams(
    Object.fromEntries(
      Object.entries(filters).map(([key, value]) => {
        //@ts-ignore
        const { type, paramKey } = esgAnalysisFilters.find((param) => param.key === key);

        return [paramKey, type.stringify(value as any)];
      }),
    ),
  );

export const searchParamsToFilters = (searchParams: URLSearchParams) =>
  Object.fromEntries(
    esgAnalysisFilters.map(({ key, type, paramKey }) => [key, type.parse(searchParams.getAll(paramKey))]),
  );

export const getSingleUseBuildingMetadata = (building: EsgAnalysisBuildingBasicDetailsFragment) => {
  const typesOfUse = getEbfTypesOfUse(building.areas);
  const isCommercial = getEbfClassesOfUse(building.areas).has(sub_building_class_enum.COMMERCIAL);

  const metaInformation = [
    {
      label: t('General_Country'),
      value: translateCountryEnum(building.address.country_id),
      icon: ICONS.COUNTRY,
    },
    {
      label: t('General_Portfolio'),
      value: building.economic_unit.portfolio.name,
      icon: ICONS.PORTFOLIO,
    },
    {
      label: t('General_TypeOfUse'),
      value: typesOfUse.map((type: type_of_use_enum) => translateTypeOfUseEnum_dynamic(type, t)).join(', '),
      icon: isCommercial ? ICONS.COMMERCIAL_BUILDING : ICONS.RESIDENTIAL_BUILDING,
    },
    {
      label: t('General_ConstructionYear'),
      value: building.year_constructed,
      icon: ICONS.CONSTRUCTION_YEAR,
    },
    {
      label: t('General_EnergyReferenceArea'),
      value: getNetArea(building.areas) + ' m²',
      icon: ICONS.AREA,
    },
  ];
  return metaInformation;
};

export const getMixUseBuildingMetadata = (
  building: EsgAnalysisBuildingBasicDetailsFragment,
  floorAreaByBuildingClass: FloorAreaBySubBuildingClass,
) => {
  const residential = floorAreaByBuildingClass.find((group) => group.subBuildingClass === 'RESIDENTIAL');
  const commercial = floorAreaByBuildingClass.find((group) => group.subBuildingClass === 'COMMERCIAL');

  const totalArea = (residential ? residential.area : 0) + (commercial ? commercial.area : 0);

  const residentialPercentage = residential ? ((residential.area / totalArea) * 100).toFixed(2) : '0';
  const commercialPercentage = commercial ? ((commercial.area / totalArea) * 100).toFixed(2) : '0';
  return [
    {
      label: t('General_Country'),
      value: translateCountryEnum(building.address.country_id),
      icon: ICONS.COUNTRY,
    },
    {
      label: t('General_Portfolio'),
      value: building.economic_unit.portfolio.name,
      icon: ICONS.PORTFOLIO,
    },
    {
      label: t('General_BuildingClassDistribution'),
      value: `${residentialPercentage} % ${translateSubBuildingClassEnum_dynamic(
        sub_building_class_enum.RESIDENTIAL,
        t,
      )}`,
      icon: ICONS.RESIDENTIAL_BUILDING,
    },
    {
      label: t('General_BuildingClassDistribution'),
      value: `${commercialPercentage} % ${translateSubBuildingClassEnum_dynamic(
        sub_building_class_enum.COMMERCIAL,
        t,
      )}`,
      icon: ICONS.COMMERCIAL_BUILDING,
    },
    {
      label: t('General_ConstructionYear'),
      value: building.year_constructed,
      icon: ICONS.CONSTRUCTION_YEAR,
    },
    {
      label: t('General_EnergyReferenceArea'),
      value: `${fShortenToTwoDecimal(totalArea)} m²`,
      icon: ICONS.AREA2,
    },
  ];
};

export type FloorAreaBySubBuildingClass =
  EsgAnalysisGetPortfolioQuery['getAnalyzedBuildings']['floorAreaBySubBuildingClass'];

export const getPortfolioMetaInformation = (
  floorAreaByBuildingClass: FloorAreaBySubBuildingClass,
  totalBuildings: number,
) => {
  const residential = floorAreaByBuildingClass.find((group) => group.subBuildingClass === 'RESIDENTIAL');
  const commercial = floorAreaByBuildingClass.find((group) => group.subBuildingClass === 'COMMERCIAL');

  const totalArea = (residential ? residential.area : 0) + (commercial ? commercial.area : 0);

  const residentialPercentage = residential ? Math.round((residential.area / totalArea) * 100) : '0';
  const commercialPercentage = commercial ? Math.round((commercial.area / totalArea) * 100) : '0';

  return [
    {
      label: t('ESGAnalysisSection_TotalBuildings'),
      value: totalBuildings,
      icon: ICONS.BUILDING,
    },
    {
      label: t('General_BuildingClassDistribution'),
      value: `${residentialPercentage}% ${translateSubBuildingClassEnum_dynamic(
        sub_building_class_enum.RESIDENTIAL,
        t,
      )}`,
      icon: ICONS.RESIDENTIAL_BUILDING,
    },
    {
      label: t('General_BuildingClassDistribution'),
      value: `${commercialPercentage}% ${translateSubBuildingClassEnum_dynamic(sub_building_class_enum.COMMERCIAL, t)}`,
      icon: ICONS.COMMERCIAL_BUILDING,
    },
    {
      label: t('General_EnergyReferenceArea'),
      value: `${fShortenToTwoDecimal(totalArea)} m²`,
      icon: ICONS.AREA2,
    },
  ];
};

/**
 * Returns the value or the absolute value depending on the isAbsolute flag.
 *
 * it accepts an object with a key and a keyAbsolute property.
 *
 * @param {boolean} isAbsolute - The flag to determine if the absolute value should be returned.
 * @param {object} obj - The object containing the key and keyAbsolute properties.
 * @param {string} key - The key to be used to get the value.
 *
 * @returns {number} The value or the absolute value.
 */
export const getValueOrAbsolute = <T extends Record<string, any>, K extends keyof T & string>(
  isAbsolute: boolean,
  obj: {
    [P in K]: number;
  } & {
    [P in `${K & string}Absolute`]: number;
  },
  key: K,
) => {
  if (isAbsolute) {
    return obj[`${key}Absolute`];
  }

  return obj[key];
};
