import { Stack, Tooltip, Typography } from '@mui/material';
import truncate from 'lodash/truncate';
import { ReactNode } from 'react';
import Iconify from '../../../../../components/Iconify';

const MAX_LENGTH = 20;

export default function PartialRenovationOptionSubtext({
  value,
  icon,
  title,
}: {
  value: string | number;
  icon: string | ReactNode;
  title: string;
}) {
  if (!value && value === 'undefined') return null;
  const shouldTruncate = value?.toString().length > MAX_LENGTH;
  return (
    <Tooltip title={shouldTruncate ? `${title}: ${value}` : title} arrow placement="top" enterDelay={200}>
      <Stack flexDirection={'row'} alignItems={'center'} gap={0.25}>
        {typeof icon === 'string' ? <Iconify icon={icon} width="16px" height="16px" color="text.secondary" /> : icon}
        <Typography variant="body2" color="text.secondary" whiteSpace={'nowrap'}>
          {truncate(value?.toString() ?? '', { length: MAX_LENGTH })}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
