import { renovation_type_enum } from '@predium/enums';
import { ActionProps } from '../ViewAction';
import EnergyRouteAction from './TechnicalActions/EnergyRouteAction';
import HydraulicBalancingAction from './TechnicalActions/HydraulicBalancingAction';
import SolarAction from './TechnicalActions/SolarAction';
import SystemsAction from './TechnicalActions/SystemActions/SystemsAction';

export default function TechnicalAction({
  currentActionBuildingModel,
  action,
  previousActionBuildingModel,
  affectedParts,
}: ActionProps) {
  const renovationType = action?.renovations[0]?.renovation_type_id;

  const props: ActionProps = {
    currentActionBuildingModel: currentActionBuildingModel,
    action: action,
    previousActionBuildingModel: previousActionBuildingModel,
    affectedParts: affectedParts,
  };

  switch (renovationType) {
    case renovation_type_enum.SOLAR_PLANT:
      return <SolarAction {...props} />;
    case renovation_type_enum.ENERGY_CONSUMER:
      return <SystemsAction {...props} />;
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      return <EnergyRouteAction {...props} />;
    case renovation_type_enum.HYDRAULIC_BALANCING:
      // currently, every energy distribution action is hydraulic balancing
      return <HydraulicBalancingAction {...props} />;

    default:
      const exhaustiveCheck = renovationType;
      throw new Error(`Unhandled renovation type ${renovationType}: ${exhaustiveCheck}`);
  }
}
