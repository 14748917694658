import { Box, useTheme } from '@mui/material';
import { FIELD_ICON_STATES } from '../../assets/icons';
import Iconify from '../Iconify';

export enum IconState {
  Error = 'Error',
  AutoCalculated = 'AutoCalculated',
  ManualEdit = 'ManualEdit',
  Deleted = 'Deleted',
  Approximated = 'Approximated',
}

type Props = { state: IconState; noWrapper?: boolean };

export const FieldIcon = ({ state, noWrapper = false }: Props) => {
  const theme = useTheme();
  let icon: JSX.Element;

  switch (state) {
    case IconState.Error:
      icon = <Iconify icon={FIELD_ICON_STATES.MANUAL_EDIT} color={theme.palette.error.dark} />;
      break;
    case IconState.AutoCalculated:
      icon = <Iconify icon={FIELD_ICON_STATES.AUTO_CALCULATED} color={theme.palette.text.secondary} />;
      break;
    case IconState.ManualEdit:
      icon = <Iconify icon={FIELD_ICON_STATES.MANUAL_EDIT} color={theme.palette.success.dark} />;
      break;
    case IconState.Deleted:
      icon = <Iconify icon={FIELD_ICON_STATES.DELETED} color={theme.palette.error.main} />;
      break;
    case IconState.Approximated:
      icon = <Iconify icon={FIELD_ICON_STATES.APPROXIMATED} color={theme.palette.info.main} />;
      break;

    default:
      const exhaustiveCheck: never = state;
      throw new Error(`Unhandled IconState ${state}: ${exhaustiveCheck}`);
  }

  if (noWrapper) {
    return icon;
  }

  return (
    <Box
      sx={{
        //the icon is used inside the input and setting the width directly doesn't work so using the fontsize as
        fontSize: 20,
        mr: 0.5,
      }}
      component={'span'}
    >
      {icon}
    </Box>
  );
};
