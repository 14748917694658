/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Card, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import { SubBuildingDocumentsFileFragment } from '@predium/client-graphql';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileThumbnail from '../../../../components/FileThumbnail';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import PdfViewer from '../../../../components/PdfViewer';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { TableSearchNotFound } from '../../../../components/table';
import TableHeadCustom, { HeadCell } from '../../../../components/table/TableHeadCustom';
import TableMoreMenu from '../../../../components/table/TableMoreMenu';
import TablePaginationStandard from '../../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../../components/table/TableRowWithHighlight';
import { GET_SUB_BUILDING_FILES } from '../../../../graphql/DataCollection.queries';
import useTable, { applySortFilter } from '../../../../hooks/useTable';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { downloadFileUri } from '../../../../utils/createDownloadLink';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../utils/formatTime';
import useBuilding from '../Context/useBuilding';
import DeleteEnergyCertificateFile from './DeleteEnergyCertificateFile';
import MissingEnergyCertificateFiles from './MissingEnergyCertificateFile';
import SubBuildingDocumentsToolbar from './SubBuildingDocumentsToolbar';

export const getAvailableDataTypes = (t: (key: string) => string) => [
  { key: 'Alle Dateien' as const, label: t('General_AllFiles') },
  { key: 'Energieausweis' as const, label: t('General_EnergyCertificate') },
  { key: 'Rechnung' as const, label: t('General_Invoice_one') },
];

export type TDataType = ReturnType<typeof getAvailableDataTypes>[number]['key'];

export type EnrichedFileType = SubBuildingDocumentsFileFragment & {
  type: TDataType;
};

function SubBuildingDocuments() {
  const { building } = useBuilding();
  const subBuildingId = building.sub_buildings[0].id;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { language } = useLanguage();

  const AVAILABLE_DATA_TYPES = getAvailableDataTypes(t);

  const TABLE_HEAD: HeadCell[] = [
    { id: 'name', label: t('General_Name') },
    { id: 'type', label: t('General_Type') },
    { id: 'created_at', label: t('General_UploadedAt'), sortingDisabled: true },
    { id: 'actions', label: '' },
  ];

  const { page, order, orderBy, rowsPerPage, setPage, onSort, setRowsPerPage } = useTable({
    defaultOrderBy: 'name',
    defaultRowsPerPage: 25,
  });

  const [filterName, setFilterName] = useState('');
  const [selectedDataType, setSelectedDataType] = useState<TDataType>('Alle Dateien');
  const [showDocumentByUrl, setShowDocumentByUrl] = useState<string | null>(null);

  const { data } = useQuery(GET_SUB_BUILDING_FILES, {
    variables: {
      id: subBuildingId,
    },
  });

  if (!data) {
    return <DelayedLoading />;
  }

  const handleDownloadClick = async (downloadUrl: string, fileName: string) => {
    try {
      await downloadFileUri(downloadUrl, fileName);
    } catch (e) {
      enqueueSnackbar(t('General_Download-error', { fileName }), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    }
  };

  // Flatten the file property and add type for table, later differentiate into other types
  const filesEnrichedByType: EnrichedFileType[] = [];
  filesEnrichedByType.push(
    ...data
      .sub_building_by_pk!.energy_certificates.filter((certificate) => Boolean(certificate.file))
      .map<EnrichedFileType>((certificate) => ({
        ...certificate.file!,
        type: 'Energieausweis',
      })),
  );
  filesEnrichedByType.push(
    // TODO: See if the query can be optimized to not fetch all consumptions but the invoices instead.
    ...uniqBy(
      data.sub_building_by_pk!.consumptions.filter((consumption) => consumption.consumption_invoice?.id),
      (consumption) => consumption.consumption_invoice!.id,
    ).map<EnrichedFileType>((consumption) => ({
      ...consumption.consumption_invoice!.file!,
      type: 'Rechnung',
    })),
  );

  const filteredDocuments = applySortFilter({
    data: filesEnrichedByType,
    nameFilter: {
      fieldName: 'name',
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy,
    },
    dataFilters: selectedDataType !== 'Alle Dateien' ? [(file) => file.type === selectedDataType] : undefined,
  });

  // Shows an option to upload an "Energieausweis" if none is present and the user has not filtered yet.
  const noFiltersApply =
    (selectedDataType === 'Alle Dateien' || selectedDataType === 'Energieausweis') && filterName === '';

  return (
    <>
      <Card sx={{ pt: 3 }}>
        <SubBuildingDocumentsToolbar
          filterName={filterName}
          handleFilterNameChange={setFilterName}
          selectedDataType={selectedDataType}
          handleDataTypeChange={setSelectedDataType}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onSort={onSort} />
              <TableBody>
                {noFiltersApply && <MissingEnergyCertificateFiles subBuildingId={subBuildingId} />}

                {filteredDocuments.length > 0 ? (
                  filteredDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file) => {
                    const { name, type, id, created_at, downloadUrl } = file;

                    return (
                      <TableRowWithHighlight
                        background={hasRecentlyChanged(created_at) ? 'success' : 'default'}
                        key={id}
                      >
                        <TableCell sx={{ pl: 1 }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                              style={{ marginRight: '1rem' }}
                              onClick={() => setShowDocumentByUrl(downloadUrl)}
                            >
                              <FileThumbnail format="pdf" />
                            </IconButton>
                            {name}
                          </div>
                        </TableCell>
                        {/*@ts-ignore */}
                        <TableCell>{AVAILABLE_DATA_TYPES.find((t) => t.key === type).label}</TableCell>
                        <TableCell>
                          {formatDateToLocale(created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language)}
                        </TableCell>
                        {/* The responsible user must be first implemented on the file, displaying the responsible user for the sub_building here makes no sens #PRE-309 */}
                        <TableCell align="right">
                          <TableMoreMenu
                            actions={
                              <>
                                <MenuItem onClick={() => setShowDocumentByUrl(downloadUrl)}>
                                  <Iconify icon={'material-symbols:file-open-outline-rounded'} />
                                  {t('General_Open')}
                                </MenuItem>
                                {/*@ts-ignore */}
                                <MenuItem onClick={() => handleDownloadClick(downloadUrl, name)}>
                                  <Iconify icon={'ic:baseline-file-download'} />
                                  {t('General_Download')}
                                </MenuItem>
                                <DeleteEnergyCertificateFile file={file} subBuildingId={subBuildingId} />
                              </>
                            }
                          />
                        </TableCell>
                      </TableRowWithHighlight>
                    );
                  })
                ) : filterName !== '' ? (
                  <SearchNotFound searchQuery={filterName} />
                ) : (
                  <TableSearchNotFound />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={filteredDocuments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>

      <PreDialog
        type="definedByChildren"
        fullWidth
        open={Boolean(showDocumentByUrl)}
        onClose={() => setShowDocumentByUrl(null)}
      >
        <PdfViewer fileURL={showDocumentByUrl!} />
      </PreDialog>
    </>
  );
}

export default SubBuildingDocuments;
