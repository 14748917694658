/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ActionFragment,
  ActionPlanningActionSubsidyFragment,
  BuildingModelFragment,
  country_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { fPunctuatedNumber } from '@predium/utils';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import Scrollbar from '../../../../components/Scrollbar';
import { FormProvider } from '../../../../components/hook-form';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { UPDATE_CUSTOM_COST } from '../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTIONPLAN,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_ACTION_PLAN_METRICS,
  GET_SUBSIDIES,
} from '../../../../graphql/ActionPlanning.queries';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import { getActionIcon } from '../../../../utils/icons';
import { affectedConsumerPartsByAction, affectedEnvelopePartsByAction } from '../ActionPlan.utils';
import { ActionWithMetricData } from '../ActionPlanSections/ActionListTable';
import ActionDetails from './ActionDetails';
import { CustomCostsForm } from './ActionDetails/ActionPricing/RowCosts';
import { ConsumerPartialRenovationOption, EnvelopePartialRenovationOption, category_enum } from './CreateAction';
import EnvelopeAction from './ViewActions/EnvelopeAction';
import TechnicalAction from './ViewActions/TechnicalAction';

export type ActionProps = {
  currentActionBuildingModel: BuildingModelFragment;
  previousActionBuildingModel: BuildingModelFragment;
  action: ActionFragment;
  affectedParts: ConsumerPartialRenovationOption[] | EnvelopePartialRenovationOption[];
};

type Props = {
  onClose: VoidFunction;
  baseBuildingModel: BuildingModelFragment;
  selectedAction: ActionWithMetricData;
  previousAction: ActionWithMetricData | undefined;
  allActions: ActionWithMetricData[] | [];
  title: string;
  actionSubsidies: ActionPlanningActionSubsidyFragment[];
  country: country_enum;
};

export default function ViewAction({
  onClose,
  baseBuildingModel,
  selectedAction,
  previousAction,
  allActions,
  title,
  actionSubsidies,
  country,
}: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const previousActionBuildingModel = previousAction ? previousAction.building_models[0] : baseBuildingModel;
  const currentActionModel = selectedAction.building_models[0];

  const renovationType = selectedAction?.renovations[0]?.renovation_type_id;
  const category = renovationType === renovation_type_enum.ENVELOPE ? category_enum.ENVELOPE : category_enum.TECHNICAL;

  const filteredActionSubsidies = actionSubsidies.filter((subsidy) => subsidy.action.id === selectedAction.id);

  const renovationArea = useMemo(() => {
    if (!selectedAction.renovations_envelope?.length) return 0;
    return selectedAction.renovations_envelope.reduce((acc, curr) => acc + (curr.new_envelope?.area ?? 0), 0);
  }, [selectedAction]);

  const categoryShortNameConversion = (value: category_enum) => {
    switch (value) {
      case category_enum.TECHNICAL:
        return t('General_TechnicalSystem');
      case category_enum.ENVELOPE:
        return t('General_Envelope');
    }
  };

  const [updateCustomCost, { loading: updateCustomCostLoading }] = useMutation(UPDATE_CUSTOM_COST, {
    onError: () =>
      enqueueSnackbar(t('ActionPlanning-Action-UpdateActionCostError'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('ActionPlanning-Action-UpdateActionCostSuccess'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
      trackEvent('ACTION_CUSTOM_COST_UPDATED', {
        action_id: selectedAction.id,
      });
    },
    refetchQueries: [GET_ACTIONPLAN, GET_ACTIONS_FOR_ACTION_PLAN, GET_ACTION_PLAN_METRICS, GET_SUBSIDIES], //Do we need to refetch subsidies?
  });

  const handleSubmitCustomCosts = () => {
    const count = selectedAction.renovations.length;
    const customCost = methods.getValues();
    if (customCost && count) {
      const calculatedCustomCosts = {
        use_custom_cost: customCost.use_custom_cost,
        cost_total_custom:
          customCost.cost_total_custom !== undefined ? customCost.cost_total_custom / count : undefined,
      };
      updateCustomCost({
        variables: {
          action_id: selectedAction.id,
          custom_cost: calculatedCustomCosts,
        },
      });
    }
  };

  const defaultCustomCosts = {
    use_custom_cost: false,
    cost_total_custom: 0,
    cost_total: 0,
  };
  const totalCustomCosts =
    selectedAction?.renovations?.reduce((acc, curr) => {
      return {
        use_custom_cost: curr.use_custom_cost || acc.use_custom_cost,
        cost_total: (curr.cost_total ?? 0) + (acc.cost_total ?? 0),
        cost_total_custom: (curr.cost_total_custom ?? 0) + (acc.cost_total_custom ?? 0),
      };
    }, defaultCustomCosts) ?? defaultCustomCosts;

  const methods = useForm<CustomCostsForm>({
    defaultValues: {
      cost_custom_input: !isNil(totalCustomCosts?.cost_total)
        ? fPunctuatedNumber(totalCustomCosts?.cost_total)
        : undefined,
      cost_total_custom: !isNil(totalCustomCosts?.cost_total_custom) ? totalCustomCosts?.cost_total_custom : undefined,
      use_custom_cost: totalCustomCosts?.use_custom_cost ?? false,
    },
  });

  const { handleSubmit } = methods;

  const isEnvelopeAction = renovationType === renovation_type_enum.ENVELOPE;

  const props: ActionProps = {
    currentActionBuildingModel: currentActionModel,
    action: selectedAction,
    previousActionBuildingModel: previousActionBuildingModel,
    //TODO: move this to envelopes
    affectedParts: isEnvelopeAction
      ? affectedEnvelopePartsByAction(selectedAction, allActions, baseBuildingModel)
      : affectedConsumerPartsByAction(selectedAction, allActions, baseBuildingModel),
  };

  return (
    <PreDialog type="definedByChildren" open={true} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Scrollbar>
          <Box
            component={Paper}
            height={'100%'}
            sx={{
              //TODO confirm this with product
              '.Mui-disabled.MuiInputBase-input': {
                WebkitTextFillColor: theme.palette.text.secondary,
              },
              '.Mui-disabled.MuiToggleButton-root': {
                WebkitTextFillColor: theme.palette.text.secondary,
              },
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4} xl={4} p={0}>
                <Box py={3} pr={3} minHeight={500}>
                  <ToggleButtonGroup size="small" value={category} exclusive sx={{ width: '100%' }}>
                    {Object.values(category_enum).map((value, i) => (
                      <ToggleButton
                        key={i}
                        value={value}
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                        }}
                        disabled
                      >
                        <Box component={'img'} src={getActionIcon(value)} sx={{ width: 16 }} />
                        <Typography sx={{ pl: 0.5 }} variant="subtitle2">
                          {categoryShortNameConversion(value)}
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>

                  <Alert severity="info" sx={{ mt: 4, mb: 3 }}>
                    {isEnvelopeAction
                      ? t('ActionPlanning-EditAction-EnvelopeActionNotEditableInfoAlert')
                      : t('ActionPlanning-EdiAction-SystemActionInfoAlert')}
                  </Alert>

                  {isEnvelopeAction ? <EnvelopeAction {...props} /> : <TechnicalAction {...props} />}
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ mr: '-2px' }} />
              <Grid xs={12} item md={8} lg={8} xl={8} p={0}>
                <FormProvider
                  methods={methods}
                  formId="action-details-form"
                  onSubmit={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleSubmit(handleSubmitCustomCosts)();
                  }}
                >
                  <ActionDetails
                    metricData={selectedAction.metric}
                    currentView="action_modal"
                    actionSubsidies={filteredActionSubsidies}
                    renovationArea={renovationArea}
                    simulated
                    country={country}
                  />
                </FormProvider>
              </Grid>
            </Grid>
          </Box>
        </Scrollbar>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {t('General_Cancel')}
        </Button>

        <LoadingButton
          type="submit"
          form="action-details-form"
          disabled={!methods.formState.isDirty}
          variant="contained"
          loading={updateCustomCostLoading}
        >
          {t('General_Save')}
        </LoadingButton>
      </DialogActions>
    </PreDialog>
  );
}
