import { useQuery } from '@apollo/client';
import { Card, Grid, Stack } from '@mui/material';
import { EsgAnalysisBuildingBasicDetailsFragment, EsgAnalysisBuildingGetBuildingQuery } from '@predium/client-graphql';
import { getEfficiencyClass, pathValueAtYear } from '@predium/client-lookup';
import {
  country_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  eu_taxonomy_compliance_enum,
} from '@predium/enums';
import { accessEnum, getEbfClassesOfUse, getEbfTypesOfUse, getNetArea, isEuTaxonomyAvailable } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../components/Loading';
import NewEfficiencyClassLabel from '../../../components/NewEfficiencyClassLabel';
import SimpleComparisonMetric from '../../../components/SimpleComparisonMetric';
import Unavailable from '../../../components/Unavailable';
import CO2CostDevelopmentGraph from '../../../components/data-visialization/esg-analysis/CO2CostDevelopmentGraph';
import EnergyCostDevelopmentGraph from '../../../components/data-visialization/esg-analysis/EnergyCostDevelopmentGraph';
import { EnergySourceConsumptionGraph } from '../../../components/data-visialization/esg-analysis/EnergySourceGraph';
import Scope123Graph from '../../../components/data-visialization/esg-analysis/Scope123Graph';
import { GET_BENCHMARK } from '../../../graphql/EsgAnalysis.queries';
import { determineStartYear } from '../../Scenarios/ActionPlan/ActionPlan.utils';
import { calculateEUTaxonomyToBeSaved } from '../Components/EUTaxonomyTooltip';
import { ENTIRE_COMPANY } from '../Components/EsgAnalysisBenchmark';
import EUTaxonomyBadge from '../Components/EuTaxonomyBadge';
import { CrremAnalysis } from '../DemandAnalysis/CrremAnalysis';

// ----------------------------------------------------------------------

type Props = {
  building: EsgAnalysisBuildingBasicDetailsFragment;
  benchmarkId: number | undefined;
  showTotal: boolean;
  analyzeBuildingData: EsgAnalysisBuildingGetBuildingQuery['getAnalyzedBuilding'] | undefined;
  loading: boolean;
  selectedBenchmarkName: string;
};

export default function BuildingAnalysisDemandTab({
  building,
  benchmarkId,
  showTotal,
  loading,
  selectedBenchmarkName,
  analyzeBuildingData: analyzedBuilding,
}: Props) {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const getBenchmarkFilterVariables = () => {
    if (benchmarkId === ENTIRE_COMPANY) {
      return { portfolioId: -1 };
    }
    return { portfolioId: benchmarkId };
  };

  const {
    data: benchmarkData,
    loading: benchmarkLoading,
    previousData: previousBenchmarkData,
  } = useQuery(GET_BENCHMARK, {
    skip: !benchmarkId,

    // @ts-ignore
    variables: {
      ...getBenchmarkFilterVariables(),
    },
  });

  if ((loading && !analyzedBuilding) || benchmarkLoading) {
    return <DelayedLoading delay={0} />;
  }

  const benchmark = benchmarkData?.getBenchmark ?? previousBenchmarkData?.getBenchmark;

  if (!analyzedBuilding) {
    return null;
  }

  if (!benchmark || !analyzedBuilding) {
    return (
      <Stack height={'50vh'} justifyContent={'center'} alignItems={'center'}>
        <Unavailable
          title={t('EsgAnalysisBuildingSection_AnalysisNotAvailable', { buildingName: building.address.street })}
        />
      </Stack>
    );
  }

  const calculateBenchmarkChange = (value: number, benchmarkValue?: number) => {
    if (!benchmarkValue) return null;
    return (1 - value / benchmarkValue) * -1;
  };

  const efficiencyClass = accessEnum(efficiency_class_enum, analyzedBuilding.efficiencyClass);
  const euTaxonomyCompliance = accessEnum(eu_taxonomy_compliance_enum, analyzedBuilding.euTaxonomyCompliance);

  const co2EmissionsChange = calculateBenchmarkChange(analyzedBuilding.co2Emissions, benchmark?.averageCo2Emissions);

  const co2Costs = pathValueAtYear(analyzedBuilding.co2CostPath.m2, currentYear);
  const co2CostsChange = calculateBenchmarkChange(co2Costs, benchmark?.averageCo2Costs);

  const finalEnergyChange = calculateBenchmarkChange(analyzedBuilding.finalEnergy, benchmark?.averageFinalEnergy);

  const energyFinalCost = pathValueAtYear(analyzedBuilding.energyCostPath.m2, currentYear);
  const energyFinalCostChange = calculateBenchmarkChange(energyFinalCost, benchmark?.averageEnergyCosts);

  const primaryEnergyChange = calculateBenchmarkChange(analyzedBuilding.primaryEnergy, benchmark?.averagePrimaryEnergy);

  const heatingDemand = analyzedBuilding.heatingDemand;

  const heatingDemandChange =
    heatingDemand && benchmark?.averageHeatingDemand
      ? (1 - heatingDemand / benchmark?.averageHeatingDemand) * -1
      : null;

  const typesOfUse = getEbfTypesOfUse(building.areas);
  const subBuildingClass = getEbfClassesOfUse(building.areas).values().next().value!;

  const primaryEnergyToBeSaved = calculateEUTaxonomyToBeSaved(
    analyzedBuilding.primaryEnergy,
    euTaxonomyCompliance,
    building.address.country_id,
    typesOfUse[0],
  );

  const energyConsumers = analyzedBuilding.energyConsumers.map((energySource) => ({
    energy_source_type: energy_source_type_enum[energySource.energySourceType],
    energy_consumption: energySource.energyConsumptionM2,
    energy_consumption_total: energySource.energyConsumptionTotal,
  }));

  const totalArea = getNetArea(building.areas);

  const country = building.address.country_id;
  const isInAustria = country === country_enum.AT;
  const hasHeatingDemandInAustria = isInAustria && !!heatingDemand;

  const firstContainerSize = hasHeatingDemandInAustria ? 10 : 8;
  const itemSizeFirstContainer = hasHeatingDemandInAustria ? 3 : 4;

  const { co2Emissions, finalEnergy, primaryEnergy } = analyzedBuilding;

  const finalEnergyMetric = (
    <Grid item xs={itemSizeFirstContainer}>
      <SimpleComparisonMetric
        title={t('General_FinalEnergy')}
        value={showTotal ? finalEnergy * totalArea : finalEnergy}
        change={finalEnergyChange}
        selectedBenchmarkName={selectedBenchmarkName}
        unit="kWh"
        showTotal={showTotal}
        titleEndAdornment={isInAustria ? null : <NewEfficiencyClassLabel efficiencyClass={efficiencyClass} />}
      />
    </Grid>
  );

  const primaryEnergyMetric = (
    <Grid item xs={itemSizeFirstContainer}>
      <SimpleComparisonMetric
        title={t('General_PrimaryEnergy')}
        value={showTotal ? primaryEnergy * totalArea : primaryEnergy}
        change={primaryEnergyChange}
        selectedBenchmarkName={selectedBenchmarkName}
        unit="kWh"
        showTotal={showTotal}
        titleEndAdornment={
          <Stack alignItems={'center'} direction={'row'}>
            {hasHeatingDemandInAustria ? (
              <NewEfficiencyClassLabel
                efficiencyClass={getEfficiencyClass({
                  country,
                  subBuildingClass,
                  energySystems: [
                    {
                      energy_system_type: energy_system_type_enum.GENERAL,
                      energy_system_consumer_routes: [
                        {
                          energy_primary: analyzedBuilding.primaryEnergy,
                          energy_final: 0,
                          energy_source_type: energy_source_type_enum.UNSPECIFIED,
                        },
                      ],
                    },
                  ],
                  heatingDemand: null,
                })}
              />
            ) : isInAustria ? (
              <NewEfficiencyClassLabel efficiencyClass={efficiencyClass} />
            ) : null}
            {isEuTaxonomyAvailable(building.areas) && (
              <EUTaxonomyBadge compliance={euTaxonomyCompliance} toBeSaved={primaryEnergyToBeSaved} />
            )}
          </Stack>
        }
      />
    </Grid>
  );
  const graphStartYear: number = determineStartYear(building.year_constructed);

  const heatingDemandMetric = hasHeatingDemandInAustria ? (
    <Grid item xs={itemSizeFirstContainer}>
      <SimpleComparisonMetric
        title={t('General_HeatingDemand')}
        value={showTotal ? heatingDemand * totalArea : heatingDemand}
        change={heatingDemandChange}
        hideNoChange={heatingDemandChange === null}
        selectedBenchmarkName={selectedBenchmarkName}
        unit="kWh"
        showTotal={showTotal}
        titleEndAdornment={<NewEfficiencyClassLabel efficiencyClass={efficiencyClass} />}
      />
    </Grid>
  ) : null;

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={3} sx={{ pt: 3 }}>
        <Grid item xs={firstContainerSize}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              {isInAustria ? (
                <>
                  {primaryEnergyMetric}
                  {finalEnergyMetric}
                  {hasHeatingDemandInAustria && heatingDemandMetric}
                </>
              ) : (
                <>
                  {finalEnergyMetric}
                  {primaryEnergyMetric}
                </>
              )}
              <Grid item xs={itemSizeFirstContainer}>
                <SimpleComparisonMetric
                  title={t('General_CO2Intensity')}
                  value={showTotal ? co2Emissions * totalArea : co2Emissions}
                  change={co2EmissionsChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="kg CO₂e"
                  showTotal={showTotal}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12 - firstContainerSize}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={hasHeatingDemandInAustria ? 12 : 6}>
                <SimpleComparisonMetric
                  title={t('General_EnergyCost')}
                  value={showTotal ? energyFinalCost * totalArea : energyFinalCost}
                  change={energyFinalCostChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="€"
                  showTotal={showTotal}
                />
              </Grid>
              {!hasHeatingDemandInAustria && (
                <Grid item xs={6}>
                  <SimpleComparisonMetric
                    title={t('General_CO2Taxes')}
                    value={showTotal ? co2Costs * totalArea : co2Costs}
                    change={co2CostsChange}
                    selectedBenchmarkName={selectedBenchmarkName}
                    unit="€"
                    showTotal={showTotal}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CrremAnalysis
          buildingLegendName={t('General_Object')}
          cumulativeExcessEmissions={analyzedBuilding.crremAnalysis.cumulativeExcessEmissions}
          graphStartYear={graphStartYear}
          selectedBenchmarkName={selectedBenchmarkName ?? ''}
          benchmarkCrremAnalysis={benchmark?.crremAnalysis}
          crremAnalysis={analyzedBuilding.crremAnalysis}
          currentPageView="esg-building"
        />
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <Scope123Graph scopeData={analyzedBuilding.scope123Co2Emissions} showTotal={showTotal} />
        </Grid>
        <Grid item xs={6}>
          <EnergySourceConsumptionGraph
            energySources={energyConsumers}
            showTotal={showTotal}
            totalArea={totalArea}
            sx={{ minHeight: '100%' }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <CO2CostDevelopmentGraph
            co2Costs={analyzedBuilding.co2CostPath.m2}
            co2CostsTotal={analyzedBuilding.co2CostPath.total}
            landlordProportion={analyzedBuilding.taxBracket.landlordProportion}
            showTotal={showTotal}
            sx={{ minHeight: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <EnergyCostDevelopmentGraph
            energyCosts={analyzedBuilding.energyCostPath.m2}
            energyCostsTotal={analyzedBuilding.energyCostPath.total}
            showTotal={showTotal}
            sx={{ minHeight: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
