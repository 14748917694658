import { Button, InputAdornment, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import FilterWithSearch, { FilterItem } from '../../../components/search/FilterWithSearch';
import usePermissions from '../../../hooks/usePermissions';
import useSessionData from '../../../hooks/useSessionData';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  numSelected: number;
  onFilterName: (value: string) => void;
  onCreateScenario: VoidFunction;
  handlePortfolioChange: (portfolioId: number) => void;
  portfolioOptions: FilterItem[];
  selectedPortfolio: FilterItem;
};

export default function ScenariosTableToolbar({
  numSelected,
  onFilterName,
  onCreateScenario,
  handlePortfolioChange,
  selectedPortfolio,
  portfolioOptions,
}: Props) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();
  const { portfolios: portfolioPermissions } = usePermissions();
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';
  const displayCreateButton = isAdmin || portfolioPermissions?.some((permission) => permission.read);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {t('ScenariosTableToolbar_BuildingsSelectedCount', { count: numSelected })}
        </Typography>
      ) : (
        <Stack direction="row" gap={2} alignItems="center">
          <TextField
            autoComplete="off"
            onChange={(event) => onFilterName(event.target.value)}
            placeholder={t('ScenariosTableToolbar_SearchPortfolioPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: '200px',
              height: 40,
              '.MuiOutlinedInput-input': {
                py: 1,
              },
            }}
          />
          <FilterWithSearch
            label={t('General_AllPortfolios')}
            selectedLabel={t('General_Portfolio', { count: 2 })}
            items={portfolioOptions}
            selectedItem={selectedPortfolio}
            onChanged={(value) => handlePortfolioChange(value as unknown as number)}
            searchable
          />
        </Stack>
      )}

      {displayCreateButton && (
        <Button
          variant="contained"
          size="medium"
          startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
          sx={{ py: 1 }}
          onClick={onCreateScenario}
        >
          {t('General_NewScenario')}
        </Button>
      )}
    </RootStyle>
  );
}
