import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import LoadingScreen from '../../../components/LoadingScreen';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import ProcessDocumentFocusPage from '../../../components/documents/ProcessDocumentFocusPage';
import { DELETE_EMISSION_CERTIFICATE_DRAFT } from '../../../graphql/DataCollection.mutations';
import {
  GET_EMISSION_CERTIFICATE_DRAFT,
  GET_EMISSION_CERTIFICATE_DRAFTS,
} from '../../../graphql/DataCollection.queries';
import { PATHS } from '../../../routes/paths';

export default function EmissionCertificateDraftPage() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id: emissionCertificateDraftId } = useParams();
  const client = useApolloClient();
  const [deleteCertificateDraftOpen, setDeleteCertificateDraftOpen] = useState(false);

  const { data } = useQuery(GET_EMISSION_CERTIFICATE_DRAFT, {
    variables: { emissionCertificateDraftId: parseInt(emissionCertificateDraftId!) },
  });

  const [deleteEmissionCertificateDraft, { loading }] = useMutation(DELETE_EMISSION_CERTIFICATE_DRAFT, {
    variables: {
      id: parseInt(emissionCertificateDraftId!),
    },
    update: (cache, { data }) => {
      if (data?.delete_emission_certificate_draft_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_emission_certificate_draft_by_pk.id,
          __typename: data.delete_emission_certificate_draft_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_EmissionCertificateDraftDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      setDeleteCertificateDraftOpen(false);
      navigate(PATHS.dataCollection.emissionCertificateDrafts());
    },
  });

  if (!data?.emission_certificate_draft_by_pk) return <LoadingScreen />;

  return (
    <>
      <ProcessDocumentFocusPage
        emissionCertificateData={data.emission_certificate_draft_by_pk}
        draftMode
        title={t('DataCollection_ProcessDocument_ReviewFile')}
        handleCloseCallback={() => {
          client.refetchQueries({
            include: [GET_EMISSION_CERTIFICATE_DRAFTS],
          });

          navigate(PATHS.dataCollection.emissionCertificateDrafts());
        }}
        onDeleteCallback={() => setDeleteCertificateDraftOpen(true)}
      />

      <DeleteConfirmationModal
        open={deleteCertificateDraftOpen}
        loading={loading}
        title={t('General_DeleteModalTitle-EmissionCertificateDraft', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-EmissionCertificateDraft'}
            values={{ count: 1, name: data.emission_certificate_draft_by_pk.file.name }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteCertificateDraftOpen(false);
        }}
        onDelete={() => {
          deleteEmissionCertificateDraft();
        }}
      />
    </>
  );
}
