/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  ActionPlanningActionSubsidyFragment,
  BuildingModelFragment,
  country_enum,
  envelope_type_enum,
  EnvelopeRenovationParameterInput,
  EnvelopeRenovationParameterOutput,
  renovation_type_enum,
  SimulateActionOutputResponseFragment,
} from '@predium/client-graphql';
import { fPunctuatedNumber } from '@predium/utils';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import Scrollbar from '../../../../components/Scrollbar';
import { FormProvider } from '../../../../components/hook-form';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { EDIT_ACTION } from '../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTION_PLAN_METRICS,
  GET_ACTIONPLAN,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_SUBSIDIES,
  SIMULATE_EDIT_ACTION,
} from '../../../../graphql/ActionPlanning.queries';
import { ActionState } from '../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import { getActionIcon } from '../../../../utils/icons';
import {
  affectedEnvelopePartsByAction,
  getActionName,
  getIconURLByAction,
  getMaxDate,
  getModificationImpactAfterSimulation,
  getModificationImpactBeforeSimulation,
} from '../ActionPlan.utils';
import { ActionWithMetricData } from '../ActionPlanSections/ActionListTable';
import DiscardActionModal from '../Components/DiscardActionModal';
import ActionDetails from './ActionDetails';
import { CustomCostsForm } from './ActionDetails/ActionPricing/RowCosts';
import {
  category_enum,
  categoryShortNameConversion,
  EnvelopeActionFormRef,
  EnvelopeActionFormSchemaType,
  EnvelopePartialRenovationOption,
  formatEnvelopeRenovationParameter,
} from './CreateAction';
import EnvelopeAction from './EditActions/EnvelopeAction';

export type EnvelopeActionEditProps = {
  previousActionBuildingModel: BuildingModelFragment;
  selectedActionType: envelope_type_enum | '';
  ref: EnvelopeActionFormRef;
  /**
   * Minimum date for the action implementation
   * previous action implementation to or new Date()
   * @default new Date()
   */
  minimumDate: Date;
  /**
   * Maximum date for the action implementation
   * next action implementation to or 31.12.2051
   */
  maximumDate?: Date;
  /**
   * Date for the action implementation
   * selected action implementation to date
   * @default selected action implementation to date
   * */
  implementationTo: Date;
  resetSimulatedData: () => void;
  loading: boolean;
  parameters: EnvelopeRenovationParameterOutput;
  editable?: boolean;
  affectedParts: EnvelopePartialRenovationOption[];
};

type Props = {
  onClose: VoidFunction;
  baseBuildingModel: BuildingModelFragment;
  country: country_enum;
  actionSubsidies: ActionPlanningActionSubsidyFragment[];
  allActions: ActionWithMetricData[] | [];
  actionState: ActionState;
};

export default function EditAction({
  onClose,
  baseBuildingModel,
  country,
  actionSubsidies,
  allActions,
  actionState,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: actionPlanId } = useParams();

  const { selected: selectedAction, previous: previousAction, next: nextAction } = actionState;

  const renovationType = selectedAction?.renovations[0]?.renovation_type_id;

  const [category, setCategory] = useState(
    renovationType === renovation_type_enum.ENVELOPE ? category_enum.ENVELOPE : category_enum.TECHNICAL,
  );
  const [simulatedData, setSimulatedData] = useState<SimulateActionOutputResponseFragment | undefined>(undefined);
  const [isCurrentFormDirty, setIsCurrentFormDirty] = useState(false);
  const [isCostFormDirty, setIsCostFormDirty] = useState(false);
  const [showCloseWarningDialog, setCloseWarningDialog] = useState(false);

  const formRef: EnvelopeActionFormRef = useRef(null);

  const previousActionBuildingModel = previousAction ? previousAction.building_models[0] : baseBuildingModel;

  const filteredActionSubsidies = actionSubsidies.filter((subsidy) => subsidy.action.id === selectedAction?.id);

  const [simulateEnvelopeAction, { loading: simulationLoading }] = useLazyQuery(SIMULATE_EDIT_ACTION, {
    onCompleted: (data) => {
      setSimulatedData(data.simulateEditAction);
    },
    onError: () =>
      enqueueSnackbar(t('ActionPlanning_SimulateAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const [editAction, { loading: editActionLoading }] = useMutation(EDIT_ACTION, {
    onError: () =>
      enqueueSnackbar(t('ActionPlanning_EditAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('ActionPlanning_EditAction-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
    },
    refetchQueries: [GET_ACTIONPLAN, GET_ACTIONS_FOR_ACTION_PLAN, GET_ACTION_PLAN_METRICS, GET_SUBSIDIES], //Do we need to refetch subsidies?
  });

  const renovationArea = useMemo(() => {
    if (!selectedAction?.renovations_envelope?.length) return 0;
    return selectedAction.renovations_envelope.reduce((acc, curr) => acc + (curr.new_envelope?.area ?? 0), 0);
  }, [selectedAction]);

  const defaultCustomCosts = {
    use_custom_cost: false,
    cost_total_custom: 0,
    cost_total: 0,
  };

  const totalCustomCosts =
    selectedAction?.renovations?.reduce((acc, curr) => {
      return {
        use_custom_cost: curr.use_custom_cost || acc.use_custom_cost,
        cost_total: (curr.cost_total ?? 0) + (acc.cost_total ?? 0),
        cost_total_custom: (curr.cost_total_custom ?? 0) + (acc.cost_total_custom ?? 0),
      };
    }, defaultCustomCosts) ?? defaultCustomCosts;

  const costFormMethods = useForm<CustomCostsForm>({
    defaultValues: {
      cost_custom_input: !isNil(totalCustomCosts?.cost_total)
        ? fPunctuatedNumber(totalCustomCosts?.cost_total)
        : undefined,
      cost_total_custom: !isNil(totalCustomCosts?.cost_total_custom) ? totalCustomCosts?.cost_total_custom : undefined,
      use_custom_cost: totalCustomCosts?.use_custom_cost ?? false,
    },
  });

  useEffect(() => {
    if (simulatedData) {
      if (!getValues().use_custom_cost) {
        const cost2Str = simulatedData?.renovation_costs
          ? fPunctuatedNumber(simulatedData?.renovation_costs)
          : undefined;
        setCostFormValue('cost_custom_input', cost2Str);
        setCostFormValue('use_custom_cost', false);
        setCostFormValue('cost_total_custom', undefined);
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulatedData]);

  const { getValues, setValue: setCostFormValue, formState: costFormState } = costFormMethods;

  useEffect(() => {
    setIsCostFormDirty(costFormState.isDirty);
  }, [costFormState.isDirty]);

  if (!selectedAction) {
    return null;
  }

  const resetSimulatedData = () => {
    setIsCurrentFormDirty(true);
    setSimulatedData(undefined);

    if (!getValues().use_custom_cost) {
      setCostFormValue('cost_custom_input', '');
      setCostFormValue('cost_total_custom', undefined);
      setCostFormValue('use_custom_cost', false);
    }
  };

  const isDataSimulated = simulatedData !== undefined;

  if (!selectedAction) {
    return null;
  }

  const actionCurrentState = selectedAction.metric;
  const afterSimulationState = getModificationImpactAfterSimulation(selectedAction.metric, simulatedData);
  const beforeSimulatedState = getModificationImpactBeforeSimulation(selectedAction.metric, true);

  const modificationImpact = isDataSimulated
    ? afterSimulationState
    : isCurrentFormDirty
    ? beforeSimulatedState
    : actionCurrentState;

  const getCustomCost = () => {
    const formValues = getValues();
    return {
      cost_custom_input: formValues.cost_custom_input,
      cost_total_custom: formValues.cost_total_custom,
      use_custom_cost: formValues.use_custom_cost ?? false,
    };
  };

  const customCost = getCustomCost();
  const customCostWithNumbers = {
    use_custom_cost: customCost.use_custom_cost ?? false,
    cost_total_custom: customCost.cost_total_custom ?? 0,
  };

  const handleSubmitForm = async (): Promise<EnvelopeActionFormSchemaType | null> => {
    let formData: EnvelopeActionFormSchemaType | null = null;
    const formMethods = formRef?.current?.methods;

    if (!formMethods) {
      return null;
    }

    await formMethods.handleSubmit((data: EnvelopeActionFormSchemaType) => {
      formData = data;
    })();

    return formData;
  };

  const onSimulateActionClick = async () => {
    let formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }
    const implementationToDate = formData.implementation_to as Date;

    //reassigning to new variable is important rather than updating formData directly. it also updates the form input because of useref
    const updatedFormData = formatEnvelopeRenovationParameter(formData, renovationType as renovation_type_enum);

    simulateEnvelopeAction({
      variables: {
        action_id: selectedAction.id,
        ...updatedFormData,
        implementation_to: implementationToDate,
      },
    });
  };

  const onEditActionClick = async () => {
    const formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }

    const implementationToDate = formData.implementation_to as Date;

    const updatedFormData = formatEnvelopeRenovationParameter(formData, renovationType as renovation_type_enum);

    await editAction({
      variables: {
        action_id: selectedAction.id,
        ...updatedFormData,
        implementation_to: implementationToDate,
        customCost: customCostWithNumbers ?? [],
      },
    });
  };

  const minimumDate = previousAction ? new Date(previousAction.implementation_to) : undefined;
  const maximumDate = nextAction ? new Date(nextAction.implementation_to) : undefined;

  const props = {
    previousActionBuildingModel: previousActionBuildingModel,
    setSimulatedData: setSimulatedData,
    formRef: formRef,
    resetSimulatedData: resetSimulatedData,
    loading: simulationLoading || editActionLoading,
    editable: selectedAction.editable,
    minimumDate: minimumDate && minimumDate < new Date() ? new Date() : minimumDate || new Date(),
    maximumDate: maximumDate ?? getMaxDate(),
    implementationTo: new Date(selectedAction.implementation_to),
    affectedParts: affectedEnvelopePartsByAction(selectedAction, allActions, baseBuildingModel),
  };

  const handleCancel = () => {
    if (isCurrentFormDirty && simulatedData) {
      setCloseWarningDialog(true);
    } else {
      onClose();
    }
  };

  const envelopeData = selectedAction.renovations_envelope[0].new_envelope;

  const parameters: EnvelopeRenovationParameterInput | null = envelopeData
    ? {
        cost_per_m2: (envelopeData && envelopeData.cost_per_m2) ?? undefined,
        envelope_type: envelopeData.envelope_type_id,
        insulation_lambda: (envelopeData && envelopeData.insulation_lambda) ?? undefined,
        insulation_material_category: envelopeData.insulation_material_category_id || undefined,
        insulation_method: envelopeData.insulation_method_id || undefined,
        insulation_thickness: (envelopeData && envelopeData.insulation_thickness) ?? undefined,
        material_name: (envelopeData && envelopeData.insulation_material_name) ?? '',
        u_value: envelopeData && envelopeData.u_value ? envelopeData.u_value : undefined,
      }
    : null;

  const shouldDisable =
    editActionLoading || isCostFormDirty || (isCostFormDirty && !simulatedData) || (!isCostFormDirty && simulatedData);

  return (
    <>
      <PreDialog
        type="definedByChildren"
        open={true}
        onClose={handleCancel}
        maxWidth="lg"
        fullWidth
        disableCloseAction={simulationLoading || editActionLoading}
      >
        <DialogTitle>
          <Stack direction={'row'} alignItems={'center'}>
            <Box component={'img'} src={getIconURLByAction(selectedAction)} width={24} mr={2} />
            <Typography variant="h6">{getActionName(selectedAction)}</Typography>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Scrollbar>
            <Box component={Paper} height={'100%'}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4} xl={4} p={0}>
                  <Box py={3} pr={3} minHeight={500}>
                    <ToggleButtonGroup
                      size="small"
                      value={category}
                      exclusive
                      sx={{ width: '100%' }}
                      onChange={(_, value) => {
                        setCategory(value);
                      }}
                      disabled={true}
                    >
                      {Object.values(category_enum).map((value, i) => (
                        <ToggleButton
                          key={i}
                          value={value}
                          sx={{
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <Box component={'img'} src={getActionIcon(value)} sx={{ width: 16 }} />
                          <Typography sx={{ pl: 0.5 }} variant="subtitle2">
                            {categoryShortNameConversion(value)}
                          </Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>

                    <Alert severity="info" sx={{ mt: 4, mb: 2 }}>
                      {t('ActionPlanning-EditAction-EnvelopeActionEditableInfoAlert')}
                    </Alert>

                    {parameters && <EnvelopeAction {...props} parameters={parameters} />}

                    <Box justifyContent={'end'} display={'flex'} my={2}>
                      <LoadingButton
                        size="medium"
                        variant="contained"
                        //Enabling if all form values are there for now
                        disabled={simulationLoading || !isCurrentFormDirty || isDataSimulated}
                        loading={simulationLoading}
                        onClick={onSimulateActionClick}
                      >
                        {t('General_Simulate')}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} item md={8} lg={8} xl={8} p={0}>
                  <FormProvider methods={costFormMethods}>
                    <ActionDetails
                      //@ts-ignore
                      metricData={modificationImpact}
                      currentView="action_modal"
                      actionSubsidies={filteredActionSubsidies}
                      renovationArea={renovationArea}
                      simulated
                      country={country}
                      actionSource="edit-action"
                    />
                  </FormProvider>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ mr: '-2px' }} />
            </Box>
          </Scrollbar>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            {t('General_Cancel')}
          </Button>
          <LoadingButton
            disabled={!shouldDisable}
            variant="contained"
            onClick={onEditActionClick}
            loading={editActionLoading}
          >
            {t('General_EditAction')}
          </LoadingButton>
        </DialogActions>
      </PreDialog>

      {showCloseWarningDialog && (
        <DiscardActionModal
          isOpen={showCloseWarningDialog}
          onClose={() => setCloseWarningDialog(false)}
          onAcknowledge={onClose}
        />
      )}
    </>
  );
}
