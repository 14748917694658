/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, MenuItem, Stack, Theme, Typography, useTheme } from '@mui/material';
import {
  checkDistrictHeating,
  getEmissionsFactor,
  getSupportedSourceTypesForSystemAndTechnologyType,
  getSupportedSourceTypesForSystemType,
} from '@predium/client-lookup';
import { data_source_type_enum, emission_factor_type_enum, energy_source_type_enum } from '@predium/enums';
import { translateEmissionFactorTypeEnum_dynamic, translateEnergySourceTypeEnum } from '@predium/i18n/client';
import { ensureDefined, maybeEnum } from '@predium/utils';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import DetailedTooltip from '../../../../../../../../components/DetailedTooltip/DetailedTooltip';
import Label from '../../../../../../../../components/Label';
import OverflowText from '../../../../../../../../components/OverflowText';
import { FieldIcon, IconState } from '../../../../../../../../components/custom-data-source-input/FieldIcon';
import { FormProvider, RHFSelect } from '../../../../../../../../components/hook-form';
import PreDialog, {
  PreDialogBody,
  PreDialogTitle,
} from '../../../../../../../../components/presentations/PreDialog/PreDialog';
import { GET_ALL_EMISSION_CERTIFICATES_DROPDOWN } from '../../../../../../../../graphql/DataCollection.queries';
import useOrgPreferences from '../../../../../../../../hooks/useOrgPreferences';
import {
  generateSystemFieldNames,
  generateSystemRouteFieldNames,
} from '../../../../../../../../sections/DataCollection/Building/Common/building.util';
import useBuilding from '../../../../../../../../sections/DataCollection/Building/Context/useBuilding';
import { SystemRoute, getDefaultParameterValues } from '../../../../../BuildingTechnology';
import TechnologyNumberField from './TechnologyNumberField';
import TechnologyTextField from './TechnologyTextField';
import { updateAllRelatedEnergySources } from './utils';

export const GlOBAL_EMISSION_CERTIFICATE_ID = -1;

type EnergySourceEditSchemaType = {
  energySource: energy_source_type_enum | '';
  primaryEnergyFactor: number;
  pricePerKwh: string;
  emissionFactor: number;
  emissionCertificateId: number;
  primaryEnergyFactorEmissionCertificateId: number;
};

export type EmissionFactorInfo = {
  id: number;
  emissionFactor: number;
  issuer?: string;
};

export type PrimaryEnergyFactorInfo = {
  id: number;
  primaryEnergyFactor: number;
  issuer?: string;
};

export const getSelectStyles = (isFieldDirty: boolean) => ({
  '.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    py: isFieldDirty ? 1.5 : 2,
  },
  '.MuiInputLabel-root': {
    color: 'text.secondary',
  },
});

export const getSelectDisabledStyles = (theme: Theme) => ({
  '.MuiInputLabel-root.Mui-disabled': {
    background: theme.palette.background.neutral,
    px: 0.5,
    borderRadius: 0.5,
    color: 'text.secondary',
  },
  '.MuiOutlinedInput-root.Mui-disabled': {
    background: theme.palette.background.neutral,
  },
  '.Mui-disabled.MuiInputBase-input': {
    WebkitTextFillColor: theme.palette.text.primary,
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  portfolioEmissionFactorType: emission_factor_type_enum;
  energySystemRoute: SystemRoute;
  index: number;
  systemIndex: number;
};

const EnergySourceEditModal = ({
  open,
  onClose,
  portfolioEmissionFactorType,
  energySystemRoute,
  index,
  systemIndex,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { building } = useBuilding();
  const { orgEmissionFactorType } = useOrgPreferences();
  const { setValue: setParentFormValue, getValues: getParentFormValues, trigger } = useFormContext();

  const { data: allEmissionCertificates } = useQuery(GET_ALL_EMISSION_CERTIFICATES_DROPDOWN);

  const {
    energyFinal,
    emissionFactor,
    emissionCertificateId,
    energySource,
    primaryEnergyFactor,
    pricePerKwh,
    consumerTechnologyType,
    energySystemType,
    primaryEnergyFactorEmissionCertificateId,
  } = energySystemRoute;

  const emissionFactorsCertificates = useMemo(() => {
    const certificates = allEmissionCertificates?.emission_factor_emission_certificates ?? [];

    return certificates.map(
      (certificate) =>
        ({
          id: certificate.id,
          emissionFactor: ensureDefined(certificate.emission_factor),
          issuer: certificate.issuer,
        } satisfies EmissionFactorInfo),
    );
  }, [allEmissionCertificates?.emission_factor_emission_certificates]);

  const primaryEnergyFactorsCertificates = useMemo(() => {
    const certificates = allEmissionCertificates?.primary_energy_factor_emission_certificates ?? [];

    return certificates.map(
      (certificate) =>
        ({
          id: certificate.id,
          primaryEnergyFactor: ensureDefined(certificate.primary_energy_factor),
          issuer: certificate.issuer,
        } satisfies PrimaryEnergyFactorInfo),
    );
  }, [allEmissionCertificates?.primary_energy_factor_emission_certificates]);

  const globalEmissionFactorType = portfolioEmissionFactorType ?? orgEmissionFactorType;

  const globalEmissionCertificateSource: EmissionFactorInfo = {
    id: GlOBAL_EMISSION_CERTIFICATE_ID,
    // This value is not used, but it is required to satisfy the EmissionFactorInfo type
    emissionFactor: 0,
  };

  const REQUIRED_MESSAGE = t('General_Required');

  const supportedEnergySources = consumerTechnologyType
    ? getSupportedSourceTypesForSystemAndTechnologyType(
        //@ts-ignore
        energySystemType,
        consumerTechnologyType,
      )
    : //@ts-ignore
      getSupportedSourceTypesForSystemType(energySystemType);

  const EnergySourceEditSchema = object().shape({
    energySource: string().required(REQUIRED_MESSAGE).oneOf(supportedEnergySources, REQUIRED_MESSAGE),
    emissionCertificateId: number().required(REQUIRED_MESSAGE),
    primaryEnergyFactorEmissionCertificateId: number().required(REQUIRED_MESSAGE),
  });

  //@ts-ignore
  const defaultValues: EnergySourceEditSchemaType = useMemo(() => {
    return {
      energySource,
      pricePerKwh,
      primaryEnergyFactor,
      emissionFactor,
      emissionCertificateId,
      primaryEnergyFactorEmissionCertificateId,
    };
  }, [
    emissionCertificateId,
    emissionFactor,
    energySource,
    pricePerKwh,
    primaryEnergyFactor,
    primaryEnergyFactorEmissionCertificateId,
  ]);

  const methods = useForm<EnergySourceEditSchemaType>({
    resolver: yupResolver(EnergySourceEditSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset, getFieldState, watch, control, setValue } = methods;

  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const newEmissionCertificateId = watch('emissionCertificateId');
  const isEmissionCertificateIdDirty = getFieldState('emissionCertificateId').isDirty;

  const newPrimaryEnergyFactorEmissionCertificateId = watch('primaryEnergyFactorEmissionCertificateId');
  const isPrimaryEnergyFactorEmissionCertificateIdDirty = getFieldState(
    'primaryEnergyFactorEmissionCertificateId',
  ).isDirty;

  const newEnergySource = watch('energySource');
  const isEnergySourceDirty = getFieldState('energySource').isDirty;

  useEffect(() => {
    const safeNewEnergySource = maybeEnum(energy_source_type_enum, newEnergySource);

    if (!safeNewEnergySource) {
      return;
    }

    const { primaryEnergyFactor, pricePerKwh } = getDefaultParameterValues(safeNewEnergySource, building.address);

    if (!checkDistrictHeating(safeNewEnergySource).isDistrictHeating) {
      setValue('primaryEnergyFactorEmissionCertificateId', GlOBAL_EMISSION_CERTIFICATE_ID);
      setValue('primaryEnergyFactor', primaryEnergyFactor);
      return;
    }

    setValue('pricePerKwh', pricePerKwh);

    if (newPrimaryEnergyFactorEmissionCertificateId === GlOBAL_EMISSION_CERTIFICATE_ID) {
      return setValue('primaryEnergyFactor', primaryEnergyFactor);
    }

    const certificatePrimaryEnergyFactor = primaryEnergyFactorsCertificates.find(
      (source) => source.id === newPrimaryEnergyFactorEmissionCertificateId,
    )?.primaryEnergyFactor;

    if (certificatePrimaryEnergyFactor === undefined) {
      return;
    }

    setValue('primaryEnergyFactor', certificatePrimaryEnergyFactor);
  }, [
    building.address,
    newEmissionCertificateId,
    newEnergySource,
    newPrimaryEnergyFactorEmissionCertificateId,
    primaryEnergyFactorsCertificates,
    setValue,
  ]);

  useEffect(() => {
    const safeNewEnergySource = maybeEnum(energy_source_type_enum, newEnergySource);
    if (!safeNewEnergySource) return;

    if (
      newEmissionCertificateId !== GlOBAL_EMISSION_CERTIFICATE_ID &&
      checkDistrictHeating(safeNewEnergySource).isDistrictHeating
    ) {
      const certificateEmissionFactor = emissionFactorsCertificates.find(
        (source) => source.id === newEmissionCertificateId,
      )?.emissionFactor;

      //@ts-ignore
      setValue('emissionFactor', certificateEmissionFactor);
    } else {
      const defaultEmissionFactor = getEmissionsFactor({
        energySourceType: safeNewEnergySource,
        emissionFactorType: globalEmissionFactorType,
        year: new Date().getFullYear(),
        country: building.address.country_id,
      });
      setValue('emissionFactor', defaultEmissionFactor);
    }
  }, [
    setValue,
    newEnergySource,
    newEmissionCertificateId,
    globalEmissionFactorType,
    building.address,
    energyFinal,
    emissionFactorsCertificates,
  ]);

  const emissionFactorSources = [globalEmissionCertificateSource, ...emissionFactorsCertificates];
  const primaryEnergyFactorSources = [globalEmissionCertificateSource, ...primaryEnergyFactorsCertificates];

  const onSubmit = (data: EnergySourceEditSchemaType) => {
    handleClose();

    if (isDirty) {
      const {
        energySourcefieldname,
        emissionFactorFieldname,
        primaryFactorFieldname,
        emissionCertificateIdFieldname,
        primaryEnergyFactorEmissionCertificateIdFieldname,

        pricePerKwhFieldname,
        //@ts-ignore
      } = generateSystemRouteFieldNames(energySystemType, systemIndex, index);

      setParentFormValue(energySourcefieldname, data.energySource, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setParentFormValue(emissionCertificateIdFieldname, data.emissionCertificateId, {
        shouldDirty: true,
      });

      setParentFormValue(
        primaryEnergyFactorEmissionCertificateIdFieldname,
        data.primaryEnergyFactorEmissionCertificateId,
        {
          shouldDirty: true,
        },
      );

      setParentFormValue(emissionFactorFieldname, data.emissionFactor, {
        shouldDirty: true,
      });
      setParentFormValue(primaryFactorFieldname, data.primaryEnergyFactor, {
        shouldDirty: true,
      });
      setParentFormValue(pricePerKwhFieldname, data.pricePerKwh);

      //@ts-ignore
      const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);

      setParentFormValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
        shouldDirty: true,
      });

      trigger(dataSourceTypeFieldname); //need a manual to trigger to render newly updated value

      if (checkDistrictHeating(data.energySource as energy_source_type_enum).isDistrictHeating) {
        updateAllRelatedEnergySources(
          getParentFormValues(),
          setParentFormValue,
          {
            emissionFactor: data.emissionFactor,
            primaryEnergyFactor: data.primaryEnergyFactor,
            primaryEnergyFactorEmissionCertificateId: data.primaryEnergyFactorEmissionCertificateId,
            emissionCertificateId: data.emissionCertificateId,
          },
          globalEmissionFactorType,
          building.address,
        );
      }
    }
  };

  return (
    <PreDialog open={open} onClose={onClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={
            <PreDialogTitle
              icon={
                <Box component="img" src={TechnologyIcons.energySourceV2} mt={0.25} mr={1} width={24} height={24} />
              }
              title={t('DataCollection_Technology_EditEnergySourceTitle')}
            />
          }
          content={
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={3}>
                  <RHFSelect
                    select
                    label={t('General_EnergySource')}
                    name="energySource"
                    helperText={t('DataCollection_Technology_EditEnergySourceHelperText')}
                    sx={getSelectStyles(isEnergySourceDirty)}
                  >
                    {supportedEnergySources.map((type) => (
                      <MenuItem sx={{ height: 40 }} key={type} value={type}>
                        {isEnergySourceDirty && newEnergySource === type && (
                          <Box mt={0.5}>
                            <FieldIcon state={IconState.ManualEdit} />
                          </Box>
                        )}
                        <Box>{translateEnergySourceTypeEnum(type)}</Box>
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid container spacing={2} ml={0} mb={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1">{t('General_EmissionFactor')}</Typography>
                      <DetailedTooltip
                        labels={{
                          title: t('General_EmissionFactor'),
                          body: t('Education_EmissionFactorBody'),
                          equations: `${t('General_EmissionFactor')} * ${t('General_FinalEnergy')} = ${t(
                            'General_CO2Intensity',
                          )}`,
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    <RHFSelect
                      select
                      label={t('General_Source')}
                      name="emissionCertificateId"
                      disabled={!checkDistrictHeating(newEnergySource as energy_source_type_enum).isDistrictHeating}
                      sx={{
                        ...getSelectStyles(isEmissionCertificateIdDirty),
                        ...getSelectDisabledStyles(theme),
                      }}
                    >
                      {emissionFactorSources.map((source) => (
                        <MenuItem sx={{ height: 40 }} key={source.id} value={source.id}>
                          {isEmissionCertificateIdDirty && newEmissionCertificateId === source.id && (
                            <Box mt={0.5}>
                              <FieldIcon state={IconState.ManualEdit} />
                            </Box>
                          )}
                          <Stack direction="row" flex="1" justifyContent="space-between">
                            <OverflowText
                              text={
                                source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                  ? translateEmissionFactorTypeEnum_dynamic(globalEmissionFactorType, t)
                                  : source.issuer
                              }
                              maxWidth={'160px'}
                              variant="body1"
                              TooltipProps={{
                                placement: 'top',
                              }}
                            />
                            <Label>
                              {source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                ? t('General_MarketBased')
                                : t('General_LocationBased')}
                            </Label>
                          </Stack>
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={5}>
                    <TechnologyNumberField
                      name="emissionFactor"
                      label={t('General_EmissionFactor')}
                      unit="kg CO₂e/kWh"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} ml={0} mb={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1">{t('General_PrimaryEnergyFactor')}</Typography>
                      <DetailedTooltip
                        variant="calculation"
                        labels={{
                          title: t('General_PrimaryEnergyFactor'),
                          body: t('Education_PrimaryEnergyFactorBody'),
                          equations: `${t('General_PrimaryEnergyFactor')} * ${t('General_FinalEnergy')} = ${t(
                            'General_PrimaryEnergy',
                          )}`,
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    <RHFSelect
                      select
                      label={t('General_Source')}
                      name="primaryEnergyFactorEmissionCertificateId"
                      disabled={!checkDistrictHeating(newEnergySource as energy_source_type_enum).isDistrictHeating}
                      sx={{
                        ...getSelectStyles(isPrimaryEnergyFactorEmissionCertificateIdDirty),
                        ...getSelectDisabledStyles(theme),
                      }}
                    >
                      {primaryEnergyFactorSources.map((source) => (
                        <MenuItem sx={{ height: 40 }} key={source.id} value={source.id}>
                          {isPrimaryEnergyFactorEmissionCertificateIdDirty &&
                            newPrimaryEnergyFactorEmissionCertificateId === source.id && (
                              <Box mt={0.5}>
                                <FieldIcon state={IconState.ManualEdit} />
                              </Box>
                            )}
                          <Stack direction="row" flex="1" justifyContent="space-between">
                            <OverflowText
                              text={
                                source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                  ? translateEmissionFactorTypeEnum_dynamic(emission_factor_type_enum.GEG, t)
                                  : source.issuer
                              }
                              maxWidth={'160px'}
                              variant="body1"
                              TooltipProps={{
                                placement: 'top',
                              }}
                            />
                            <Label>
                              {source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                ? t('General_MarketBased')
                                : t('General_LocationBased')}
                            </Label>
                          </Stack>
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={5}>
                    <TechnologyNumberField name="primaryEnergyFactor" label={t('General_PrimaryEnergyFactor')} />
                  </Grid>
                </Grid>

                <Grid container spacing={2} ml={0} mb={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1">{t('General_PricePerKwh')}</Typography>
                      <DetailedTooltip
                        labels={{
                          title: t('General_PricePerKwh'),
                          body: t('Education_PricePerKwhBody'),
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    <TechnologyTextField
                      name="invalid_key" // This is a dummy key because the key is required
                      label={t('General_Source')}
                      defaultValue={'Predium'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TechnologyNumberField name="pricePerKwh" label={t('General_PricePerKwh')} unit="€/kWh" />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('General_Cancel')}
              </Button>
              <Button type="submit" variant="contained">
                {t('General_Accept')}
              </Button>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
};

export default EnergySourceEditModal;
