import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { EsgAnalysisGetPortfolioQuery, climate_risk_type_enum } from '@predium/client-graphql';
import { getAnalyzedBuildingsWhere } from '@predium/graphql-where-client';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClimateRiskGraph } from '../../../components/data-visialization/esg-analysis/ClimateRiskChart';
import { createStandardErrorSnackbar } from '../../../components/NotistackProvider';
import { GET_PORTFOLIO } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { getBuildingWhereFilters } from '../../../utils/getWhereFilters';
import { BenchmarkType } from '../Components/EsgAnalysisBenchmark';
import { DisplayViewType } from '../Components/EsgAnalysisViewSwitcher';
import { FloorAreaBySubBuildingClass } from '../EsgAnalysisUtil';
import EsgAnalysisBuildingListTab from './BuildingListTab/EsgAnalysisBuildingListTab';
import EsgAnalysisSection from './EsgAnalysisSection';
import { ClimateRiskData, CurrentPageView, EsgAnalysisTab } from './PortfolioAnalysisHeader';

type Props = {
  currentPageView?: CurrentPageView;
  setFloorAreaByBuildingClass: (data: FloorAreaBySubBuildingClass) => void;
  currentTab: EsgAnalysisTab;
  showTotal: boolean;
  currentView: DisplayViewType;
  selectedBenchmark: BenchmarkType;
  setTotalBuildings: (total: number) => void;
};

export default function PortfolioAnalysisBody({
  currentPageView,
  setFloorAreaByBuildingClass,
  currentTab,
  showTotal,
  currentView,
  selectedBenchmark,
  setTotalBuildings,
}: Props) {
  const { filters } = useEsgAnalysisFilters();
  const { t } = useTranslation();
  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);
  const { enqueueSnackbar } = useSnackbar();

  const [climateRisks, setClimateRiskData] = useState<ClimateRiskData>([]);

  const currentYear = new Date().getFullYear();

  const { data, loading, previousData } = useQuery<EsgAnalysisGetPortfolioQuery>(GET_PORTFOLIO, {
    variables: {
      buildingWhere: getAnalyzedBuildingsWhere(getBuildingWhereFilters(filters), currentYear),
    },

    fetchPolicy: 'cache-and-network',
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  useEffect(() => {
    if (data) {
      setFloorAreaByBuildingClass(
        data
          ? data.getAnalyzedBuildings.floorAreaBySubBuildingClass || []
          : previousData?.getAnalyzedBuildings.floorAreaBySubBuildingClass || [],
      );
      setClimateRiskData(
        data ? data.getAnalyzedBuildings.climateRisks : previousData?.getAnalyzedBuildings.climateRisks || [],
      );
      setTotalBuildings(
        data
          ? data?.getAnalyzedBuildings?.buildingsGeneralData.length
          : previousData?.getAnalyzedBuildings.buildingsGeneralData.length || 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, previousData]);

  const climateRisksData = climateRisks.map((risk) => ({
    ...risk,
    type: climate_risk_type_enum[risk.type],
  }));

  if (currentTab === 'demand') {
    if (currentView === 'dashboard') {
      return (
        <Box sx={{ pt: 5 }}>
          <EsgAnalysisSection
            showTotal={showTotal}
            currentPageView={currentPageView}
            loading={loading}
            analyzedBuildings={data?.getAnalyzedBuildings ?? previousData?.getAnalyzedBuildings}
            selectedBenchmark={selectedBenchmark}
          />
        </Box>
      );
    }

    return (
      <Box sx={{ pt: 5 }}>
        <EsgAnalysisBuildingListTab showTotal={showTotal} />
      </Box>
    );
  }

  if (currentTab === 'climate_risks') {
    return (
      <Box sx={{ pt: 5 }}>
        <Grid item xs={12}>
          <ClimateRiskGraph climateRisks={climateRisksData} />
        </Grid>
      </Box>
    );
  }

  return null;
}
