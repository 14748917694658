/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient, useQuery } from '@apollo/client';
import { Button, DialogContent, DialogTitle, TableCell, TableRow, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DropzoneComponent from '../../../../components/DropzoneComponent';
import FileThumbnail from '../../../../components/FileThumbnail';
import Iconify from '../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import AccessRightsWrapper from '../../../../components/permission-tooltips/AccessRightsWrapper';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { CREATE_ENERGY_CERTIFICATE_FILE } from '../../../../graphql/DataCollection.mutations';
import {
  GET_MISSING_ENERGY_CERTIFICATE_FILES,
  GET_SUB_BUILDING_FILES,
} from '../../../../graphql/DataCollection.queries';
import useUpdateFileName from '../../../../hooks/useUpdateFileName';
import useBuilding from '../Context/useBuilding';
import { getAvailableDataTypes } from './SubBuildingDocuments';

type Props = {
  subBuildingId: number;
};

function MissingEnergyCertificateFiles({ subBuildingId }: Props) {
  const { t } = useTranslation();
  const { updateFileName } = useUpdateFileName();
  const { enqueueSnackbar } = useSnackbar();
  const AVAILABLE_DATA_TYPES = getAvailableDataTypes(t);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const newFileId = useRef<number | null>(null);
  //TODO: remove unused state
  const [, setUploadInProgress] = useState(false);

  const { data: missingFiles } = useQuery(GET_MISSING_ENERGY_CERTIFICATE_FILES, {
    variables: { subBuildingId },
  });

  const apollo = useApolloClient();
  const { hasEditAccess } = useBuilding();

  const asyncCreateUploadUrl = (energyCertificateId: number) => async () => {
    const { data, errors } = await apollo.mutate({
      mutation: CREATE_ENERGY_CERTIFICATE_FILE,
      variables: {
        energyCertificateId,
      },
    });

    if (errors) {
      enqueueSnackbar(t('DataCollection_EnergyCertificateUpload_ErrorMessage'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    } else {
      //@ts-ignore
      newFileId.current = data.createEnergyCertificateFile.file_id;
      return {
        //@ts-ignore
        fileUrl: data.createEnergyCertificateFile.upload_url,

        //@ts-ignore
        fileId: data.createEnergyCertificateFile.file_id,
      };
    }
  };

  const updateEnergyCertificateFileNameAfterUpload = async (fileName: string) => {
    //@ts-ignore
    await updateFileName(newFileId.current, fileName, [
      { query: GET_MISSING_ENERGY_CERTIFICATE_FILES, variables: { subBuildingId } },
      { query: GET_SUB_BUILDING_FILES, variables: { id: subBuildingId } },
    ]);
  };

  if (!missingFiles || !missingFiles.sub_building[0]) return null;

  return (
    <>
      {missingFiles.sub_building[0]?.energy_certificates.map((energyCertificate) => {
        const createUploadUrl = asyncCreateUploadUrl(energyCertificate.id);

        return (
          <React.Fragment key={energyCertificate.id}>
            <TableRow>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <FileThumbnail format="pdf" />
                  {t('DataCollection_EnergyCertificate_MissingDocument')}
                </div>
              </TableCell>
              <TableCell>{AVAILABLE_DATA_TYPES[1].label}</TableCell>
              <TableCell></TableCell>

              <TableCell align="right">
                <AccessRightsWrapper hasAccess={hasEditAccess}>
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon={'ic:baseline-file-upload'} />}
                    onClick={() => setOpenUploadDialog(true)}
                  >
                    {t('General_UploadDocument')}
                  </Button>
                </AccessRightsWrapper>
              </TableCell>
            </TableRow>

            <PreDialog
              type="definedByChildren"
              open={openUploadDialog}
              onClose={() => setOpenUploadDialog(false)}
              fullWidth
            >
              <DialogTitle>{t('DataCollection_AddNewEnergyCertificate_UploadPrompt')}</DialogTitle>

              <DialogContent sx={{ mb: 3 }}>
                <DropzoneComponent
                  fileDisplayName={t('General_EnergyCertificate')}
                  description={
                    <Typography variant="body1">
                      <Trans
                        i18nKey="DataCollection_AddNewEnergyCertificate_Description"
                        components={{ underline: <span style={{ textDecoration: 'underline' }} /> }}
                      />
                    </Typography>
                  }
                  maxFiles={1}
                  //@ts-ignore
                  createUploadUrl={createUploadUrl}
                  onUploadSuccess={(file) => {
                    updateEnergyCertificateFileNameAfterUpload(file.name);
                    setOpenUploadDialog(false);
                  }}
                  setUploadInProgress={setUploadInProgress}
                />
              </DialogContent>
            </PreDialog>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default MissingEnergyCertificateFiles;
