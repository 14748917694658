import { useContext } from 'react';
import { ActionPlanContext } from './ActionPlanContext';

const useActionPlan = () => {
  const value = useContext(ActionPlanContext);

  if (!value) {
    throw new Error('useActionPlan must be used within a ActionPlanProvider');
  }

  return value;
};

export default useActionPlan;
