import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { SxProps, Theme, styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

// ----------------------------------------------------------------------

type Props = {
  numSelected: number;
  filterName: string;
  onFilterName: (value: string) => void;
  sx?: SxProps<Theme>;
  placeholder?: string;
  /**
   * Style override if sx just doesn't do it.
   */
  style?: React.CSSProperties;
};

export default function SearchTableToolbar({ numSelected, filterName, onFilterName, placeholder, sx, style }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
        ...sx,
      }}
      style={style}
    >
      <Stack direction="row">
        <TextField
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          placeholder={placeholder ?? t('General_SearchBuildingPlaceholder')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
          sx={{
            maxWidth: '200px',
            height: 40,
            '.MuiOutlinedInput-input': {
              py: 1,
            },
          }}
        />
      </Stack>
    </RootStyle>
  );
}
