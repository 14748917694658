import { Box, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import DetailedTooltip, { DetailedTooltipProps } from '../../../../../../components/DetailedTooltip/DetailedTooltip';
import { FieldIcon, IconState } from '../../../../../../components/custom-data-source-input/FieldIcon';

export type TableData = {
  label: string;
  value: number | string | null;
  isDirty?: boolean;
  tooltipProps?: DetailedTooltipProps;
};

type TableProps = {
  data: TableData[];
  isDeleted?: boolean;
};

const TechnologyUnitTable = ({ data, isDeleted = false }: TableProps) => {
  return (
    <Table
      sx={{
        p: 0,
        td: {
          p: 0,
        },
      }}
    >
      <TableBody>
        {data.map((item, index) => {
          const { label, value, isDirty, tooltipProps } = item;

          return (
            <TableRow key={index}>
              <TableCell align="left">
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Box sx={{ textDecoration: isDeleted ? 'line-through' : 'none' }}>{label}</Box>
                  {tooltipProps && (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <DetailedTooltip {...tooltipProps} />
                    </Box>
                  )}
                </Stack>
              </TableCell>
              <TableCell align="right">
                {isDirty ? (
                  <Stack display="inline-flex" direction="row" alignItems="center" spacing={0.25}>
                    <FieldIcon state={IconState.ManualEdit} noWrapper />
                    <Box>{value}</Box>
                  </Stack>
                ) : (
                  <Box sx={{ textDecoration: isDeleted ? 'line-through' : 'none' }}>{value}</Box>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TechnologyUnitTable;
