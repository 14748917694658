import { useQuery } from '@apollo/client';
import { Card, Grid, Stack } from '@mui/material';
import {
  EsgAnalysisBuildingBasicDetailsFragment,
  EsgAnalysisGetBenchmarkBuildingsFragment,
} from '@predium/client-graphql';
import { country_enum, efficiency_class_enum, eu_taxonomy_compliance_enum } from '@predium/enums';
import { accessEnum, getEbfTypesOfUse, isEuTaxonomyAvailable } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../components/Loading';
import NewEfficiencyClassLabel from '../../../components/NewEfficiencyClassLabel';
import SimpleComparisonMetric from '../../../components/SimpleComparisonMetric';
import { GET_BUILDING_CONSUMPTION_KPIs } from '../../../graphql/EsgAnalysis.queries';
import { calculateEUTaxonomyToBeSaved } from '../Components/EUTaxonomyTooltip';
import EUTaxonomyBadge from '../Components/EuTaxonomyBadge';

// ----------------------------------------------------------------------

type Props = {
  building: EsgAnalysisBuildingBasicDetailsFragment;
  // Optional, because switching the benchmark re-loads the gql query and leaves it loading for a short time
  benchmark?: EsgAnalysisGetBenchmarkBuildingsFragment;
  showTotal: boolean;
  selectedReportingYear: number;
};

export default function ConsumptionKPIs({ building, showTotal, selectedReportingYear }: Props) {
  const { t } = useTranslation();

  const { data, loading, previousData } = useQuery(GET_BUILDING_CONSUMPTION_KPIs, {
    fetchPolicy: 'cache-and-network',
    variables: { buildingId: building.id, year: selectedReportingYear },
  });

  const kpiData = data?.getEsgConsumptionAnalysisKPIs ?? previousData?.getEsgConsumptionAnalysisKPIs;

  if (loading && !kpiData) {
    return <DelayedLoading />;
  }

  if (!kpiData) {
    return null;
  }

  const {
    co2Intensity,
    co2Tax,
    energyCost,
    euTaxonomy,
    primaryEnergy,
    finalEnergy,
    co2IntensityAbsolute,
    finalEnergyAbsolute,
    primaryEnergyAbsolute,
    co2TaxAbsolute,
    energyCostAbsolute,
  } = kpiData;

  const efficiencyClass = kpiData.efficiencyClass ? accessEnum(efficiency_class_enum, kpiData.efficiencyClass) : null;
  const euTaxonomyCompliance = euTaxonomy ? accessEnum(eu_taxonomy_compliance_enum, euTaxonomy) : null;

  const typesOfUse = building?.areas ? getEbfTypesOfUse(building.areas) : [];

  const primaryEnergyToBeSaved =
    primaryEnergy && euTaxonomyCompliance && building.address.country_id && typesOfUse[0]
      ? calculateEUTaxonomyToBeSaved(primaryEnergy, euTaxonomyCompliance, building.address.country_id, typesOfUse[0])
      : null;

  const country = building.address.country_id;
  const isInAustria = country === country_enum.AT;

  const finalEnergyMetric = (
    <Grid item xs={4}>
      <SimpleComparisonMetric
        isConsumption
        title={t('General_FinalEnergy')}
        value={showTotal ? finalEnergyAbsolute : finalEnergy}
        unit="kWh"
        showTotal={showTotal}
        titleEndAdornment={
          isInAustria ? null : efficiencyClass ? <NewEfficiencyClassLabel efficiencyClass={efficiencyClass} /> : null
        }
      />
    </Grid>
  );

  const primaryEnergyMetric = (
    <Grid item xs={4}>
      <SimpleComparisonMetric
        isConsumption
        title={t('General_PrimaryEnergy')}
        value={showTotal ? primaryEnergyAbsolute : primaryEnergy}
        unit="kWh"
        showTotal={showTotal}
        titleEndAdornment={
          <Stack alignItems={'center'} direction={'row'}>
            {isInAustria && efficiencyClass ? <NewEfficiencyClassLabel efficiencyClass={efficiencyClass} /> : null}
            {isEuTaxonomyAvailable(building.areas) && euTaxonomyCompliance && primaryEnergyToBeSaved ? (
              <EUTaxonomyBadge compliance={euTaxonomyCompliance} toBeSaved={primaryEnergyToBeSaved} />
            ) : null}
          </Stack>
        }
      />
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={3} sx={{ pt: 3 }}>
        <Grid item xs={8}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              {finalEnergyMetric}
              {primaryEnergyMetric}

              <Grid item xs={4}>
                <SimpleComparisonMetric
                  title={t('General_CO2Intensity')}
                  value={showTotal ? co2IntensityAbsolute : co2Intensity}
                  unit="kg CO₂e"
                  showTotal={showTotal}
                  isConsumption
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SimpleComparisonMetric
                  title={t('General_EnergyCost')}
                  value={showTotal ? energyCostAbsolute : energyCost}
                  unit="€"
                  showTotal={showTotal}
                  isConsumption
                />
              </Grid>
              <Grid item xs={6}>
                <SimpleComparisonMetric
                  title={t('General_CO2Taxes')}
                  value={showTotal ? co2TaxAbsolute : co2Tax}
                  unit="€"
                  showTotal={showTotal}
                  isConsumption
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
