/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  ActionFragment,
  ActionImpactDataOutputFragment,
  ActionPlanImpactDataOutputFragment,
  ActionPlanningActionSubsidyFragment,
  BuildingModelFragment,
  DataCollectionSubBuildingSubBuildingSubsidyFragment,
  EnergyConsumerForActionFragment,
  EnvelopeUnitFragment,
  SimulateActionOutputResponseFragment,
  SubsidyInput,
  country_enum,
  envelope_type_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { cmToM, fPunctuatedNumber } from '@predium/utils';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import Scrollbar from '../../../../components/Scrollbar';
import { FormProvider } from '../../../../components/hook-form';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { IMPLEMENT_ACTION, SIMULATE_ACTION } from '../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTIONPLAN,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_ACTION_PLAN_METRICS,
  GET_SUBSIDIES,
} from '../../../../graphql/ActionPlanning.queries';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import { ActionPlanMetricsData } from '../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import { getActionIcon } from '../../../../utils/icons';
import { getModificationImpactAfterSimulation, getModificationImpactBeforeSimulation } from '../ActionPlan.utils';
import DiscardActionModal from '../Components/DiscardActionModal';
import ActionDetails from './ActionDetails';
import { CustomCostsForm } from './ActionDetails/ActionPricing/RowCosts';
import EnvelopeAction from './CreateActions/EnvelopeAction';
import { DoorActionForm } from './CreateActions/EnvelopeActions/DoorAction';
import { NonWindowDoorActionForm } from './CreateActions/EnvelopeActions/NonWindowDoorAction';
import { WindowActionForm } from './CreateActions/EnvelopeActions/WindowAction';
import TechnicalAction from './CreateActions/TechnicalAction';
import { EnergyRouteActionForm } from './CreateActions/TechnicalActions/EnergyRouteAction';
import { SolarActionSchemaType } from './CreateActions/TechnicalActions/SolarAction';
import { HeatingHotWaterType } from './CreateActions/TechnicalActions/SystemActions/HeatingAction';

export type EnvelopePartialRenovationOption = EnvelopeUnitFragment & { index: number };
export type ConsumerPartialRenovationOption = EnergyConsumerForActionFragment & { index: number };

//should come from DB or defined at common location
export enum category_enum {
  ENVELOPE = 'ENVELOPE',
  TECHNICAL = 'TECHNICAL',
}

export const categoryShortNameConversion = (value: category_enum) => {
  switch (value) {
    case category_enum.TECHNICAL:
      return t('General_TechnicalSystem');
    case category_enum.ENVELOPE:
      return t('General_Envelope');
  }
};

export type FormRef<T extends FieldValues> = {
  methods: UseFormReturn<T>;
};

export type ActionFormSchemaType =
  | NonWindowDoorActionForm
  | WindowActionForm
  | DoorActionForm
  | SolarActionSchemaType
  | EnergyRouteActionForm
  | HeatingHotWaterType;

export type EnvelopeActionFormSchemaType = NonWindowDoorActionForm | WindowActionForm | DoorActionForm;
export type TechnicalActionFormSchemaType = SolarActionSchemaType | EnergyRouteActionForm | HeatingHotWaterType;

export type CurrentFormRef = MutableRefObject<FormRef<ActionFormSchemaType> | null>;
export type EnvelopeActionFormRef = MutableRefObject<FormRef<EnvelopeActionFormSchemaType> | null>;
export type TechnicalActionFormRef = MutableRefObject<FormRef<TechnicalActionFormSchemaType> | null>;

export type EnvelopeActionCreationProps = {
  previousActionBuildingModel: BuildingModelFragment;
  selectedActionType: envelope_type_enum | '';
  ref: EnvelopeActionFormRef;
  lastEnvelopeAction: ActionFragment | undefined;
  minimumDate: Date;
  resetSimulatedData: () => void;
  loading: boolean;
};

export const getInsulationThicknessInMeters = (formData: NonWindowDoorActionForm) => {
  const insulationThicknessCm = formData.envelope_renovation_parameter.insulation_thickness;
  return parseFloat(cmToM(insulationThicknessCm as number).toFixed(2));
};

export const getFormattedSolarAreaRatio = (formData: SolarActionSchemaType) => {
  const roofAreaRatio = formData.solar_renovation_parameter.roof_area_ratio;
  return roofAreaRatio / 100;
};

export const formatEnvelopeRenovationParameter = (
  updatedFormData: EnvelopeActionFormSchemaType,
  selectedActionType: renovation_type_enum,
): EnvelopeActionFormSchemaType => {
  if (selectedActionType === renovation_type_enum.ENVELOPE) {
    const isNonWindowDoorAction =
      updatedFormData.envelope_renovation_parameter.envelope_type !== envelope_type_enum.WINDOW &&
      updatedFormData.envelope_renovation_parameter.envelope_type !== envelope_type_enum.DOOR;

    if (!isNonWindowDoorAction) {
      return updatedFormData;
    }

    if ('insulation_thickness' in updatedFormData.envelope_renovation_parameter) {
      const insulationThicknessMeters = getInsulationThicknessInMeters(updatedFormData as NonWindowDoorActionForm);

      updatedFormData = {
        ...updatedFormData,
        envelope_renovation_parameter: {
          ...updatedFormData.envelope_renovation_parameter,
          insulation_thickness: insulationThicknessMeters,
        },
      };
    }
  }
  return updatedFormData;
};

export const formatSolarRenovationParameter = (updatedFormData: SolarActionSchemaType): SolarActionSchemaType => {
  return {
    ...updatedFormData,
    solar_renovation_parameter: {
      ...updatedFormData.solar_renovation_parameter,
      roof_area_ratio: getFormattedSolarAreaRatio(updatedFormData),
    },
  };
};

export const formatFormData = (
  updatedFormData: ActionFormSchemaType,
  selectedActionType: renovation_type_enum,
): ActionFormSchemaType => {
  if (selectedActionType === renovation_type_enum.ENVELOPE) {
    updatedFormData = formatEnvelopeRenovationParameter(updatedFormData as NonWindowDoorActionForm, selectedActionType);
  } else if (selectedActionType === renovation_type_enum.SOLAR_PLANT) {
    updatedFormData = formatSolarRenovationParameter(updatedFormData as SolarActionSchemaType);
  }

  return updatedFormData;
};

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  baseBuildingModel: BuildingModelFragment;
  metricsData: ActionPlanMetricsData | null;
  subBuildingSubsidies: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
  lastEnvelopeAction: ActionFragment | undefined;
  implementationTo: Date;
  country: country_enum;
};

export default function CreateAction({
  isOpen,
  onClose,
  baseBuildingModel,
  metricsData,
  subBuildingSubsidies,
  lastEnvelopeAction,
  implementationTo,
  country,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = usePosthogTracking();
  const { id: actionPlanId } = useParams();

  const [category, setCategory] = useState<category_enum>(category_enum.ENVELOPE);
  const [simulatedData, setSimulatedData] = useState<SimulateActionOutputResponseFragment | undefined>(undefined);
  const [subsidyData, setSubsidyData] = useState<SubsidyInput[]>([]);
  const [selectedActionType, setSelectedActionType] = useState<renovation_type_enum | undefined>(undefined);
  const [selectedEnvelopeType, setSelectedEnvelopeType] = useState<envelope_type_enum | undefined>(undefined);
  const [isCurrentFormDirty, setIsCurrentFormDirty] = useState(false);
  const [showCloseWarningDialog, setCloseWarningDialog] = useState(false);

  const formRef: EnvelopeActionFormRef | TechnicalActionFormRef = useRef(null);

  const previousActionBuildingModel = baseBuildingModel;

  const [simulateEnvelopeAction, { loading: simulationLoading }] = useMutation(SIMULATE_ACTION, {
    onCompleted: (data) => {
      setSimulatedData(data.simulateAction);
    },
    onError: () =>
      enqueueSnackbar(t('ActionPlanning_SimulateAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const [implementAction, { loading: implementActionLoading }] = useMutation(IMPLEMENT_ACTION, {
    onError: () =>
      enqueueSnackbar(t('CreateNewAction_ImplementAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('CreateNewAction_ImplementAction-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
    },
    refetchQueries: [GET_ACTIONPLAN, GET_ACTIONS_FOR_ACTION_PLAN, GET_ACTION_PLAN_METRICS, GET_SUBSIDIES], //Do we need to refetch subsidies?
  });

  useEffect(() => {
    setIsCurrentFormDirty(false);
    setSimulatedData(undefined);
  }, [category]);

  //TODO: move this to cost component
  const costFormMethods = useForm<CustomCostsForm>({
    defaultValues: {
      cost_custom_input: undefined,
      cost_total_custom: undefined,
      use_custom_cost: false,
    },
  });

  const { getValues, setValue: setCostFormValue } = costFormMethods;

  const getCustomCost = () => {
    const formValues = getValues();

    return {
      cost_custom_input: formValues.cost_custom_input,
      cost_total_custom: formValues.cost_total_custom,
      use_custom_cost: formValues.use_custom_cost ?? false,
    };
  };

  const resetSimulatedData = () => {
    setIsCurrentFormDirty(true);
    if (simulatedData) {
      costFormMethods.reset();
      //can we reset complete simulated data? the current use case half cooked. neither this nor that
      setSimulatedData(undefined);
    }
  };

  useEffect(() => {
    setCostFormValue('cost_total_custom', undefined);
    const cost2Str = simulatedData?.renovation_costs ? fPunctuatedNumber(simulatedData?.renovation_costs) : undefined;
    setCostFormValue('cost_custom_input', cost2Str);
    if (!getValues('use_custom_cost')) setCostFormValue('use_custom_cost', false);
  }, [simulatedData, setCostFormValue, getValues]);

  const handleSubmitForm = async (): Promise<ActionFormSchemaType | null> => {
    let formData: ActionFormSchemaType | null = null;
    const formMethods = formRef?.current?.methods;

    if (!formMethods) {
      return null;
    }

    await formMethods.handleSubmit((data: ActionFormSchemaType) => {
      formData = data;
    })();

    return formData;
  };

  const onSimulateActionClick = async () => {
    let formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }
    const implementationToDate = formData.implementation_to as Date;

    //reassigning to new variable is important rather than updating formData directly. it also updates the form input because of useref
    const updatedFormData = formatFormData(formData, selectedActionType as renovation_type_enum);

    simulateEnvelopeAction({
      variables: {
        action_plan_id: parseInt(actionPlanId),
        ...updatedFormData,
        implementation_to: implementationToDate,
      },
    });
  };

  const onImplementActionClick = async () => {
    const formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }

    const customCost = getCustomCost();
    const customCostWithNumbers = customCost
      ? {
          use_custom_cost: customCost.use_custom_cost ?? false,
          cost_total_custom: customCost.cost_total_custom ?? 0,
        }
      : undefined;

    const implementationToDate = formData.implementation_to as Date;

    const updatedFormData = formatFormData(formData, selectedActionType as renovation_type_enum);

    const result = await implementAction({
      variables: {
        action_plan_id: parseInt(actionPlanId),
        ...updatedFormData,
        implementation_to: implementationToDate,
        implementation_from: implementationToDate, // TODO: handle this in the backend. frontend doesn't have implementation_from date
        subsidies: subsidyData,
        customCost: customCostWithNumbers,
      },
    });

    if (result.errors?.length === 0 || !result.errors) {
      const isPartialRenovation = previousActionBuildingModel.envelope_units.length > formData.renovation_ids.length;
      trackEvent('ACTION_CREATED', {
        renovationType: selectedActionType ?? null,
        envelopeType: selectedEnvelopeType ?? null,
        withCustomCost: customCostWithNumbers?.use_custom_cost ?? false,
        isPartialRenovation,
        withSubsidies: subsidyData.length > 0,
        subsidies: subsidyData.flatMap((s) => s.id),
      });
    }
  };

  // @ts-ignore TODO: move it to subsidy component PRE-3998
  const actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[] = subBuildingSubsidies
    .filter((availableSubsidy) =>
      subsidyData.find((selectedSubsidyInput) => selectedSubsidyInput.id === availableSubsidy.id),
    )
    .map((selectedSubsidy) => {
      //we know this subsidy is selected, but we need to get the input data again
      const subsidyInput = subsidyData.find((selectedSubsidyInput) => selectedSubsidyInput.id === selectedSubsidy.id);
      if (!subsidyInput) return null;

      const { cost_total_custom, use_custom_cost } = getCustomCost();

      return {
        subsidy: {
          id: selectedSubsidy.id,
          title: selectedSubsidy.title,
          categories: selectedSubsidy.categories,
        },
        value: subsidyInput.value,
        type: subsidyInput.type,
        action: {
          //since the action is not yet created
          id: undefined,
          renovations: [
            {
              cost_total: use_custom_cost ? cost_total_custom : simulatedData?.renovation_costs ?? 0,
              renovation_type_id: selectedActionType,
            },
          ],
        },
      };
    });

  const props = {
    previousActionBuildingModel: previousActionBuildingModel,
    setSimulatedData: setSimulatedData,
    setSelectedActionType: setSelectedActionType,
    setSelectedEnvelopeType: setSelectedEnvelopeType,
    minimumDate: implementationTo,
    resetSimulatedData: resetSimulatedData,
    loading: simulationLoading || implementActionLoading,
  };

  const handleCancel = () => {
    if (isCurrentFormDirty && simulatedData) {
      setCloseWarningDialog(true);
    } else {
      onClose();
    }
  };

  const isDataSimulated = simulatedData !== undefined;

  const actions = metricsData?.actions;
  const totalActions = actions?.length ?? 0;
  const atLeastOneAction = totalActions > 0;
  const baseForCreateAction: ActionImpactDataOutputFragment | ActionPlanImpactDataOutputFragment | undefined =
    actions && atLeastOneAction ? actions[totalActions - 1]?.metric : metricsData?.action_plan;

  const beforeSimulationState = getModificationImpactBeforeSimulation(baseForCreateAction, atLeastOneAction);
  //@ts-ignore
  const afterSimulationState = getModificationImpactAfterSimulation(beforeSimulationState, simulatedData);
  const modificationImpact = isDataSimulated ? afterSimulationState : beforeSimulationState;

  return (
    <>
      <PreDialog
        type="definedByChildren"
        open={isOpen}
        onClose={handleCancel}
        maxWidth="lg"
        fullWidth
        disableCloseAction={simulationLoading || implementActionLoading}
      >
        <DialogTitle>{t('General_Action')}</DialogTitle>
        <Divider />
        <DialogContent>
          <Scrollbar>
            <Box component={Paper} height={'100%'}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4} xl={4} p={0}>
                  <Box py={3} pr={3} minHeight={500}>
                    <ToggleButtonGroup
                      size="small"
                      value={category}
                      exclusive
                      sx={{ width: '100%' }}
                      onChange={(_, value) => {
                        setCategory(value);
                      }}
                      disabled={simulationLoading || implementActionLoading}
                    >
                      {Object.values(category_enum).map((value, i) => (
                        <ToggleButton
                          key={i}
                          value={value}
                          sx={{
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <Box component={'img'} src={getActionIcon(value)} sx={{ width: 16 }} />
                          <Typography sx={{ pl: 0.5 }} variant="subtitle2">
                            {categoryShortNameConversion(value)}
                          </Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>

                    {category === category_enum.ENVELOPE ? (
                      <EnvelopeAction
                        {...props}
                        formRef={formRef as EnvelopeActionFormRef}
                        lastEnvelopeAction={lastEnvelopeAction}
                      />
                    ) : (
                      <TechnicalAction {...props} formRef={formRef as TechnicalActionFormRef} />
                    )}
                    <Box justifyContent={'end'} display={'flex'} my={2}>
                      <LoadingButton
                        size="medium"
                        variant="contained"
                        //Enabling if all form values are there for now
                        disabled={simulationLoading || !isCurrentFormDirty || isDataSimulated}
                        loading={simulationLoading}
                        onClick={onSimulateActionClick}
                      >
                        {t('General_Simulate')}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} item md={8} lg={8} xl={8} p={0}>
                  <FormProvider methods={costFormMethods}>
                    <ActionDetails
                      //@ts-ignore
                      metricData={modificationImpact}
                      actionSubsidies={actionSubsidies ?? []}
                      currentView="action_modal"
                      simulated={isDataSimulated}
                      setSubsidyData={setSubsidyData}
                      showAddSubsidyButton={isDataSimulated}
                      renovationType={selectedActionType}
                      country={country}
                    />
                  </FormProvider>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ mr: '-2px' }} />
            </Box>
          </Scrollbar>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            {t('General_Cancel')}
          </Button>
          <LoadingButton
            disabled={!simulatedData || implementActionLoading}
            variant="contained"
            onClick={onImplementActionClick}
            loading={implementActionLoading}
          >
            {t('General_CreateAction')}
          </LoadingButton>
        </DialogActions>
      </PreDialog>

      {showCloseWarningDialog && (
        <DiscardActionModal
          isOpen={showCloseWarningDialog}
          onClose={() => setCloseWarningDialog(false)}
          onAcknowledge={onClose}
        />
      )}
    </>
  );
}
